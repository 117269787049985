import styled from '@emotion/styled';
import { Typography } from '@robinpowered/ui-kit';
import { ClockSolid, LockSolid } from '@robinpowered/icons';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { getLongTimeZoneName, momentToLocationDateTime } from 'utils';
import { SHORT_TIME_FORMAT } from 'constants/timeFormat';
import { ReservationDate } from './ReservationDate';

const { Text } = Typography;

interface ReservationDetailsProps {
  startTime: string;
  seriesId: string | null | undefined;
  endTime: string | null | undefined;
  timezone: string | null | undefined;
  isPrivate: boolean;
}

export function ReservationDetails({
  startTime,
  seriesId,
  endTime,
  timezone,
  isPrivate,
}: ReservationDetailsProps) {
  const { t } = useTranslation('resourceDetails');

  const isValidTimezone = timezone && moment.tz.names().includes(timezone);

  const longReservationTimezoneName = useMemo(() => {
    return getLongTimeZoneName(timezone);
  }, [timezone]);

  if (!isValidTimezone) {
    return null;
  }

  const formattedStartTime = momentToLocationDateTime(
    moment(startTime),
    timezone
  ).format(SHORT_TIME_FORMAT);
  const formattedEndTime = momentToLocationDateTime(
    moment(endTime),
    timezone
  ).format(SHORT_TIME_FORMAT);

  return (
    <DeskReservationContainer>
      <ReservationDetailsContainer>
        <ReservationDate
          seriesId={seriesId}
          startTime={startTime}
          timezone={timezone}
        />

        <ReservationTimeContainer>
          <TimeContainer>
            <ClockIcon />
            <TimeText>
              {formattedStartTime}
              {endTime && ` - ${formattedEndTime}`}
            </TimeText>
          </TimeContainer>
          {!!longReservationTimezoneName && (
            <TimezoneContainer>
              <TimezoneText>{longReservationTimezoneName}</TimezoneText>
            </TimezoneContainer>
          )}
        </ReservationTimeContainer>
        {isPrivate && (
          <PrivateBookingContainer>
            <LockIcon data-testid="lock-icon" />
            <PrivateBookingText>
              {t('reservation_details.private_booking')}
            </PrivateBookingText>
          </PrivateBookingContainer>
        )}
      </ReservationDetailsContainer>
    </DeskReservationContainer>
  );
}

const DeskReservationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
`;

const ReservationDetailsContainer = styled(Text)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
`;

const ReservationTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Space-Padding-paddingXS, 8px);
  align-self: stretch;
`;

const ClockIcon = styled(ClockSolid)`
  width: 16px;
  height: 16px;
  fill-opacity: 0.55;
`;

const TimeText = styled(Text)`
  flex: 1 0 0;
`;

const TimezoneContainer = styled.div`
  display: flex;
  padding-left: 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const TimezoneText = styled(Text)`
  flex: 1 0 0;
`;

const PrivateBookingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Space-Padding-paddingXS, 8px);
  align-self: stretch;
`;

const LockIcon = styled(LockSolid)`
  width: 16px;
  height: 16px;
  fill-opacity: 0.55;
`;

const PrivateBookingText = styled(Text)`
  flex: 1 0 0;
`;
