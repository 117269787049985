import { ApolloLink } from '@apollo/client';
import { config } from 'config';
import { datadogRum } from '@datadog/browser-rum';

export const cacheHitLink = new ApolloLink((operation, forward) => {
  if (config.robinEnv !== 'production') {
    const isMutation = operation.query.definitions.some(
      (definition) =>
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'mutation'
    );

    // Skip if this is a mutation
    // readQuery will fail because the operation.query is not a valid query
    if (isMutation) {
      return forward(operation);
    }

    const cachedData = operation.getContext()?.cache?.readQuery({
      query: operation.query,
      variables: operation.variables,
    });

    datadogRum.addAction('apollo_cache', {
      operation: operation.operationName,
      variables: operation.variables,
      hit: !!cachedData,
    });
  }
  return forward(operation);
});
