import styled from '@emotion/styled';
import { FC } from 'react';
import { Skeleton } from '@robinpowered/ui-kit';
import { useDeskResourceDetails } from './graphql';
import { DeskBookingPolicies } from './DeskBookingPolicies';
import { DeskAmenities } from './DeskAmenities';
import { DeskTypeTag } from './DeskTypeTag';
import { DeskInformation } from './DeskInformation';
import { DeskStickers } from './DeskStickers';
import {
  AssignedDeskAlert,
  DisabledDeskAlert,
  NoAccessOrPermissionAlert,
} from './alerts';
import { useBookingEnabledForDesk } from './hooks/useBookingEnabledForDesk';

export const DeskDetailsInfoOnly: FC<{ deskId: string | null | undefined }> = ({
  deskId,
}) => {
  const {
    deskDetails,
    deskAvailability,
    loading: deskLoading,
  } = useDeskResourceDetails(deskId);

  const { isSelectedStartTimeDuringExclusion } =
    useBookingEnabledForDesk(deskId);

  if (deskLoading) {
    return (
      <DeskDetailsContent data-testid="skeleton">
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </DeskDetailsContent>
    );
  }

  if (!deskDetails || !deskAvailability) {
    return null;
  }

  return (
    <DeskDetailsContent data-testid="desk-details">
      <DeskInfoHeader>
        <DeskTypeTag deskTypes={deskDetails.rawType} />

        <DeskInformation
          name={deskDetails.name}
          levelName={deskDetails.level?.name}
          locationName={deskDetails.location.name}
        />
      </DeskInfoHeader>

      <NoAccessOrPermissionAlert
        unbookableReasons={deskAvailability?.unbookableReasons}
      />

      <DisabledDeskAlert
        isReservable={deskDetails.isReservable}
        isDisabled={deskDetails.isDisabled}
      />

      <AssignedDeskAlert
        isDuringExclusion={isSelectedStartTimeDuringExclusion}
        isReservable={deskDetails.isReservable}
        isDisabled={deskDetails.isDisabled}
        rawTypes={deskDetails.rawType}
        availability={deskAvailability.availability}
      />

      <DeskAmenities deskId={deskId}></DeskAmenities>

      <DeskBookingPolicies deskId={deskId}></DeskBookingPolicies>

      <DeskStickers stickers={deskDetails.stickers} />
    </DeskDetailsContent>
  );
};

const DeskDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--main-colors-white-white-0, #fff);
`;

const DeskInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
