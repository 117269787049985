import { useRecoilValue, useSetRecoilState } from 'recoil';
import { editSpaceView } from './atoms';
import { useSetRightSidebarView } from 'atoms/sidebar/hooks';
import { EditSpaceView } from './types';

export const useEditSpaceView = () => {
  return useRecoilValue(editSpaceView);
};

export const useSetSpaceSidebarView = () => {
  const setSidebarView = useSetRightSidebarView();
  const setSpaceView = useSetRecoilState(editSpaceView);
  return (view: EditSpaceView) => {
    setSidebarView('spaces');
    setSpaceView(view);
  };
};
