import { useCallback, useMemo } from 'react';
import {
  Availability,
  BookingEnabledQuery,
  BookingEnabledQueryVariables,
  DeskType,
  DeskUnbookableReasonType,
} from 'generated';
import moment from 'moment';
import { useTimezone } from 'atoms/resource';
import { gql } from '@apollo/client';
import { useApolloContext } from 'contexts';
import { useQueryCachedLoad } from 'hooks';
import { DeskSettingSlug } from '../graphql/useDeskSettings';
import { useDeskAvailabilityQueryParameters } from 'hooks/useDeskAvailabilityParameters';
import { useAuthContext } from 'contexts';

type DeskDetails = BookingEnabledQuery['getDesksByIds'][number];
type DeskDetailsState = DeskDetails['state'];
type AvailabilityDetails =
  BookingEnabledQuery['reservationsMultiDayGroupedByDate'][number];

type DeskWithAvailability = DeskDetails & {
  availabilityDetails: AvailabilityDetails | undefined;
};

type DeskBookingEnabledMap = {
  [key: string]: {
    isBookable: boolean;
    isSelectedStartTimeDuringExclusion: boolean;
    noAccessOrPermission: boolean;
    requiresAssignmentByAdmin: boolean;
  };
};

type DeskUnbookableReasons = NonNullable<
  BookingEnabledQuery['reservationsMultiDayGroupedByDate'][number]['unbookableReasons']
>;

const BOOKING_ENABLED_QUERY = gql`
  query BookingEnabled(
    $deskIds: [ID!]!
    $dates: [LocalDate!]!
    $startTime: LocalTime!
    $durationInMinutes: Int!
    $timezone: IANATimezone!
    $userId: Int!
    $startTimeMoment: Date!
    $endTimeMoment: Date!
    $recurrence: String
  ) {
    getDesksByIds(ids: $deskIds) {
      id
      rawType
      isReservable
      isDisabled
      settings {
        slug
        value
      }
      state(
        startTime: $startTimeMoment
        endTime: $endTimeMoment
        userId: $userId
        recurrence: $recurrence
      ) {
        exclusions {
          startTime
          endTime
        }
      }
    }
    reservationsMultiDayGroupedByDate(
      deskIds: $deskIds
      dates: $dates
      startTime: $startTime
      durationInMinutes: $durationInMinutes
      timezone: $timezone
      userId: $userId
    ) {
      deskId
      availability
      unbookableReasons {
        reason
      }
    }
  }
`;

export const useBookingEnabledForDesks = (
  deskIds: string[] | null | undefined
) => {
  const { tenantId } = useApolloContext();
  const { currentUser } = useAuthContext();
  const { timezone } = useTimezone();
  const { startTimes, endTime, recurrence, durationInMinutes, dates } =
    useDeskAvailabilityQueryParameters();

  const { data, loading } = useQueryCachedLoad<
    BookingEnabledQuery,
    BookingEnabledQueryVariables
  >(
    BOOKING_ENABLED_QUERY,
    {
      context: {
        headers: {
          'cache-refresh': 'no-cache',
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip:
        !tenantId ||
        !deskIds?.length ||
        !startTimes ||
        startTimes.length === 0 ||
        !endTime ||
        !currentUser,
      variables: {
        deskIds: deskIds || [],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        startTimeMoment: startTimes![0].toString(),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        endTimeMoment: endTime!.toString(),
        recurrence: recurrence,
        timezone: timezone,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        userId: Number(currentUser!.id),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        startTime: startTimes![0]!.format('H:m'),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        durationInMinutes: durationInMinutes,
        dates: dates,
      },
    },
    ['deskIds']
  );

  const [deskDetails, deskAvailabilityDetails] = useMemo(() => {
    return [data?.getDesksByIds, data?.reservationsMultiDayGroupedByDate];
  }, [data]);

  // Match up the availability details with the desk details
  const desksWithAvailability: DeskWithAvailability[] | undefined =
    useMemo(() => {
      return deskDetails?.map((desk) => {
        const availabilityDetails = deskAvailabilityDetails?.find(
          (avail) => avail.deskId === desk.id
        );
        return {
          ...desk,
          availabilityDetails,
        };
      });
    }, [deskDetails, deskAvailabilityDetails]);

  const isAssignedAndShared = useCallback((rawType: DeskType[]) => {
    return (
      rawType?.length === 2 &&
      rawType.includes(DeskType.Assigned) &&
      rawType.includes(DeskType.Shared)
    );
  }, []);

  const isNotAssignedAndAvailable = useCallback(
    (availability: Availability | undefined, isAssigned: boolean) => {
      const isAvailable = availability === Availability.Available;
      return !isAssigned && isAvailable;
    },
    []
  );

  const isSelectedStartTimeDuringExclusion = useCallback(
    (deskDetailsState: DeskDetailsState) => {
      if (
        !deskDetailsState.exclusions ||
        deskDetailsState.exclusions.length === 0
      ) {
        return false;
      }

      return deskDetailsState.exclusions.some((exclusion) => {
        const exclusionStart = moment(exclusion.startTime);
        const exclusionEnd = moment(exclusion.endTime);

        return startTimes?.[0]?.isBetween(
          exclusionStart,
          exclusionEnd,
          undefined,
          '[)'
        );
      });
    },
    [startTimes]
  );

  const noAccessOrPermission = useCallback(
    (unbookableReasons: DeskUnbookableReasons) => {
      const lackingPermissions = unbookableReasons.find((unbookable) => {
        return (
          unbookable?.reason === DeskUnbookableReasonType.LackingPermissions ||
          unbookable?.reason === DeskUnbookableReasonType.NoAccess
        );
      });
      return !!lackingPermissions;
    },
    []
  );

  const isBookable = useCallback(
    (desk: DeskWithAvailability) => {
      const assignedAndShared = isAssignedAndShared(desk.rawType);
      const notAssignedAndAvailable = isNotAssignedAndAvailable(
        desk.availabilityDetails?.availability,
        assignedAndShared
      );
      const startTimeDuringExclusion = isSelectedStartTimeDuringExclusion(
        desk.state
      );

      return (
        notAssignedAndAvailable ||
        (desk?.availabilityDetails?.availability === Availability.Available &&
          startTimeDuringExclusion)
      );
    },
    [
      isAssignedAndShared,
      isNotAssignedAndAvailable,
      isSelectedStartTimeDuringExclusion,
    ]
  );

  const requiresAssignmentByAdmin = useCallback(
    (desk: DeskWithAvailability) => {
      const settings = desk.settings || [];

      const allowExclusionsSetting = settings.find(
        (s) => s.slug === DeskSettingSlug.AllowExclusions
      )?.value;

      const allowExclusions = allowExclusionsSetting === 'true';

      return allowExclusions
        ? isAssignedAndShared(desk.rawType) &&
            !isSelectedStartTimeDuringExclusion(desk.state)
        : isAssignedAndShared(desk.rawType);
    },
    [isAssignedAndShared, isSelectedStartTimeDuringExclusion]
  );

  return {
    loading,
    data: desksWithAvailability?.reduce<DeskBookingEnabledMap>((acc, desk) => {
      acc[desk.id] = {
        isBookable: isBookable(desk),
        isSelectedStartTimeDuringExclusion: isSelectedStartTimeDuringExclusion(
          desk.state
        ),
        noAccessOrPermission: noAccessOrPermission(
          desk.availabilityDetails?.unbookableReasons || []
        ),
        requiresAssignmentByAdmin: requiresAssignmentByAdmin(desk),
      };
      return acc;
    }, {}),
  };
};
