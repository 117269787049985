import { useTimeFilters } from 'hooks/useTimeFilters';
import { TimeDropdown } from './TimeDropdown';
import { useMapControlsContext } from 'contexts';
import { useGetStartLocationTime } from 'atoms/resource';
import { LocationDateTimeMoment } from 'utils';

interface TimeDropdownProps {
  onTimeSelect?: (time: LocationDateTimeMoment) => void;
  status?: '' | 'warning' | 'error' | undefined;
}

// drop down that keeps track of clock for calculating start time
export const StartTimeDropdown = ({
  onTimeSelect,
  status,
}: TimeDropdownProps): JSX.Element => {
  const { selectedLocationId } = useMapControlsContext();
  const { minStartTime, maxStartTime } = useTimeFilters(selectedLocationId);
  const startTime = useGetStartLocationTime();
  return (
    <TimeDropdown
      minimum={minStartTime}
      maximum={maxStartTime}
      selectedTime={startTime}
      onTimeSelect={onTimeSelect}
      status={status}
    ></TimeDropdown>
  );
};
