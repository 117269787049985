import {
  useMapDataLoadingContext,
  useMapLoadingContext,
} from '@robinpowered/perseus';
import { useDebounce } from 'react-use';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from '@robinpowered/ui-kit';

const messageKey = 'map-loading';

export const MapLoadingMessage = () => {
  const { t } = useTranslation('common');
  const { mapLoading } = useMapLoadingContext();
  const { mapDataLoading } = useMapDataLoadingContext();

  const loadingMessage = useMemo(() => {
    if (mapLoading) {
      return t('loading.map');
    }

    if (mapDataLoading) {
      return t('loading.availability');
    }

    return;
  }, [mapDataLoading, mapLoading, t]);

  useEffect(() => {
    if (loadingMessage) {
      void message.open({
        key: messageKey,
        type: 'loading',
        content: loadingMessage,
        duration: 0, // Do not auto dismiss
      });
    }
  }, [loadingMessage]);

  useDebounce(
    () => {
      // If there is no loading message destroy the message after 500 ms
      if (!loadingMessage) {
        message.destroy(messageKey);
      }
    },
    500,
    [loadingMessage]
  );

  return null;
};
