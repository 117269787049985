import { FC } from 'react';

import { checkIsExhaustive } from 'utils';
import { SpaceDetails } from './SpaceDetails';
import { useEditSpaceView } from 'atoms/space';
import { SpaceList } from './space-list';

export const SpaceSidebar: FC = () => {
  const view = useEditSpaceView();
  switch (view) {
    case 'space-details':
      return <SpaceDetails />;
    case 'space-list':
      return <SpaceList />;
    default:
      checkIsExhaustive(view);
      return null;
  }
};
