import {
  useResetMapInteractiveLayesrs,
  useSetCurrentFilter,
  useSetMapInteractiveLayers,
} from 'atoms/mapInteractions/hooks';
import { FilterType, InteractiveLayer } from 'atoms/resource/types';
import { useCallback } from 'react';

export const useFilterTagClick = ({
  type,
  active,
  activeLayers,
}: {
  type: FilterType | null;
  active: boolean;
  activeLayers: Set<InteractiveLayer>;
}) => {
  const setMapInteractiveLayers = useSetMapInteractiveLayers();
  const resetMapInteractiveLayers = useResetMapInteractiveLayesrs();
  const setCurrentFilter = useSetCurrentFilter();

  const handleClick = useCallback(() => {
    if (!active) {
      setCurrentFilter(type);
      setMapInteractiveLayers(activeLayers);
    } else {
      setCurrentFilter(null);
      resetMapInteractiveLayers();
    }
  }, [
    active,
    activeLayers,
    resetMapInteractiveLayers,
    setCurrentFilter,
    setMapInteractiveLayers,
    type,
  ]);

  return { handleClick };
};
