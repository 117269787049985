import {
  createContext,
  useContext,
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
} from 'react';
import intersection from 'lodash/intersection';
import { gql, useQuery } from '@apollo/client';
import { useSelectedLevel } from 'atoms/resource';
import { SpaceFilterQuery, SpaceFilterQueryVariables } from 'generated';
import {
  useSetSpaceFilterInitialized,
  useSetFilteredSpaceIds,
} from 'atoms/mapInteractions';

const SPACE_FILTER_QUERY = gql`
  query spaceFilter($levelId: ID!) {
    getLevelById(id: $levelId) {
      spaces {
        id
      }
    }
  }
`;

type SpaceFilterContextValue = {
  setSpaceIdsThatMatchTypeCriteria: Dispatch<SetStateAction<string[]>>;
  setSpaceIdsThatMatchAmenityCriteria: Dispatch<SetStateAction<string[]>>;
  setSpaceIdsThatMatchCapacityCriteria: Dispatch<SetStateAction<string[]>>;
};

const SpaceFilterContext = createContext<SpaceFilterContextValue>({
  setSpaceIdsThatMatchTypeCriteria: () => {
    throw new Error('Not Implemented');
  },
  setSpaceIdsThatMatchAmenityCriteria: () => {
    throw new Error('Not Implemented');
  },
  setSpaceIdsThatMatchCapacityCriteria: () => {
    throw new Error('Not Implemented');
  },
});

export const SpaceFilterContextProvider: FC = ({ children }) => {
  const [levelId] = useSelectedLevel();
  const setFilteredSpaceIds = useSetFilteredSpaceIds();
  const setSpaceFilterInitialized = useSetSpaceFilterInitialized();

  const { data, loading } = useQuery<
    SpaceFilterQuery,
    SpaceFilterQueryVariables
  >(SPACE_FILTER_QUERY, {
    skip: !levelId,
    variables: {
      levelId: levelId || '',
    },
  });

  const [spaceIdsThatMatchTypeCrtieria, setSpaceIdsThatMatchTypeCriteria] =
    useState<string[]>([]);

  const [
    spaceIdsThatMatchAmenityCriteria,
    setSpaceIdsThatMatchAmenityCriteria,
  ] = useState<string[]>([]);

  const [
    spaceIdsThatMatchCapacityCriteria,
    setSpaceIdsThatMatchCapacityCriteria,
  ] = useState<string[]>([]);

  useEffect(() => {
    setSpaceFilterInitialized(!loading);
  }, [setSpaceFilterInitialized, loading]);

  useEffect(() => {
    setSpaceIdsThatMatchTypeCriteria(
      data?.getLevelById?.spaces?.map((space) => space.id) || []
    );
    setSpaceIdsThatMatchAmenityCriteria(
      data?.getLevelById?.spaces?.map((space) => space.id) || []
    );

    const allSpaceIds =
      data?.getLevelById?.spaces?.map((space) => space.id) || [];

    setSpaceIdsThatMatchTypeCriteria(allSpaceIds);
    setSpaceIdsThatMatchAmenityCriteria(allSpaceIds);
    setSpaceIdsThatMatchCapacityCriteria(allSpaceIds);
  }, [data?.getLevelById?.spaces]);

  const spaceIdsThatMatchAllCriteria = useMemo(() => {
    return intersection(
      spaceIdsThatMatchTypeCrtieria,
      spaceIdsThatMatchAmenityCriteria,
      spaceIdsThatMatchCapacityCriteria
    );
  }, [
    spaceIdsThatMatchAmenityCriteria,
    spaceIdsThatMatchTypeCrtieria,
    spaceIdsThatMatchCapacityCriteria,
  ]);

  useEffect(() => {
    setFilteredSpaceIds(spaceIdsThatMatchAllCriteria);
  }, [setFilteredSpaceIds, spaceIdsThatMatchAllCriteria]);

  const value: SpaceFilterContextValue = {
    setSpaceIdsThatMatchTypeCriteria,
    setSpaceIdsThatMatchAmenityCriteria,
    setSpaceIdsThatMatchCapacityCriteria,
  };

  return (
    <SpaceFilterContext.Provider value={value}>
      {children}
    </SpaceFilterContext.Provider>
  );
};

export const useSpaceFilterContext = (): SpaceFilterContextValue => {
  return useContext(SpaceFilterContext);
};
