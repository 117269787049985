import { DeskWithAvailability } from '../types';
import { useDeskListItemData } from '../../graphql/useDeskListItemData';
import { useDatePickerType } from 'atoms/resource';
import { DeskListItemSkeleton } from './DeskListItemSkeleton';
import { DeskListItemNoAccess } from './DeskListItemNoAccess';
import { checkIsExhaustive } from 'utils';
import { DeskListItemAssigned } from './DeskListItemAssigned';
import { DeskListItemCurrentUserReservation } from './DeskListItemCurrentUserReservation';
import { DeskListItemPrivate } from './DeskListItemPrivate';
import { DeskListItemBooked } from './DeskListItemBooked';
import { DeskListItemUnavailable } from './DeskListItemUnavailable';
import { DeskListItemAvailable } from './DeskListItemAvailable';
import { useDeskListItemType } from './hooks';

export const DeskListItem = ({ desk }: { desk: DeskWithAvailability }) => {
  const datePickerType = useDatePickerType();

  // We only want to layer in reservation data if we are using the single date picker
  const { desk: deskData, loading } = useDeskListItemData({
    deskId: desk.id,
    skip: datePickerType !== 'single',
  });

  const itemType = useDeskListItemType(desk, deskData);

  if (loading) {
    return <DeskListItemSkeleton />;
  }

  switch (itemType) {
    case 'no_access_or_permission':
      return <DeskListItemNoAccess deskId={desk.id} deskName={desk.name} />;

    case 'assigned': {
      return (
        <DeskListItemAssigned
          deskId={desk.id}
          deskName={desk.name}
          assignedReservation={deskData?.state?.reservations?.[0]}
        />
      );
    }

    case 'current_user_reservation':
      return (
        <DeskListItemCurrentUserReservation
          deskId={desk.id}
          deskName={desk.name}
          currentUserReservation={deskData?.state?.reservations?.[0]}
        />
      );

    case 'private':
      return <DeskListItemPrivate deskId={desk.id} />;

    case 'booked':
      return (
        <DeskListItemBooked
          deskId={desk.id}
          reservation={deskData?.state?.reservations?.[0]}
        />
      );

    case 'unavailable':
      return <DeskListItemUnavailable deskId={desk.id} deskName={desk.name} />;

    case 'available':
      return <DeskListItemAvailable deskId={desk.id} deskName={desk.name} />;

    default:
      checkIsExhaustive(itemType);
      return null;
  }
};
