import React, {
  FC,
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import moment from 'moment';
import { useInterval } from 'react-use';
import { hasFutureDateSelected, IsoString, momentToIsoString } from 'utils';
import {
  startTimeState,
  useGetStartTimesForSelectedDates,
  useSetEndTimeFromStartTimeForDesks,
  useSetStartTime,
  useTimezone,
  useSetEndTimeFromStartTimeForSpaces,
} from 'atoms/resource';
import { useRecoilValue } from 'recoil';

export const ClockStateContext = createContext<IsoString>(
  momentToIsoString(moment())
);

export const ClockProvider: FC = ({ children }) => {
  const TICK_INTERVAL_IN_MS = 1000;

  const { timezone } = useTimezone();
  const [savedTimeZone, setSavedTimeZone] = useState(timezone);
  const [clock, setClock] = useState<IsoString>(momentToIsoString(moment()));
  const [isDocumentVisible, setIsDocumentVisible] = useState(!document.hidden);

  const selectedDates = useGetStartTimesForSelectedDates();

  const isViewingFutureDate = useMemo(() => {
    if (!selectedDates) {
      return false;
    }

    return hasFutureDateSelected(selectedDates, timezone);
  }, [selectedDates, timezone]);

  const startTime = useRecoilValue(startTimeState);

  const setStartTime = useSetStartTime();
  const setEndTimeFromStartTimeForSpaces =
    useSetEndTimeFromStartTimeForSpaces();
  const setEndTimeFromStartTimeForDesks = useSetEndTimeFromStartTimeForDesks();

  const handleVisibilityChange = () => {
    setIsDocumentVisible(!document.hidden);
  };

  /**
   * When the document is hidden i.e. robin app is not in focus, we don't
   * need to update the clock. Resume the clock when the app is in focus.
   */
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  //Only update on the minute mark
  useInterval(() => {
    if (isDocumentVisible) {
      const now = moment();
      if (!now.isSame(moment(clock), 'minute')) {
        setClock(momentToIsoString(now));
      }
    }
  }, TICK_INTERVAL_IN_MS);

  // The clock is responsible for updating availability if it gets ahead of the start time
  useEffect(() => {
    const timezoneChanged = timezone !== savedTimeZone;
    setSavedTimeZone(timezone);
    const currentClock = moment(clock);

    if (
      (!isViewingFutureDate &&
        (!startTime || moment(startTime).isBefore(currentClock))) ||
      timezoneChanged
    ) {
      setStartTime(currentClock);
      setEndTimeFromStartTimeForSpaces(currentClock);
      setEndTimeFromStartTimeForDesks(currentClock);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clock, timezone]);

  return (
    <ClockStateContext.Provider value={clock}>
      {children}
    </ClockStateContext.Provider>
  );
};

export const useClock = (): IsoString => {
  const clock = useContext(ClockStateContext);
  return clock;
};
