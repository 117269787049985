const environments = ['production', 'staging', 'development', 'test'] as const;
type Environment = typeof environments[number];

const robinEnv = process.env.REACT_APP_ROBIN_ENV
  ? process.env.REACT_APP_ROBIN_ENV
  : window.REACT_APP_ROBIN_ENV;

declare global {
  interface Window {
    NODE_ENV: 'production';
    REACT_APP_ROBIN_ENV: Environment;
    REACT_APP_DASHBOARD_URL: string;
    REACT_APP_GQL_URL: string;
    REACT_APP_DASHBOARD_API_URL: string;
    REACT_APP_ATLAS_URL: string;
    REACT_APP_SHARE_URL: string;
    REACT_APP_AMPLITUDE_API_KEY: string;
    REACT_APP_COOKIE_NAME: string;
    REACT_APP_AUTH_COOKIE_NAME: string;
    REACT_APP_NAME: string;
    REACT_APP_VERSION: string;
    REACT_APP_IMAGE_SERVER_URL: string;
    REACT_APP_DD_APPLICATION_ID: string;
    REACT_APP_DD_CLIENT_TOKEN: string;
    REACT_APP_DD_SITE: string | undefined;
    REACT_APP_FEATURE_FLAG_URL: string;
    [key: string]: string;
  }
}

type Config = {
  env: string;
  robinEnv: string;
  dashboardUrl: string;
  gqlUrl: string;
  dashboardApiUrl: string;
  atlasUrl: string;
  shareUrl: string;
  amplitudeApiKey: string;
  sessionCookieName: string;
  authSessionCookieName: string;
  appName: string;
  appVersion: string;
  imageServerUrl: string;
  ddApplicationId: string;
  ddClientToken: string;
  ddSite: string;
  featureFlagUrl: string;
};

const envToConfigMapping: { [K in keyof Config]: string } = {
  env: 'NODE_ENV',
  robinEnv: 'REACT_APP_ROBIN_ENV',
  dashboardUrl: 'REACT_APP_DASHBOARD_URL',
  gqlUrl: 'REACT_APP_GQL_URL',
  dashboardApiUrl: 'REACT_APP_DASHBOARD_API_URL',
  atlasUrl: 'REACT_APP_ATLAS_URL',
  shareUrl: 'REACT_APP_SHARE_URL',
  amplitudeApiKey: 'REACT_APP_AMPLITUDE_API_KEY',
  sessionCookieName: 'REACT_APP_COOKIE_NAME',
  authSessionCookieName: 'REACT_APP_AUTH_COOKIE_NAME',
  appName: 'REACT_APP_NAME',
  appVersion: 'REACT_APP_VERSION',
  imageServerUrl: 'REACT_APP_IMAGE_SERVER_URL',
  ddApplicationId: 'REACT_APP_DD_APPLICATION_ID',
  ddClientToken: 'REACT_APP_DD_CLIENT_TOKEN',
  ddSite: 'REACT_APP_DD_SITE',
  featureFlagUrl: 'REACT_APP_FEATURE_FLAG_BASE_URL',
};

const serverConfig: Config = {
  imageServerUrl: process.env.STORYBOOK_ENV
    ? 'https://images.staging.robinpowered.com'
    : window.REACT_APP_IMAGE_SERVER_URL,
  ddSite: window.REACT_APP_DD_SITE || 'datadoghq.com',
} as Config;

// map through each key with it's equivalent in env.js file
Object.keys(envToConfigMapping).forEach((key) => {
  const typedKey = key as keyof Config;
  const envVal = window[envToConfigMapping[typedKey]];
  if (envVal && envVal.length > 0)
    serverConfig[typedKey] = window[envToConfigMapping[typedKey]];
});

const localConfig: { [key: string]: string } = { ...serverConfig };

// for local, map through each key with it's equivalent in .env file
Object.keys(envToConfigMapping).forEach((key) => {
  const typedKey = key as keyof Config;
  const envVal = process.env[envToConfigMapping[typedKey]];
  if (envVal) {
    localConfig[key] = envVal;
  }
});

export const config = robinEnv === 'local' ? localConfig : serverConfig;

/* eslint-disable no-console */
if (config.env === 'development') {
  console.groupCollapsed('Started app with config');
  console.table(config);
  console.groupEnd();
}
/* eslint-enable no-console */
