import { gql, useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  GetReleaseDeskScheduleByDeskIdQuery,
  GetReleaseDeskScheduleByDeskIdQueryVariables,
} from 'generated';

export type SharedDeskSchedules =
  GetReleaseDeskScheduleByDeskIdQuery['getSharedDesksSchedules'];

export const GET_SHARED_DESK_SCHEDULE = gql`
  query GetReleaseDeskScheduleByDeskId($getSharedDeskScheduleIds: [ID!]!) {
    getSharedDesksSchedules(deskIds: $getSharedDeskScheduleIds) {
      id
      schedule {
        id
        recurrence
      }
    }
  }
`;

export const useReleaseDeskSchedule = (deskId: string | undefined) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    GetReleaseDeskScheduleByDeskIdQuery,
    GetReleaseDeskScheduleByDeskIdQueryVariables
  >(GET_SHARED_DESK_SCHEDULE, {
    skip: !tenantId || !deskId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      getSharedDeskScheduleIds: deskId || '',
    },
  });

  return {
    loading,
    data: data?.getSharedDesksSchedules,
  };
};
