import { message } from '@robinpowered/ui-kit';
import {
  useEditDeskNewDeskId,
  useSetEditDeskNewDeskId,
  useSetEditDeskReservationId,
} from 'atoms/editDesk';
import { useSetMapMode } from 'atoms/mapInteractions';
import { useSetCurrentlySelectedResource } from 'atoms/resource';
import {
  useSetDeskSidebarView,
  useSetRightSidebarView,
} from 'atoms/sidebar/hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useOnCompleted = () => {
  const { t } = useTranslation('resourceDetails');

  const newDeskId = useEditDeskNewDeskId();
  const setRightSidebarView = useSetRightSidebarView();
  const setMapMode = useSetMapMode();
  const setEditDeskNewDeskId = useSetEditDeskNewDeskId();
  const setEditReservationId = useSetEditDeskReservationId();
  const setSelectedResource = useSetCurrentlySelectedResource();
  const setDeskSidebarView = useSetDeskSidebarView();
  const onCompleted = useCallback(() => {
    setRightSidebarView('desks');
    setDeskSidebarView('desk-details');
    setMapMode('browse');
    setEditDeskNewDeskId(null);
    setEditReservationId(null);

    if (newDeskId) {
      setSelectedResource({
        type: 'seats',
        id: newDeskId,
      });
    }

    void message.success(t('edit_desk_reservation.success'));
  }, [
    setEditDeskNewDeskId,
    setEditReservationId,
    setMapMode,
    setRightSidebarView,
    setSelectedResource,
    t,
    newDeskId,
    setDeskSidebarView,
  ]);

  return onCompleted;
};
