import { gql, QueryResult } from '@apollo/client';
import {
  DeskReservationsForCancelModalQuery,
  DeskReservationsForCancelModalQueryVariables,
} from 'generated';
import { useQueryCachedLoad } from 'hooks';
import { Moment } from 'moment';

export type DeskReservationsForCancelModal =
  DeskReservationsForCancelModalQuery['getDeskReservationsByUserId'];

export const GET_DESK_RESERVATIONS_FOR_USER = gql`
  query DeskReservationsForCancelModal(
    $userId: ID!
    $start: Date!
    $end: Date!
  ) {
    getDeskReservationsByUserId(id: $userId, startTime: $start, endTime: $end) {
      userId
      reservations {
        id
        startTime
      }
    }
  }
`;

export function useDeskReservationsByUserId(
  reservationsStart: Moment,
  reservationsEnd: Moment,
  userId?: string
): QueryResult<
  DeskReservationsForCancelModalQuery,
  DeskReservationsForCancelModalQueryVariables
> {
  return useQueryCachedLoad<
    DeskReservationsForCancelModalQuery,
    DeskReservationsForCancelModalQueryVariables
  >(GET_DESK_RESERVATIONS_FOR_USER, {
    variables: {
      userId: userId ? userId : '',
      start: reservationsStart.toISOString(),
      end: reservationsEnd.toISOString(),
    },
    skip: !userId,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });
}
