import { useLazyQuery, useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  SpaceResourceDetailsQuery,
  SpaceResourceDetailsQueryVariables,
} from 'generated';
import gql from 'graphql-tag';
import { useEffect } from 'react';

const SPACE_RESOURCE_DETAILS = gql`
  query SpaceResourceDetails($id: ID!) {
    getSpaceById(id: $id) {
      id
      name
      description
      image
      type
      capacity
      isAccessible
      locationId
      isManaged
      calendar {
        calendarId
      }
      permissions {
        name
        value
      }
    }
  }
`;

const LOCATION_DETAILS = gql`
  query LocationDetails($locationId: ID!) {
    getLocationById(id: $locationId) {
      id
      name
      levels {
        id
        name
      }
    }
  }
`;

export const useSpaceResourceDetails = (spaceId: string | undefined) => {
  const { tenantId } = useApolloContext();

  const { loading, data: spaceData } = useQuery<
    SpaceResourceDetailsQuery,
    SpaceResourceDetailsQueryVariables
  >(SPACE_RESOURCE_DETAILS, {
    skip: !tenantId || !spaceId,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: spaceId || '',
    },
  });

  const [
    fetchLocationDetails,
    { loading: locationLoading, data: locationData },
  ] = useLazyQuery(LOCATION_DETAILS, { fetchPolicy: 'cache-first' });

  useEffect(() => {
    if (spaceData?.getSpaceById?.locationId) {
      void fetchLocationDetails({
        variables: { locationId: spaceData.getSpaceById.locationId },
      });
    }
  }, [spaceData, fetchLocationDetails]);

  return {
    loading: loading || locationLoading,
    spaceDetails: spaceData?.getSpaceById,
    locationDetails: locationData?.getLocationById,
  };
};
