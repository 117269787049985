import { ResourceTags } from '../ResourceTags';
import { Skeleton } from '@robinpowered/ui-kit';
import { useSpaceAmenitiesDetails } from './graphql/useSpaceAmenitiesDetails';
import { useTranslation } from 'react-i18next';

export function SpaceAmenities({
  spaceId,
}: {
  spaceId: string | undefined;
}): JSX.Element | null {
  const { t } = useTranslation('resourceDetails');
  const { spaceDetails, loading } = useSpaceAmenitiesDetails(spaceId);

  if (loading) return <Skeleton active />;
  if (!spaceDetails?.amenities || spaceDetails?.amenities.length === 0)
    return null;

  return (
    <ResourceTags title={t('amenities')} resources={spaceDetails.amenities} />
  );
}
