import { atom } from 'recoil';

export const AtomKey = {
  editDeskReservationId: 'editDeskReservationId',
  editDeskNewDeskId: 'editDeskNewDeskId',
  editDeskPotentialDeskId: 'editDeskPotentialDeskId',
};

export const editDeskReservationId = atom<string | null>({
  key: AtomKey.editDeskReservationId,
  default: null,
});

export const editDeskNewDeskId = atom<string | null>({
  key: AtomKey.editDeskNewDeskId,
  default: null,
});

export const editDeskPotentialDeskId = atom<string | null>({
  key: AtomKey.editDeskPotentialDeskId,
  default: null,
});
