import { ApolloLink } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';

let persistedQueryLink: ApolloLink | undefined;

try {
  // IE11 & Edge will not be able to use crypto, so only add persisted queries
  // where it's available.
  if (
    typeof window?.crypto?.subtle?.digest !== 'undefined' &&
    typeof window?.TextEncoder !== 'undefined'
  ) {
    /**
     * Persisted query link allows us to reduce the overhead
     * of repeated requests by sharing a request hash with the server.
     * This link also improves compatibility with CDN access by allowing
     * graphql over GET requests
     */
    persistedQueryLink = createPersistedQueryLink({
      sha256,
      useGETForHashedQueries: true,
      disable: (error) => {
        if (error.networkError) {
          return true;
        }
        return false;
      },
    });
  }
} catch (e) {} // eslint-disable-line no-empty

export { persistedQueryLink };
