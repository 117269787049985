import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  editDeskReservationId,
  editDeskNewDeskId,
  editDeskPotentialDeskId,
} from './atoms';

export const useEditDeskReservationId = () => {
  return useRecoilValue(editDeskReservationId);
};

export const useSetEditDeskReservationId = () => {
  return useSetRecoilState(editDeskReservationId);
};

export const useEditDeskPotentialDeskId = () => {
  return useRecoilValue(editDeskPotentialDeskId);
};

export const useSetEditDeskPotentialDeskId = () => {
  return useSetRecoilState(editDeskPotentialDeskId);
};

export const useEditDeskNewDeskId = () => {
  return useRecoilValue(editDeskNewDeskId);
};

export const useSetEditDeskNewDeskId = () => {
  return useSetRecoilState(editDeskNewDeskId);
};
