import { gql, useQuery } from '@apollo/client';
import { Select } from '@robinpowered/ui-kit';
import { useSelectedLevel } from 'atoms/resource';
import { useCallback, useMemo, useState } from 'react';
import { useSpaceFilterContext } from '../../context/SpaceFilterContext';
import { useTranslation } from 'react-i18next';
import { SpaceTypeFilterQuery, SpaceTypeFilterQueryVariables } from 'generated';
import {
  getSpaceIdsThatAreOfSelectedType,
  spaceTypesToSelectOptions,
} from './utils/type';
import { NoResults } from '../../NoResults';

const TYPE_FILTER_QUERY = gql`
  query spaceTypeFilter($levelId: ID!) {
    getLevelById(id: $levelId) {
      spaces {
        id
        type
      }
    }
  }
`;

export type Spaces = NonNullable<
  SpaceTypeFilterQuery['getLevelById']
>['spaces'];

export const TypeFilter = () => {
  const { t } = useTranslation('filters');
  const [levelId] = useSelectedLevel();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const { setSpaceIdsThatMatchTypeCriteria } = useSpaceFilterContext();

  const { loading, data } = useQuery<
    SpaceTypeFilterQuery,
    SpaceTypeFilterQueryVariables
  >(TYPE_FILTER_QUERY, {
    skip: !levelId,
    variables: {
      levelId: levelId || '',
    },
  });

  const handleChange = useCallback(
    (updatedTypes: string[]) => {
      setSelectedTypes(updatedTypes);

      if (updatedTypes.length === 0) {
        setSpaceIdsThatMatchTypeCriteria(
          data?.getLevelById?.spaces?.map((space) => space.id) || []
        );
        return;
      }

      setSpaceIdsThatMatchTypeCriteria(
        getSpaceIdsThatAreOfSelectedType(
          data?.getLevelById?.spaces,
          updatedTypes
        )
      );
    },
    [data?.getLevelById?.spaces, setSpaceIdsThatMatchTypeCriteria]
  );

  const options = useMemo(
    () => spaceTypesToSelectOptions(data?.getLevelById?.spaces, t),
    [data?.getLevelById?.spaces, t]
  );

  return (
    <Select
      loading={loading}
      value={selectedTypes}
      onChange={handleChange}
      mode="multiple"
      style={{ width: '200px' }}
      options={options}
      placeholder={t('SPACE_FILTERS.FILTER_LABELS.TYPES')}
      maxTagCount="responsive"
      data-testid="space-type-filter"
      notFoundContent={<NoResults />}
    />
  );
};
