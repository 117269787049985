import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentlySelectedResource,
  datePickerType,
  endTimeStateForDesks,
  endTimeStateForSpaces,
  locationTimezone,
  selectedDatesState,
  selectedLevel,
  selectedLocation,
  startTimeState,
} from './atoms';
import {
  sortDatesAscending,
  LocationDateTimeMoment,
  calculateMinimumEndTime,
  momentToIsoString,
  IsoString,
  momentToLocationDateTime,
  getLongTimeZoneName,
} from 'utils';
import moment from 'moment';
import { useMemo } from 'react';
import { useEndTime } from 'hooks/useEndTime';

type TimezoneContextType = {
  timezone: string;
  longName: string;
};

const createLocalDateTime = (
  date: IsoString,
  time: IsoString,
  timezone: string
): LocationDateTimeMoment => {
  const dateMoment = moment.tz(date, timezone);
  const timeMoment = moment.tz(time, timezone);
  const combinedMoment = dateMoment.set({
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    second: timeMoment.second(),
  });
  return combinedMoment as LocationDateTimeMoment;
};

const isoStringtoLocalTime = (
  time: IsoString,
  timezone: string
): LocationDateTimeMoment => {
  return momentToLocationDateTime(moment(time), timezone);
};

export const useCurrentlySelectedResource = () => {
  return useRecoilValue(currentlySelectedResource);
};

export const useSetCurrentlySelectedResource = () => {
  return useSetRecoilState(currentlySelectedResource);
};

export const useSelectedLocation = () => {
  return [
    useRecoilValue(selectedLocation),
    useSetRecoilState(selectedLocation),
  ] as const;
};

export const useSelectedLevel = () => {
  return [
    useRecoilValue(selectedLevel),
    useSetRecoilState(selectedLevel),
  ] as const;
};

export const useTimezone = (): TimezoneContextType => {
  const timezone = useRecoilValue(locationTimezone);
  const longName = getLongTimeZoneName(timezone) || '';
  return {
    timezone,
    longName,
  };
};

export const useSetTimezone = () => {
  return useSetRecoilState(locationTimezone);
};

export const useSetSelectedDates = () => {
  const setSelectedDates = useSetRecoilState(selectedDatesState);
  return (dates: moment.Moment[]) => {
    const datesString = dates.map(momentToIsoString);
    setSelectedDates(datesString);
  };
};

export const useSetStartTime = () => {
  const setStartTime = useSetRecoilState(startTimeState);
  return (startTime: moment.Moment) => {
    const startTimeString = momentToIsoString(startTime);
    setStartTime(startTimeString);
  };
};

export const useSetEndTimeFromStartTimeForSpaces = () => {
  const setEndTimeForSpaces = useSetRecoilState(endTimeStateForSpaces);

  return (startTime: moment.Moment) => {
    const calculatedEndTime = calculateMinimumEndTime(startTime);
    const spaceEndTime = momentToIsoString(calculatedEndTime);
    setEndTimeForSpaces(spaceEndTime);
  };
};

export const useSetEndTimeForSpaces = () => {
  const setEndTimeForSpaces = useSetRecoilState(endTimeStateForSpaces);

  return (endTime: moment.Moment) => {
    const spaceEndTime = momentToIsoString(endTime);
    setEndTimeForSpaces(spaceEndTime);
  };
};

export const useSetEndTimeFromStartTimeForDesks = () => {
  const timezone = useRecoilValue(locationTimezone);
  const setEndTimeForDesks = useSetRecoilState(endTimeStateForDesks);
  const { calculateEndTimeForDesks } = useEndTime();

  return (startTime: moment.Moment) => {
    const localDateTime = momentToLocationDateTime(startTime, timezone);
    const calculatedEndTime = calculateEndTimeForDesks(localDateTime);
    const deskEndTime = momentToIsoString(calculatedEndTime);
    setEndTimeForDesks(deskEndTime);
  };
};

export const useSetEndTimeForDesks = () => {
  const setEndTimeForDesks = useSetRecoilState(endTimeStateForDesks);

  return (endTime: moment.Moment) => {
    const deskEndTime = momentToIsoString(endTime);
    setEndTimeForDesks(deskEndTime);
  };
};

/**
 * Returns date + time start times combining both date + time picker.
 * @returns {LocationDateTimeMoment[] | null} List of date + times selected from date & time pickers. Null if no start time selected
 */
export const useGetStartTimesForSelectedDates = ():
  | LocationDateTimeMoment[]
  | null => {
  const selectedDates = useRecoilValue(selectedDatesState);
  const startTime = useRecoilValue(startTimeState);
  const timezone = useRecoilValue(locationTimezone);

  return useMemo(() => {
    const result = startTime
      ? selectedDates
          .map((x) => createLocalDateTime(x, startTime, timezone))
          .sort(sortDatesAscending)
      : null;
    return result;
  }, [selectedDates, startTime, timezone]);
};

/**
 * Returns date + time space end times combining both date + time picker.
 * @returns {LocationDateTimeMoment[] | null} List of date + times selected from date & time pickers. Null if no end time selected
 */
export const useGetSpaceEndTimesForSelectedDates = ():
  | LocationDateTimeMoment[]
  | null => {
  const selectedDates = useRecoilValue(selectedDatesState);
  const endTimeForSpaces = useRecoilValue(endTimeStateForSpaces);
  const timezone = useRecoilValue(locationTimezone);

  return useMemo(
    () =>
      endTimeForSpaces
        ? selectedDates
            .map((x) => createLocalDateTime(x, endTimeForSpaces, timezone))
            .sort(sortDatesAscending)
        : null,
    [selectedDates, endTimeForSpaces, timezone]
  );
};

/**
 * Returns date + time desk end times combining both date + end time picker.
 * @returns {LocationDateTimeMoment[] | null} List of date + times selected from date & time pickers. Null if no end time selected
 */
export const useGetDeskEndTimesForSelectedDates = ():
  | LocationDateTimeMoment[]
  | null => {
  const selectedDates = useRecoilValue(selectedDatesState);
  const endTimeForDesks = useRecoilValue(endTimeStateForDesks);
  const timezone = useRecoilValue(locationTimezone);

  return useMemo(() => {
    const result = endTimeForDesks
      ? selectedDates
          .map((x) => createLocalDateTime(x, endTimeForDesks, timezone))
          .sort(sortDatesAscending)
      : null;
    return result;
  }, [selectedDates, endTimeForDesks, timezone]);
};

export const useDatePickerType = () => {
  return useRecoilValue(datePickerType);
};

export const useSetDatePickerType = () => {
  return useSetRecoilState(datePickerType);
};

/**
 * Returns time ONLY from the start time picker.
 * @returns {LocationDateTimeMoment} The time only component of time selection refer to useGetStartTimesForSelectedDates[0] for datetime of first selected day
 */
export const useGetStartLocationTime = (): LocationDateTimeMoment | null => {
  const timezone = useRecoilValue(locationTimezone);

  const startTime = useRecoilValue(startTimeState);
  return useMemo(
    () => startTime && isoStringtoLocalTime(startTime, timezone),
    [startTime, timezone]
  );
};

/**
 * Returns time ONLY from the end time space picker.
 * @returns {LocationDateTimeMoment} The time only component of time selection refer to useGetSpaceEndTimesForSelectedDates for datetime of selected days
 */
export const useGetSpaceEndLocationTime = (): LocationDateTimeMoment | null => {
  const timezone = useRecoilValue(locationTimezone);

  const spaceEndTime = useRecoilValue(endTimeStateForSpaces);
  return useMemo(
    () => spaceEndTime && isoStringtoLocalTime(spaceEndTime, timezone),
    [spaceEndTime, timezone]
  );
};

/**
 * Returns time ONLY from the end time desk picker.
 * @returns {LocationDateTimeMoment} The time only component of time selection refer useGetDeskEndTimesForSelectedDates for datetime of selected days
 */
export const useGetDeskEndLocationTime = (): LocationDateTimeMoment | null => {
  const timezone = useRecoilValue(locationTimezone);
  const deskEndTime = useRecoilValue(endTimeStateForDesks);
  return useMemo(
    () => deskEndTime && isoStringtoLocalTime(deskEndTime, timezone),
    [deskEndTime, timezone]
  );
};

export const useStartTimeIsNow = (): boolean => {
  const startTime = useRecoilValue(startTimeState);
  const timezone = useRecoilValue(locationTimezone);

  return useMemo(() => {
    if (!startTime) return false;
    const startTimeMoment = moment.tz(startTime, timezone);
    const now = moment.tz(timezone);
    return startTimeMoment.isSame(now, 'minute');
  }, [startTime, timezone]);
};
