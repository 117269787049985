import { onError } from '@apollo/client/link/error';
import { datadogRum } from '@datadog/browser-rum';

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    const captureException = (message: string) => {
      datadogRum.addError(new Error(message ?? 'Unknown error'), {
        operation,
      });
    };

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        captureException(
          `[GraphQL error]: Message: ${message} - {locations: ${JSON.stringify(
            locations
          )}, path: ${path}}`
        );
      });
    }
    if (networkError) {
      captureException(`[Network error]: ${networkError}`);
    }
  }
);
