import { LocationDateTimeMoment } from './dateUtils';

export const generateTimes = (
  min: LocationDateTimeMoment,
  max: LocationDateTimeMoment,
  step: number,
  maxSelectable: boolean
): LocationDateTimeMoment[] => {
  // Return empty array if props are not defined or if min is after max
  if (!min || !max || !step || min.isAfter(max, 'minutes')) {
    return [];
  }

  const times = [];

  // This value is mutated and used to increment the while loop.
  const loopTime = min.clone().startOf('minute');

  // If the min time isn't divisible by the step, add it to the array and round.
  if (loopTime.minutes() % step !== 0) {
    // The number of minutes between the minimum and the next step value.
    const remainder = step - (loopTime.minute() % step);

    times.push(min.clone());
    loopTime.add(remainder, 'minutes');
  }

  // Respect maxSelectable prop with isBefore check
  while (loopTime.clone().isBefore(max)) {
    times.push(loopTime.clone());
    loopTime.add(step, 'minutes');
  }

  // Add max value if maxSelectable is true
  if (maxSelectable && !times.some((time) => time.isSame(max, 'minute'))) {
    times.push(max);
  }

  return times;
};
