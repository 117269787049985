import React, { useCallback, useMemo } from 'react';
import { Divider, Skeleton } from '@robinpowered/ui-kit';

import { SidebarContent, SidebarHeader } from 'components/global/sidebar';
import { useTranslation } from 'react-i18next';
import { useSetRightSidebarView } from 'atoms/sidebar/hooks';
import { useSpaceList } from '../graphql/useSpaceList';
import styled from '@emotion/styled';

import { SpaceListItem } from './SpaceListItem';
import { SpaceListSearch } from './SpaceListSearch';
import {
  useFilteredSpaceIds,
  useSetCurrentFilter,
} from 'atoms/mapInteractions';

export const SpaceList = () => {
  const { t } = useTranslation('spaceResourceDetails');
  const setRightSidebarView = useSetRightSidebarView();
  const mapFilteredSpaceIds = useFilteredSpaceIds();
  const setCurrentFilter = useSetCurrentFilter();
  const { loading, spaceList } = useSpaceList();

  const searchedSpaceList = useMemo(
    () => spaceList.filter((space) => mapFilteredSpaceIds.includes(space.id)),
    [spaceList, mapFilteredSpaceIds]
  );

  const onClose = useCallback(() => {
    setCurrentFilter(null);
    setRightSidebarView(undefined);
  }, [setCurrentFilter, setRightSidebarView]);

  return (
    <SpaceListContainer>
      <SidebarHeader header={t('space_list.header')} onClose={onClose} />
      <SpaceListContent>
        <SpaceListSearch spaceList={searchedSpaceList}>
          {(searchResults) =>
            loading ? (
              <div data-testid="skeleton">
                <Skeleton active />
              </div>
            ) : (
              <SpaceListWrapper>
                {searchResults.map((x, index) => {
                  return (
                    <div key={x.id}>
                      <SpaceListItem space={x} />
                      {index < searchedSpaceList.length - 1 && (
                        <Divider style={{ margin: '12px 0' }} />
                      )}
                    </div>
                  );
                })}
              </SpaceListWrapper>
            )
          }
        </SpaceListSearch>
      </SpaceListContent>
    </SpaceListContainer>
  );
};
const SpaceListContent = styled(SidebarContent)`
  display: flex;
  flex-direction: column;
`;

const SpaceListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpaceListContainer = styled.div`
  margin-bottom: 8px;
  top: 59px;
  border: 1px solid var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
  border-radius: 4px;
  background: var(--Colors-Neutral-colorWhite, #fff);
  height: 100%;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
