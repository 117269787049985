import { useSelectedLevel } from 'atoms/resource';
import { useApolloContext } from 'contexts';
import {
  GetSpacesByLevelIdQuery,
  GetSpacesByLevelIdQueryVariables,
  SnapTo,
} from 'generated';
import gql from 'graphql-tag';
import { useQueryCachedLoad } from 'hooks';
import { useSpaceAvailabilityParameters } from 'hooks/useSpaceAvailabilityParameters';
import moment from 'moment';
import { useIsUnmodifiedSpaceEndTime } from '../space-availability-pills/hooks/useIsUnmodifiedSpaceEndTime';

export type SpaceListDetail = NonNullable<
  GetSpacesByLevelIdQuery['getLevelById']
>['spaces'][0];

const SPACE_LIST_QUERY = gql`
  query getSpacesByLevelId(
    $levelId: ID!
    $startTime: Date
    $numberOfWindows: Int
    $meetingDurationInMinutes: Int
    $snapTo: SnapTo
  ) {
    getLevelById(id: $levelId) {
      id
      spaces {
        id
        description
        name
        image
        type
        capacity
        isAccessible
        locationId
        scheduleConfiguration
        amenities {
          id
          name
          amenityId
        }
        meetingRoomWindowedAvailability(
          startTime: $startTime
          numberOfWindows: $numberOfWindows
          meetingDurationInMinutes: $meetingDurationInMinutes
          firstWindowSplit: $snapTo
        ) {
          start
          end
          status
        }
      }
    }
  }
`;

export const useSpaceList = () => {
  const { tenantId } = useApolloContext();
  const [level] = useSelectedLevel();
  const { startTime, durationInMinutes } = useSpaceAvailabilityParameters();
  const isUnmodifiedSpaceEndTime = useIsUnmodifiedSpaceEndTime();
  const request = {
    levelId: level || '',
    startTime: moment(startTime).toISOString(),
    meetingDurationInMinutes: isUnmodifiedSpaceEndTime ? 30 : durationInMinutes,
    numberOfWindows: isUnmodifiedSpaceEndTime ? 2 : 1,
    snapTo: isUnmodifiedSpaceEndTime ? SnapTo.Thirty : SnapTo.Zero,
  };
  const { loading, data } = useQueryCachedLoad<
    GetSpacesByLevelIdQuery,
    GetSpacesByLevelIdQueryVariables
  >(
    SPACE_LIST_QUERY,
    {
      skip: !tenantId || !level || !startTime,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: request,
    },
    ['startTime', 'meetingDurationInMinutes']
  );

  return { loading, spaceList: data?.getLevelById?.spaces ?? [] };
};
