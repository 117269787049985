var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState } from 'react';
export function useHttpState() {
    var _a = __read(useState({
        data: null,
        loading: true,
        error: null,
    }), 2), state = _a[0], setState = _a[1];
    var setLoading = function () {
        return setState(function (currState) { return (__assign(__assign({}, currState), { loading: true })); });
    };
    var setData = function (data) {
        return setState({
            data: data,
            loading: false,
            error: null,
        });
    };
    var setError = function (error) {
        return setState(function (currState) { return (__assign(__assign({}, currState), { error: error, loading: false })); });
    };
    return __assign(__assign({}, state), { setLoading: setLoading, setData: setData, setError: setError });
}
