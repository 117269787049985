import { SidebarContent, SidebarHeader } from 'components/global/sidebar';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRightSidebarView } from 'atoms/sidebar/hooks';
import { DeskList } from './desk-list';
import { useSetCurrentFilter } from 'atoms/mapInteractions';
import { useBackDestinationContext } from './edit-desk-reservation/contexts';
import { useSetCurrentlySelectedResource } from 'atoms/resource/hooks';

export const DeskListView: FC = () => {
  const { t } = useTranslation('resourceDetails');

  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const setRightSidebarView = useSetRightSidebarView();
  const setCurrentFilters = useSetCurrentFilter();
  const { setBackDestination } = useBackDestinationContext();

  useEffect(() => {
    setBackDestination('desk-list');
  }, [setBackDestination]);

  const onClose = useCallback(() => {
    setRightSidebarView(undefined);
    setCurrentFilters(null);
    setCurrentlySelectedResource(null);
  }, [setRightSidebarView, setCurrentFilters, setCurrentlySelectedResource]);

  return (
    <>
      <SidebarHeader header={t('desk_list.header')} onClose={onClose} />

      <SidebarContent>
        <DeskList />
      </SidebarContent>
    </>
  );
};
