import { config } from './config';

type ConfigType = {
  local: string[];
  development: string[];
  staging: string[];
  production: string[];
};

const datadogOriginsConfig: ConfigType = {
  local: [], // Disable tracing origins on local
  development: [], // Disable tracing origins on development
  staging: [
    'https://federation-gateway.staging.robinpowered.com',
    'https://atlas.staging.services.robinpowered.com',
  ],
  production: [
    'https://federation-gateway.robinpowered.com',
    'https://atlas.services.robinpowered.com',
  ],
};

const datadogOrigins =
  datadogOriginsConfig[config.robinEnv as keyof ConfigType];

export { datadogOrigins };
