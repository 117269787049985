import { Button, message } from '@robinpowered/ui-kit';
import {
  useGetDeskEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useSetDatePickerType,
  useSetSelectedDates,
  useTimezone,
} from 'atoms/resource';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useReserveDeskForMe } from './hooks';
import { BookingPolicyType, DeskReservationVisibility } from 'generated';
import { createRecurrenceFromDates } from 'utils';

type BookButtonProps = {
  deskId: string | undefined;
  type: BookingPolicyType | undefined;
  isDisabled: boolean;
  isPrivate: boolean;
  isDuringExclusion: boolean;
  deskDataLoading: boolean;
};

export const BookButton = ({
  deskId,
  type,
  isDisabled,
  isPrivate,
  isDuringExclusion,
  deskDataLoading,
}: BookButtonProps) => {
  const { t } = useTranslation('resourceDetails');
  const { timezone } = useTimezone();

  const startTimeMoments = useGetStartTimesForSelectedDates();
  const endTimeMoments = useGetDeskEndTimesForSelectedDates();

  const setDatePickerType = useSetDatePickerType();
  const setSelectedDates = useSetSelectedDates();

  const { reserveDesk, isReserving } = useReserveDeskForMe();

  const handleBooking = useCallback(async () => {
    if (!startTimeMoments || !endTimeMoments || !deskId || !type) {
      void message.open({
        type: 'error',
        content: t('desk_booking_controls.failure'),
      });

      return;
    }

    const hotBooking = type === BookingPolicyType.Hot;
    const bookingType = hotBooking
      ? BookingPolicyType.Hot
      : isDuringExclusion
      ? BookingPolicyType.ReverseHotel
      : BookingPolicyType.Hoteled;

    if (startTimeMoments) {
      setSelectedDates([startTimeMoments?.[0]]);
      setDatePickerType('single');
    }

    void reserveDesk({
      variables: {
        recurrence:
          startTimeMoments && startTimeMoments?.length > 1
            ? createRecurrenceFromDates(startTimeMoments)
            : undefined,
        seatId: Number(deskId),
        type: bookingType,
        start: {
          dateTime: startTimeMoments[0].clone().utc().format(),
          timeZone: timezone,
        },
        end: {
          dateTime: endTimeMoments[0].clone().utc().format(),
          timeZone: timezone,
        },
        visibility: isPrivate
          ? DeskReservationVisibility.JustMe
          : DeskReservationVisibility.Everyone,
      },
    });
  }, [
    deskId,
    endTimeMoments,
    isDuringExclusion,
    isPrivate,
    reserveDesk,
    setSelectedDates,
    setDatePickerType,
    startTimeMoments,
    t,
    timezone,
    type,
  ]);

  const buttonText = useMemo(() => {
    return isReserving
      ? t('desk_booking_controls.booking_action')
      : t('desk_booking_controls.book_action');
  }, [isReserving, t]);

  return (
    <Button
      type="primary"
      onClick={handleBooking}
      disabled={deskDataLoading || isDisabled}
      loading={isReserving}
      data-testid="book-button"
    >
      {buttonText}
    </Button>
  );
};
