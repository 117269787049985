import { CallbackInterface } from 'recoil';
import { SidebarView, leftSidebarOpenState, rightSidebarView } from './atoms';

export const handleSetRightSidebarView =
  ({ set }: CallbackInterface) =>
  (view: SidebarView) => {
    set(rightSidebarView, view);

    // Always close the left sidebar when opening the right sidebar
    if (view) {
      set(leftSidebarOpenState, false);
    }
  };

export const handleSetLeftSidebarOpen =
  ({ set }: CallbackInterface) =>
  (isOpen: boolean) => {
    set(leftSidebarOpenState, isOpen);

    if (isOpen) {
      // Always close the right sidebar when opening the left sidebar
      set(rightSidebarView, undefined);
    }
  };
