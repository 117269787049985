import { useTimeFilters } from 'hooks/useTimeFilters';
import { TimeDropdown } from './TimeDropdown';
import { useMapControlsContext } from 'contexts';
import {
  useGetDeskEndLocationTime,
  useGetSpaceEndLocationTime,
  useSetEndTimeForDesks,
  useSetEndTimeForSpaces,
} from 'atoms/resource';
import { useCallback, useMemo } from 'react';
import { LocationDateTimeMoment } from 'utils';

interface TimeDropdownProps {
  type: 'seats' | 'spaces';
  status?: '' | 'warning' | 'error' | undefined;
}

// drop down that keeps track of clock for calculating end time
export const EndTimeDropdown = ({
  type,
  status,
}: TimeDropdownProps): JSX.Element => {
  const { selectedLocationId } = useMapControlsContext();
  const { minEndTime, maxEndTime } = useTimeFilters(selectedLocationId);
  const endTimeForSpaces = useGetSpaceEndLocationTime();
  const endTimeForDesks = useGetDeskEndLocationTime();
  const setEndTimeForDesks = useSetEndTimeForDesks();
  const setEndTimeForSpaces = useSetEndTimeForSpaces();
  const onEndTimeSelect = useCallback(
    (selectedTime: LocationDateTimeMoment) => {
      switch (type) {
        case 'seats':
          setEndTimeForDesks(selectedTime);
          return;
        case 'spaces':
          setEndTimeForSpaces(selectedTime);
          return;

        case undefined:
          setEndTimeForSpaces(selectedTime);
          setEndTimeForDesks(selectedTime);
          return;
      }
    },
    [type, setEndTimeForDesks, setEndTimeForSpaces]
  );

  const selectedTimeBasedOnFilter = useMemo(
    () => (type === 'seats' ? endTimeForDesks : endTimeForSpaces),
    [type, endTimeForDesks, endTimeForSpaces]
  );
  return (
    <TimeDropdown
      minimum={minEndTime}
      maximum={maxEndTime}
      selectedTime={selectedTimeBasedOnFilter}
      onTimeSelect={onEndTimeSelect}
      status={status}
    ></TimeDropdown>
  );
};
