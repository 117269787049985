import { useTranslation } from 'react-i18next';
import { SubtractSolid } from '@robinpowered/icons';
import styled from '@emotion/styled';
import { Alert, Typography } from '@robinpowered/ui-kit';

const { Text } = Typography;

type OfficeClosedBannerProps = {
  openOfficeHoursModal: () => void;
};

export const OfficeClosedBanner = ({
  openOfficeHoursModal,
}: OfficeClosedBannerProps) => {
  const { t } = useTranslation('common');

  const bannerContent = (
    <ContentWrapper>
      <SubtractSolid size={16} />
      <BannerText>
        {t('office_hours.location_closed')}
        <Link onClick={openOfficeHoursModal}>
          {t('office_hours.view_office_hours')}
        </Link>
      </BannerText>
    </ContentWrapper>
  );

  return (
    <AlertBanner
      message={bannerContent}
      banner={true}
      showIcon={false}
      data-testid="office-closed-banner"
    />
  );
};

const AlertBanner = styled(Alert)`
  align-items: center;
  align-self: stretch;
  background: var(--Colors-Neutral-Bg-colorBgSpotlight, rgba(0, 0, 0, 0.85));
  display: flex;
  gap: var(--Components-Alert-Global-marginXS, 8px);
  justify-content: center;
  padding: var(--Space-Padding-paddingContentVerticalSM, 8px)
    var(--Space-Padding-paddingSM, 12px);

  /* Override Ant Design's default alert content alignment */
  .ant-alert-message {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Colors-Neutral-Text-colorTextLightSolid, #fff);

  /* Style the icon to be white */
  path {
    fill: var(--Colors-Neutral-Text-colorTextLightSolid, #fff);
  }
`;

const BannerText = styled(Text)`
  color: var(--Colors-Neutral-Text-colorTextLightSolid, #fff);
`;

const Link = styled(BannerText)`
  cursor: pointer;
  margin-left: 4px;
  text-decoration-line: underline;
`;
