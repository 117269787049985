import {
  InteractiveLayer,
  SelectableResources,
  SelectedResource,
} from '../atoms/resource/types';
import { checkIsExhaustive } from './isExhaustive';
import { OnMapClickEvent } from '@robinpowered/perseus';

const interactiveLayers: InteractiveLayer[] = [
  'seats',
  'spaces',
  'space_labels',
];

const isInteractiveLayer = (layer: string): layer is InteractiveLayer => {
  return interactiveLayers.includes(layer as InteractiveLayer);
};

const mapInteractiveLayerToSelectableResource = (
  sourceType: InteractiveLayer
): SelectableResources => {
  switch (sourceType) {
    case 'seats':
      return 'seats';

    case 'spaces':
    case 'space_labels':
      return 'spaces';

    default:
      checkIsExhaustive(sourceType);
      throw new Error(
        `Unknown interactive layer when mapping to resource: ${sourceType}`
      );
  }
};

// A single click on the map can return multiple layers
// For example clicking on an avatar over a desk would return both
// We want to filter the list for any interactive layers that we care about
// and then return the first one, assuming that it was the top layer displayed on the map
export const getClickedResource = (
  event: OnMapClickEvent
): SelectedResource | undefined => {
  const { sourceTypes, targetDetails } = event;

  // Filter only for layers that we care about
  const interactiveLayers = sourceTypes.filter(isInteractiveLayer);

  // Return undefined if there are no layers that we care about
  if (interactiveLayers.length === 0) {
    return;
  }

  // We are assuming that the first item in the list was the 'top' most rendered layer
  const topLayer = interactiveLayers[0];

  // Find the target details for the top layer
  const target = targetDetails.find((td) => td.type === topLayer);

  // If for some reason the layer has no target details, return undefined
  if (!target) {
    // TODO: Log error
    return;
  }

  const resourceType = mapInteractiveLayerToSelectableResource(topLayer);

  return {
    type: resourceType,
    id: target.targetId.toString(),
  };
};
