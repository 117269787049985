import React, { createContext, ReactNode } from 'react';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useParams } from 'react-router-dom';
import { useAuthContext } from './AuthContext';
type LocalStorageType = keyof LocalStorageContextProps;

interface LocalStorageContextProps {
  recentlySelectedLocations: [string[], (value: string[]) => void];
  recentlySelectedLevel: [string | undefined, (value: string) => void];
  officeV2Enabled: [boolean, (value: boolean) => void];
  enabledLegacyOfficeCardMinimized: [boolean, (value: boolean) => void];
}

type LocalStorageByOrg = {
  recentlySelectedLocationIds: string[];
  recentlySelectedLevelId: string | undefined;
};
const emptyStorage = {
  recentlySelectedLocationIds: [],
  recentlySelectedLevelId: '',
};

const LocalStorageContext = createContext<LocalStorageContextProps | undefined>(
  undefined
);

const defaultContext: LocalStorageContextProps = {
  recentlySelectedLocations: [[], (value: string[]) => null],
  recentlySelectedLevel: [undefined, (value: string) => null],
  officeV2Enabled: [true, (value: boolean) => null],
  enabledLegacyOfficeCardMinimized: [false, (value: boolean) => null],
};

// skip local storage if no orgSlug or user found as it will create incorrect keys
export const LocalStorageProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const { currentUser } = useAuthContext();
  const storageKeyPrefix = `office-${currentUser?.id}`;
  const { orgSlug } = useParams();
  if (currentUser && orgSlug) {
    return (
      <ValidLocalStorageProvider
        orgSlug={orgSlug}
        storageKeyPrefix={storageKeyPrefix}
      >
        {children}
      </ValidLocalStorageProvider>
    );
  }
  return <EmptyLocalStorageProvider>{children}</EmptyLocalStorageProvider>;
};

const EmptyLocalStorageProvider = ({ children }: { children?: ReactNode }) => {
  return (
    <LocalStorageContext.Provider value={defaultContext}>
      {children}
    </LocalStorageContext.Provider>
  );
};

const ValidLocalStorageProvider = ({
  storageKeyPrefix,
  orgSlug,
  children,
}: {
  storageKeyPrefix: string;
  orgSlug: string;
  children?: ReactNode;
}) => {
  const [localStorageByOrg, setLocalStorageByOrg] = useLocalStorage<
    LocalStorageByOrg | undefined
  >(
    `${storageKeyPrefix}/${orgSlug}-local-storage`,
    emptyStorage as LocalStorageByOrg
  );

  //always default to true if not exist
  const [officeV2Enabled, setOfficeV2Enabled] = useLocalStorage<boolean>(
    `${storageKeyPrefix}/enableOfficeV2`,
    true
  );
  const [enableLegacyOfficeCardMinimized, setEnableLegacyOfficeCardMinimized] =
    useLocalStorage<boolean>(
      `${storageKeyPrefix}/enableLegacyOfficeCardMinimized`,
      false
    );
  const setRecentlySelectedLocationIds = (value: string[]) => {
    if (localStorageByOrg) {
      const updatedStore = {
        ...localStorageByOrg,
        ...{
          recentlySelectedLocationIds: value,
        },
      };
      setLocalStorageByOrg(updatedStore);
    }
  };
  const setRecentlySelectedLevelId = (value: string) => {
    if (localStorageByOrg) {
      const updatedStore = {
        ...localStorageByOrg,
        ...{
          recentlySelectedLevelId: value,
        },
      };
      setLocalStorageByOrg(updatedStore);
    }
  };
  return (
    <LocalStorageContext.Provider
      value={{
        recentlySelectedLocations: [
          localStorageByOrg?.recentlySelectedLocationIds || [],
          setRecentlySelectedLocationIds,
        ],
        recentlySelectedLevel: [
          localStorageByOrg?.recentlySelectedLevelId,
          setRecentlySelectedLevelId,
        ],
        officeV2Enabled: [officeV2Enabled ?? true, setOfficeV2Enabled],
        enabledLegacyOfficeCardMinimized: [
          enableLegacyOfficeCardMinimized ?? false,
          setEnableLegacyOfficeCardMinimized,
        ],
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorageContext = (): LocalStorageContextProps => {
  const context = React.useContext(LocalStorageContext);
  if (!context) {
    throw new Error('No localstorage context found');
  }
  return context;
};

export const useLocalStorageContextByKey = <K extends LocalStorageType>(
  key: K
): LocalStorageContextProps[K] => {
  const context = React.useContext(LocalStorageContext);
  if (!context) {
    throw new Error('No localstorage context found');
  }
  return context[key];
};
