import { gql, useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  GetOrSuggestMyDefaultLocationOrLevelQuery,
  GetOrSuggestMyDefaultLocationOrLevelQueryVariables,
} from 'generated';

export type DefaultLocation = NonNullable<
  GetOrSuggestMyDefaultLocationOrLevelQuery['getOrSuggestMyDefaultLocationOrLevel']
>;

export const GET_DEFAULT_LOCATION_QUERY = gql`
  query GetOrSuggestMyDefaultLocationOrLevel {
    getOrSuggestMyDefaultLocationOrLevel {
      levelId
      locationId
    }
  }
`;

export const useDefaultLocation = () => {
  const { tenantId } = useApolloContext();

  return useQuery<
    GetOrSuggestMyDefaultLocationOrLevelQuery,
    GetOrSuggestMyDefaultLocationOrLevelQueryVariables
  >(GET_DEFAULT_LOCATION_QUERY, {
    skip: !tenantId,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });
};
