import { FC, useCallback, useMemo } from 'react';
import { DeskListItemBase } from './DeskListItemBase';
import { DeskIcon } from './icons/DeskIcon';
import { useTranslation } from 'react-i18next';
import { useMapMode } from 'atoms/mapInteractions';
import { checkIsExhaustive } from 'utils';
import { useSetEditDeskPotentialDeskId } from 'atoms/editDesk';
import { useViewDeskDetails } from './hooks';
import { useSetDeskSidebarView } from 'atoms/sidebar/hooks';

export const DeskListItemAvailable: FC<{
  deskId: string;
  deskName: string;
}> = ({ deskName, deskId }) => {
  const { t } = useTranslation('resourceDetails');

  const mapMode = useMapMode();
  const setPotentialDeskId = useSetEditDeskPotentialDeskId();
  const setView = useSetDeskSidebarView();
  const viewDetails = useViewDeskDetails(deskId);

  const selectForEdit = useCallback(() => {
    setPotentialDeskId(deskId);
    setView('potential-desk');
  }, [deskId, setPotentialDeskId, setView]);

  const onClick = useMemo(() => {
    switch (mapMode) {
      case 'browse':
        return viewDetails;

      case 'edit-desk-reservation':
        return selectForEdit;

      default:
        checkIsExhaustive(mapMode);
    }
  }, [mapMode, viewDetails, selectForEdit]);

  return (
    <DeskListItemBase
      title={deskName}
      subtitle={t('desk_list.available')}
      icon={<DeskIcon />}
      onClick={onClick}
    />
  );
};
