export type SelectOption = {
  label: string;
  value: string | number;
};

export type CapacityGroup = {
  min: number | null;
  max: number | null;
};

export const isCapacityGroup = (object: unknown): object is CapacityGroup => {
  return (
    typeof object === 'object' &&
    object !== null &&
    'min' in object &&
    (typeof object.min === 'number' || object.min === null) &&
    'max' in object &&
    (typeof object.max === 'number' || object.max === null)
  );
};
