import styled from '@emotion/styled';
import { SpaceFilterContextProvider } from '../../context/SpaceFilterContext';
import { AmenitiesFilter } from './AmenitiesFilter';
import { TypeFilter } from './TypeFilter';
import { CapacityFilter } from './CapacityFilter';

const SpaceSubFiltersWithContext = () => {
  return (
    <SpaceFilterContextProvider>
      <Container>
        <TypeFilter />
        <AmenitiesFilter />
        <CapacityFilter />
      </Container>
    </SpaceFilterContextProvider>
  );
};

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
`;

export { SpaceSubFiltersWithContext as SpaceSubFilters };
