import { Button, Typography } from '@robinpowered/ui-kit';
import { CloseOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

export const SidebarHeader = ({
  header,
  subHeader,
  onClose,
  prefix,
}: {
  header: string;
  subHeader?: string;
  onClose?: () => void;
  prefix?: React.ReactNode;
}): JSX.Element => {
  return (
    <Container>
      <PrefixWrapper>
        {prefix && prefix}

        <HeaderWrapper>
          <Header level={4}>{header}</Header>
          {subHeader && <SubHeader level={5}>{subHeader}</SubHeader>}
        </HeaderWrapper>
      </PrefixWrapper>

      {onClose && (
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={onClose}
          data-testid="sidebar-header-close-button"
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--Space-Padding-padding, 16px);
  border-bottom: var(--Components-Drawer-Global-lineWidth, 1px) solid
    var(--Components-Drawer-Global-colorSplit, rgba(0, 0, 0, 0.06));
  background: var(--Colors-Neutral-colorWhite, #fff);
`;

const PrefixWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled(Typography.Title)`
  &.ant-typography {
    margin-bottom: 0;
  }
`;

const SubHeader = styled(Typography.Title)``;
