import { useMemo, useEffect, useCallback } from 'react';
import {
  Button,
  Dropdown,
  MenuProps,
  Select,
  Typography,
} from '@robinpowered/ui-kit';
import { useMapControlsContext } from 'contexts';
import { LocationLevel, useLocationLevels } from 'hooks/useLocationLevels';
import { DefaultOptionType } from 'antd/es/select';
import { sortObjectAsc } from 'utils';

type LevelOption = DefaultOptionType & {
  value: string;
  id: string;
};

const renderLabel = (level: LocationLevel, isDropdown: boolean) => {
  return (
    <Typography.Text
      style={{
        width: isDropdown ? 130 : 160,
        display: 'block',
        textAlign: 'left',
      }}
      ellipsis={{ tooltip: level.name }}
    >
      <div>{level.name}</div>
    </Typography.Text>
  );
};

export function LevelSelector(): JSX.Element | null {
  const { selectedLocationId, selectedLevelId, setSelectedLevelId } =
    useMapControlsContext();
  const locationData = useLocationLevels(selectedLocationId);

  const levels = useMemo(
    () => locationData.data?.getLocationById?.levels || [],
    [locationData]
  );

  const items: MenuProps['items'] = useMemo(() => {
    if (locationData.loading) return [];
    return levels
      .map((level) => ({
        label: renderLabel(level, true),
        key: level.id,
        name: level.name,
      }))
      .sort(sortObjectAsc('name'));
  }, [locationData.loading, levels]);

  const menuProps = {
    items,
    onClick: (e: { key: string }) => {
      const levelId = e.key;
      setSelectedLevelId(levelId);
    },
  };

  const levelDisplayLine = useMemo(() => {
    if (locationData.loading || !levels.length) return 'No floor set up';
    const selectedLevel = levels.find((l) => l.id === selectedLevelId);

    return selectedLevel?.name || null;
  }, [locationData.loading, levels, selectedLevelId]);

  useEffect(() => {
    if (selectedLocationId && !locationData.loading) {
      const validLevelId = levels.some((level) => level.id === selectedLevelId)
        ? selectedLevelId
        : levels[0]?.id;

      if (validLevelId && validLevelId !== selectedLevelId) {
        setSelectedLevelId(validLevelId);
      }
    }
  }, [
    selectedLocationId,
    locationData.loading,
    levels,
    selectedLevelId,
    setSelectedLevelId,
  ]);

  useEffect(() => {
    if (selectedLocationId) {
      void locationData.refetch();
    }
  }, [selectedLocationId, locationData]);

  const onSelect = useCallback(
    (_, option: LevelOption) => {
      const selectedOption = levels.find((level) => level.id === option.id);
      if (selectedOption) {
        setSelectedLevelId(selectedOption.id);
      }
    },
    [levels, setSelectedLevelId]
  );

  const renderItem = useCallback((level: LocationLevel): LevelOption => {
    return {
      value: level.name,
      id: level.id,
      label: renderLabel(level, false),
    };
  }, []);

  const getOptions = useCallback(
    (levels: LocationLevel[]): LevelOption[] => {
      return levels.map((location) => renderItem(location));
    },
    [renderItem]
  );

  if (levels.length > 5) {
    const options = getOptions(levels);

    return (
      <Select
        disabled={levels.length === 0}
        style={{ width: 160 }}
        showSearch
        value={levelDisplayLine}
        placeholder={'Select a level'}
        onSelect={onSelect}
        options={options}
        filterSort={sortObjectAsc('value')}
      ></Select>
    );
  }

  return (
    <Dropdown disabled={levels.length === 0} menu={menuProps}>
      <Button>
        <Typography.Text
          disabled={levels.length === 0}
          style={{
            width: 130,
            textAlign: 'left',
          }}
          ellipsis={{ tooltip: levelDisplayLine }}
        >
          <div>{levelDisplayLine}</div>
        </Typography.Text>
      </Button>
    </Dropdown>
  );
}
