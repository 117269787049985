import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { Button, Typography } from '@robinpowered/ui-kit';
import { MinorPinSolid } from '@robinpowered/icons';
import moment from 'moment';
import styled from '@emotion/styled';
import { SHORT_TIME_FORMAT, SLASH_DATE_FORMAT, Z_INDEX } from '../../constants';
import {
  TimeFrame,
  UseLocationWorkingHoursQuery,
  CustomWorkingHours,
} from 'generated';
import { IsoString } from 'utils';

const { Text, Title } = Typography;

type OfficeHoursModalProps = {
  isOpen: boolean;
  location: UseLocationWorkingHoursQuery['getLocationById'];
  onClose: () => void;
  selectedStartTime: IsoString | null;
};

export function OfficeHoursModal({
  isOpen,
  location,
  onClose,
  selectedStartTime,
}: OfficeHoursModalProps) {
  const { t } = useTranslation('common');

  function getFormattedHoursText(timeFrame: TimeFrame): string {
    const hoursStart = moment(timeFrame.start, SHORT_TIME_FORMAT).format(
      SHORT_TIME_FORMAT
    );
    const hoursEnd = moment(timeFrame.end, SHORT_TIME_FORMAT).format(
      SHORT_TIME_FORMAT
    );
    return `${hoursStart} - ${hoursEnd}`;
  }

  function getFormattedDayText(day: number): string {
    const startDay = moment(selectedStartTime).day();
    const selectedDate = moment(selectedStartTime);

    // Calculate days to add based on the difference between target day and start day
    let daysToAdd = 0;
    if (day >= startDay) {
      daysToAdd = day - startDay;
    } else {
      // If target day is earlier in the week than start day,
      // add days until we reach that day next week
      daysToAdd = 7 - (startDay - day);
    }

    return selectedDate
      .clone()
      .add(daysToAdd, 'days')
      .format(SLASH_DATE_FORMAT);
  }

  const OfficeHoursList = (): JSX.Element => {
    const NUMBER_OF_DAYS_TO_DISPLAY = 7;
    const startDay = moment(selectedStartTime).day();
    const workingHours = location?.workingHours ?? [];
    const customWorkingHours = location?.customWorkingHours ?? [];

    //order working hours starting at the selected date, and then for a week's worth
    const orderedWorkingHours = [
      ...workingHours.slice(startDay, NUMBER_OF_DAYS_TO_DISPLAY),
      ...workingHours.slice(0, startDay),
    ];

    const customHoursByDate = customWorkingHours.reduce(
      (acc: Record<string, TimeFrame>, custom: CustomWorkingHours) => {
        const date = moment(custom.date).format(SLASH_DATE_FORMAT);
        // If timeFrames[0] is undefined, store null to indicate Office Closure
        acc[date] = custom.timeFrames?.[0] ?? null;
        return acc;
      },
      {}
    );

    return (
      <List>
        {orderedWorkingHours?.map(({ day, timeFrames }) => {
          const dayText = getFormattedDayText(day ?? 0);

          const hasCustomHours = dayText in customHoursByDate;
          const customTimeFrame = customHoursByDate[dayText];
          const timeFrame = hasCustomHours ? customTimeFrame : timeFrames[0];

          const hoursText = timeFrame
            ? getFormattedHoursText(timeFrame)
            : t('office_hours.closed');

          return (
            <DayRow key={day}>
              <DayText size="large">{dayText}</DayText>
              <DayText size="large">{hoursText}</DayText>
            </DayRow>
          );
        })}
      </List>
    );
  };

  return (
    <StyledModal
      zIndex={Z_INDEX.OFFICE_HOURS_MODAL}
      title={<ModalTitle level={4}>{location?.name}</ModalTitle>}
      open={isOpen}
      onCancel={onClose}
      style={{ minWidth: 'fit-content' }}
      footer={[
        <Button key="close" onClick={onClose}>
          {t('office_hours.close')}
        </Button>,
      ]}
    >
      <ModalBody>
        {!!location?.address && (
          <LocationSection>
            <Title level={5}>{t('office_hours.location')}</Title>
            <AddressLine>
              <MinorPinSolid size={16} />
              <Text size="large">{location?.address}</Text>
            </AddressLine>
          </LocationSection>
        )}
        <OfficeHoursSection>
          <Title level={5}>{t('office_hours.office_hours')}</Title>
          <OfficeHoursList />
        </OfficeHoursSection>
      </ModalBody>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: var(--Components-Drawer-Global-lineWidth, 1px) solid
      var(--Colors-Neutral-Border-colorBorderSecondary, #f0f0f0);
    margin: 0 -24px;
    padding: 0 16px 16px 16px;
    width: calc(100% + 48px);
  }
  .ant-modal-footer {
    border-top: var(--Components-Drawer-Global-lineWidth, 1px) solid
      var(--Colors-Neutral-Border-colorBorderSecondary, #f0f0f0);
    margin: 0 -24px;
    padding: 20px 16px 0 16px;
    width: calc(100% + 48px);
  }
`;

const ModalTitle = styled(Title)`
  align-items: center;
  align-self: stretch;
  display: flex;
`;

const ModalBody = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: fit-content;
  padding: var(--Components-Drawer-Global-paddingLG, 24px) 0;
`;

const LocationSection = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const AddressLine = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: var(--Space-Padding-paddingXS, 8px);
  white-space: nowrap;
  width: fit-content;
`;

const OfficeHoursSection = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--Space-Margin-marginXS, 8px);
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 100%;
`;

const DayRow = styled.div`
  display: flex;
  height: 24px;
  gap: var(--Space-Margin-marginXS, 8px);
  flex: 1 0 0;
`;

const DayText = styled(Text)`
  align-self: flex-start;
  flex-basis: 50%;
`;
