import moment from 'moment';
import { RRule } from 'rrule';
import { SharedDeskSchedules } from '../graphql/useSharedDeskSchedule';
import { Desk, DeskReservationsBySeatId, Exclusion } from '../graphql';
import { DeskReservationCard } from './DeskReservationCard';
import { useGetStartTimesForSelectedDates } from 'atoms/resource';
import { LocationDateTimeMoment } from 'utils';
import { useCallback } from 'react';

interface ScheduledReservationsProps {
  deskSchedules: SharedDeskSchedules | undefined;
  reservations: DeskReservationsBySeatId | undefined;
  desk: Desk;
}

export function doesScheduleFallOnExclusionDay(
  scheduleRecurrence: string,
  times: LocationDateTimeMoment[] | null,
  exclusions: Exclusion[]
): boolean {
  if (!times || times.length === 0) {
    return false;
  }

  if (!exclusions || exclusions.length === 0) {
    return false;
  }

  const rrule = RRule.fromString(scheduleRecurrence);
  const scheduleDays = rrule.options.byweekday;

  // Adjust the days to be 0-6 instead of 1-7, explanation:
  // RRule uses ISO 8601 weekday numbers, which are 1-7 but moment.js days are 0-6
  const adjustedTimes = times.map((time) => (time.day() + 6) % 7);
  const isTimeInSchedule = adjustedTimes.some((adjustedDay) =>
    scheduleDays?.some((weekday) => weekday === adjustedDay)
  );

  if (!isTimeInSchedule) {
    return false;
  }

  return exclusions.some((exclusion) => {
    const exclusionStart = moment(exclusion.startTime);
    const exclusionEnd = moment(exclusion.endTime);
    return times.some((time) => time.isBetween(exclusionStart, exclusionEnd));
  });
}

export function ScheduledReservations({
  deskSchedules,
  reservations,
  desk,
}: ScheduledReservationsProps): JSX.Element | null {
  const startTimes = useGetStartTimesForSelectedDates();

  const exclusionCheck = useCallback(
    (
      scheduleRecurrence: string,
      times: LocationDateTimeMoment[] | null,
      exclusions: Exclusion[]
    ): boolean =>
      doesScheduleFallOnExclusionDay(scheduleRecurrence, times, exclusions),
    []
  );

  return (
    <>
      {deskSchedules?.map((sharedSchedule) =>
        sharedSchedule?.schedule?.map((schedule) => {
          const matchingReservation = reservations?.find(
            (reservation) => reservation.reservee?.user?.id === schedule.id
          );

          const isDuringExclusion = desk
            ? exclusionCheck(
                schedule.recurrence,
                startTimes,
                desk.state.exclusions
              )
            : false;

          return (
            <DeskReservationCard
              key={schedule.id}
              reservation={matchingReservation}
              schedule={schedule}
              desk={desk}
              isDuringExclusion={isDuringExclusion}
              showUserDetails={true}
              showReservationDetails={false}
            />
          );
        })
      )}
    </>
  );
}
