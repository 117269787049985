import { useDeskAmenities } from './graphql/useDeskAmenities';
import { ResourceTags } from '../ResourceTags';
import { Skeleton } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';

export function DeskAmenities({
  deskId,
}: {
  deskId: string | null | undefined;
}): JSX.Element | null {
  const { t } = useTranslation('resourceDetails');
  const { deskDetails, loading } = useDeskAmenities(deskId);

  if (loading) return <Skeleton active />;
  if (!deskDetails?.amenities || deskDetails?.amenities.length === 0)
    return null;

  return (
    <ResourceTags title={t('amenities')} resources={deskDetails.amenities} />
  );
}
