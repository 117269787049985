import {
  Button as AntButton,
  ButtonProps as AntButtonProps,
  Typography as AntTypography,
} from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { Close } from '@robinpowered/icons';

export const FilterTag = ({
  icon,
  title,
  active,
  ...tagProps
}: {
  title: string;
  icon: JSX.Element;
  active: boolean;
} & AntButtonProps) => {
  return (
    <TagButton active={active} {...tagProps}>
      <TagBody active={active} title={title} icon={icon} />
    </TagButton>
  );
};

type TagButtonProps = { active: boolean } & AntButtonProps;

const TagButton = (props: TagButtonProps) => {
  return <ButtonAsTag {...props} role="button" tabIndex={0} />;
};

const TagBody = ({
  title,
  icon,
  active,
}: {
  title: string;
  icon?: JSX.Element;
  active: boolean;
}) => {
  return (
    <BodyWrapper>
      {icon} <Text active={active}>{title}</Text>{' '}
      {active && <Close color="white" size={12} />}
    </BodyWrapper>
  );
};

const Text = styled(AntTypography.Text)<{ active: boolean }>`
  color: ${({ active }) =>
    active
      ? 'var(--Colors-Neutral-Bg-colorBgContainer, #fff)'
      : 'var(--Colors-Neutral-Bg-colorBgSpotlight, rgba(0, 0, 0, 0.85))'};
`;

const ButtonAsTag = styled(AntButton)<{ active: boolean }>`
  padding: 0;
  cursor: pointer;

  background: ${({ active }) =>
    active
      ? 'var(--Colors-Neutral-Bg-colorBgSpotlight, rgba(0, 0, 0, 0.85))'
      : 'var(--Colors-Neutral-Bg-colorBgContainer, #fff)'};

  border-radius: var(--Border-Radius-borderRadiusSM, 4px);

  &:hover {
    background: ${({ active }) =>
      active
        ? 'var(--Colors-Neutral-Bg-colorBgSpotlight, rgba(0, 0, 0, 0.85))'
        : 'var(--Colors-Neutral-Bg-colorBgContainer, #fff)'} !important;
  }

  /* boxShadowTertiary */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03),
    0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
`;

const BodyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
  padding: var(--Space-Padding-paddingXXS, 4px)
    var(--Space-Padding-paddingSM, 12px);
`;
