import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useApolloContext } from 'contexts';
import { DeskAmenitiesQuery, DeskAmenitiesQueryVariables } from 'generated';

const DESK_AMENITIES = gql`
  query DeskAmenities($deskId: ID!) {
    getDeskById(id: $deskId) {
      id
      amenities {
        id
        name
        quantity
      }
    }
  }
`;

export const useDeskAmenities = (deskId: string | null | undefined) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    DeskAmenitiesQuery,
    DeskAmenitiesQueryVariables
  >(DESK_AMENITIES, {
    skip: !tenantId || !deskId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      deskId: deskId || '',
    },
  });

  return {
    loading,
    deskDetails: data?.getDeskById,
  };
};
