import { MutationTuple, useMutation, gql } from '@apollo/client';
import {
  EndOrCancelReservationByIdMutationResult,
  EndOrCancelReservationByIdMutationVariables,
} from 'generated';

const END_OR_CANCEL_RESERVATION = gql`
  mutation EndOrCancelReservationById($reservationId: ID!) {
    endOrCancelReservation(reservationId: $reservationId) {
      reservationId
    }
  }
`;

export const useEndOrCancelReservation = (): MutationTuple<
  EndOrCancelReservationByIdMutationResult,
  EndOrCancelReservationByIdMutationVariables
> => {
  return useMutation<
    EndOrCancelReservationByIdMutationResult,
    EndOrCancelReservationByIdMutationVariables
  >(END_OR_CANCEL_RESERVATION, {
    refetchQueries: [
      'useSeriesStartTimesForCancel',
      'DeskResourceDetails',
      'DeskReservationsBySeatId',
      'UseMySeatReservation',
      'GroupingMapSeatState',
      'GroupingMapSeatStateMultiDay',
      'DeskReservationsForCancelModal',
      'DeskReservationsForDeskPolicyCard',
    ],
  });
};
