import { useLocalStorage as useLocalStore } from 'react-use';

// Hook to access local storage
export const useLocalStorage = <T>(
  key: string,
  initial: T
): [T | undefined, (value: T) => void] => {
  const [val, set] = useLocalStore<T>(key, initial);
  return [val, set];
};
