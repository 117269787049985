import { Moment } from 'moment';

type SortableValue = string | number;

export function sortObjectAsc<T, K extends keyof T>(
  property: K & (T[K] extends SortableValue ? unknown : never)
): (a: T, b: T) => number {
  return (a, b) => {
    const valueA = a[property];
    const valueB = b[property];

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.localeCompare(valueB, undefined, { numeric: true });
    } else if (typeof valueA === 'number' && typeof valueB === 'number') {
      return valueA - valueB;
    } else {
      throw new Error('Sortable properties must be of type string or number');
    }
  };
}

export const sortDatesAscending = (a: Moment, b: Moment) => a.diff(b);
