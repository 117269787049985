import { FC } from 'react';
import { DeskListItemBase } from './DeskListItemBase';
import { useTranslation } from 'react-i18next';
import { PrivateIcon } from './icons/PrivateIcon';
import { useDefaultDeskListItemClick } from './hooks/useDefaultDeskListItemClick';

export const DeskListItemPrivate: FC<{
  deskId: string;
}> = ({ deskId }) => {
  const { t } = useTranslation('resourceDetails');

  const onClick = useDefaultDeskListItemClick(deskId);

  return (
    <DeskListItemBase
      title={t('desk_list.private')}
      subtitle={t('desk_list.unavailable')}
      icon={<PrivateIcon />}
      onClick={onClick}
    />
  );
};
