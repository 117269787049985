import styled from '@emotion/styled';
import { AmenitiesFilter } from './AmenitiesFilter';
import { DeskFilterContextProvider } from '../../context/DeskFilterContext';
import { useSetDeskSidebarView } from 'atoms/sidebar/hooks';
import { useEffect } from 'react';

const DeskSubFiltersWithContext = () => {
  const setDeskSidebarView = useSetDeskSidebarView();

  useEffect(() => {
    setDeskSidebarView('desk-list');
  }, [setDeskSidebarView]);

  return (
    <DeskFilterContextProvider>
      <Container>
        <AmenitiesFilter />
      </Container>
    </DeskFilterContextProvider>
  );
};

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
`;

export { DeskSubFiltersWithContext as DeskSubFilters };
