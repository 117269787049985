import styled from '@emotion/styled';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SidebarContent, SidebarHeader } from 'components/global/sidebar';
import { BookDeskDatePicker } from '../booking/BookDeskDatePicker';
import { BookDeskTimePickers } from '../booking/BookDeskTimePickers';
import {
  useCurrentlySelectedResource,
  useGetDeskEndLocationTime,
  useGetStartTimesForSelectedDates,
  useSetDatePickerType,
  useTimezone,
} from 'atoms/resource';
import { Typography } from 'antd';
import { DeskInformation } from '../DeskInformation';
import { Alert, Button, Skeleton } from '@robinpowered/ui-kit';
import {
  useEditDeskReservationId,
  useEditDeskNewDeskId,
  useSetEditDeskReservationId,
  useSetEditDeskNewDeskId,
  useSetEditDeskPotentialDeskId,
} from 'atoms/editDesk';
import EditAlt from '@robinpowered/icons/EditAlt';
import { momentToLocationDateTime } from 'utils';
import moment from 'moment';
import { EditSummaryViewFooter } from './EditSummaryViewFooter';
import { EditSummaryCancelModal } from './EditSummaryCancelModal';
import { useInitializeTimes } from './hooks';
import { useSetMapMode } from 'atoms/mapInteractions';
import { useBookingEnabledForDesk } from '../hooks/useBookingEnabledForDesk';
import { useEditReservationSummary } from '../graphql/useEditReservationSummary';
import { useBackDestinationContext } from './contexts';
import { useSetDeskSidebarView } from 'atoms/sidebar/hooks';

export const EditSummaryView: FC = () => {
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const { t } = useTranslation('resourceDetails');
  const { id: selectedResourceId } = useCurrentlySelectedResource() || {};
  const { setBackDestination } = useBackDestinationContext();
  const { timezone } = useTimezone();
  const startTimes = useGetStartTimesForSelectedDates();
  const endTime = useGetDeskEndLocationTime();
  const setView = useSetDeskSidebarView();

  const setDatePickerType = useSetDatePickerType();
  const setMapMode = useSetMapMode();

  const editReservationId = useEditDeskReservationId();
  const newDeskId = useEditDeskNewDeskId();
  const setEditDeskNewDeskId = useSetEditDeskNewDeskId();
  const setEditReservationId = useSetEditDeskReservationId();
  const setEditDeskPotentialDeskId = useSetEditDeskPotentialDeskId();

  useEffect(() => {
    setBackDestination('edit-summary');
  }, [setBackDestination]);

  const {
    loading,
    currentDeskData,
    currentDeskAvailability,
    newDeskData,
    newDeskAvailability,
    reservation,
  } = useEditReservationSummary(
    selectedResourceId,
    newDeskId,
    editReservationId
  );

  const { isBookable } = useBookingEnabledForDesk(
    newDeskId ?? selectedResourceId
  );

  useInitializeTimes({
    reservationStart: reservation?.startTime
      ? momentToLocationDateTime(moment(reservation?.startTime), timezone)
      : undefined,
    reservationEnd: reservation?.endTime
      ? momentToLocationDateTime(moment(reservation?.endTime), timezone)
      : undefined,
    nowAtLocation: momentToLocationDateTime(moment(), timezone),
  });

  useEffect(() => {
    if (reservation?.seriesId) {
      setDatePickerType(reservation?.seriesId ? 'multi' : 'single');
    }
  }, [reservation?.seriesId, setDatePickerType]);

  const hasStartTimeBeenEdited = useMemo(() => {
    const selectedStartTime = startTimes?.[0];

    const reservationStart = momentToLocationDateTime(
      moment(reservation?.startTime),
      timezone
    );

    return !selectedStartTime?.isSame(reservationStart);
  }, [reservation?.startTime, startTimes, timezone]);

  const hasEndTimeBeenEdited = useMemo(() => {
    const reservationEnd = momentToLocationDateTime(
      moment(reservation?.endTime),
      timezone
    );

    return !endTime?.isSame(reservationEnd);
  }, [endTime, reservation?.endTime, timezone]);

  const hasDeskBeenEdited = useMemo(() => !!newDeskId, [newDeskId]);

  const hasBeenEdited = useMemo(
    () => hasDeskBeenEdited || hasStartTimeBeenEdited || hasEndTimeBeenEdited,
    [hasDeskBeenEdited, hasEndTimeBeenEdited, hasStartTimeBeenEdited]
  );

  const handleCleanup = useCallback(() => {
    setView('desk-details');
    setMapMode('browse');
    setEditDeskPotentialDeskId(null);
    setEditDeskNewDeskId(null);
    setEditReservationId(null);
  }, [
    setEditDeskNewDeskId,
    setEditDeskPotentialDeskId,
    setEditReservationId,
    setMapMode,
    setView,
  ]);

  const onClose = useCallback(() => {
    if (!hasBeenEdited) {
      handleCleanup();
    } else {
      setShowCancelModal(true);
    }
  }, [hasBeenEdited, handleCleanup]);

  const handleViewDeskList = useCallback(() => {
    setView('edit-desk-list');
  }, [setView]);

  const handleRemoveNewDesk = useCallback(() => {
    setEditDeskNewDeskId(null);
  }, [setEditDeskNewDeskId]);

  const handleViewCurrentDeskDetails = useCallback(() => {
    setView('current-desk');
  }, [setView]);

  const handleViewNewDeskDetails = useCallback(() => {
    setView('new-desk');
  }, [setView]);

  return (
    <>
      <SidebarHeader
        prefix={<EditAlt size={18} />}
        header={t('edit_desk_reservation.summary_header')}
        onClose={onClose}
      />

      <SidebarContent>
        {loading && <Skeleton active />}

        {!loading && currentDeskData && (
          <Container data-testid="content">
            <Alert
              message="Under Construction. Edit reservation is in progress."
              type="warning"
            />

            <Section>
              <Typography.Title level={5}>
                {t('edit_desk_reservation.booking_controls_title')}
              </Typography.Title>

              <BookDeskDatePicker
                unbookableReasons={
                  newDeskAvailability?.unbookableReasons ??
                  currentDeskAvailability?.unbookableReasons
                }
                conflictingReservations={
                  newDeskData?.state?.reservations ??
                  currentDeskData?.state.reservations
                }
              />

              <BookDeskTimePickers
                unbookableReasons={
                  newDeskAvailability?.unbookableReasons ??
                  currentDeskAvailability?.unbookableReasons
                }
              />
            </Section>

            <Section>
              <DeskSectionHeader>
                <Typography.Title level={5}>
                  {t('edit_desk_reservation.desk_section_title')}
                </Typography.Title>

                <Button type="link" size="small" onClick={handleViewDeskList}>
                  {t('edit_desk_reservation.view_desk_list_action')}
                </Button>
              </DeskSectionHeader>

              <Typography.Text>
                {t('edit_desk_reservation.change_desk_description')}
              </Typography.Text>

              <DeskInformationCard
                name={currentDeskData.name}
                levelName={currentDeskData.level?.name}
                locationName={currentDeskData.location.name}
                disabled={!!newDeskId}
              >
                {!newDeskId && (
                  <Button
                    onClick={handleViewCurrentDeskDetails}
                    data-testid="current-desk-view-details"
                  >
                    {t('edit_desk_reservation.view_desk_details_action')}
                  </Button>
                )}
              </DeskInformationCard>
            </Section>

            {newDeskId && (
              <Section>
                <Typography.Title level={5}>
                  {t('edit_desk_reservation.updated_desk_section_title')}
                </Typography.Title>

                <DeskInformationCard
                  name={newDeskData?.name}
                  levelName={newDeskData?.level?.name}
                  locationName={newDeskData?.location.name}
                  loading={loading}
                  data-testid="new-desk-card"
                >
                  <CardActions>
                    <Button
                      onClick={handleViewNewDeskDetails}
                      disabled={loading}
                      data-testid="new-desk-view-details"
                    >
                      {t('edit_desk_reservation.view_desk_details_action')}
                    </Button>

                    <Button
                      data-testid="new-desk-remove-action"
                      onClick={handleRemoveNewDesk}
                    >
                      {t('edit_desk_reservation.remove_new_desk_action')}
                    </Button>
                  </CardActions>
                </DeskInformationCard>
              </Section>
            )}
          </Container>
        )}
      </SidebarContent>

      <EditSummaryViewFooter
        hasDeskBeenEdited={hasDeskBeenEdited}
        hasStartTimeBeenEdited={hasStartTimeBeenEdited}
        hasEndTimeBeenEdited={hasEndTimeBeenEdited}
        isBookable={isBookable}
        loading={loading}
        onCancel={onClose}
      />

      <EditSummaryCancelModal
        open={showCancelModal}
        onCancel={() => setShowCancelModal(false)}
        onClose={() => setShowCancelModal(false)}
        onOk={handleCleanup}
        data-testid="cancel-modal"
      />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DeskInformationCard = styled(DeskInformation)`
  padding: var(--Space-Padding-paddingSM, 12px);
  border-radius: var(--Border-Radius-borderRadius, 4px);
  border: 1px solid var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
`;

const CardActions = styled.div`
  display: flex;
  gap: 8px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DeskSectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
