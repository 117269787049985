import { MutationTuple, useMutation, gql } from '@apollo/client';
import {
  EndReservationOrVisitsInRangeMutationResult,
  EndReservationOrVisitsInRangeMutationVariables,
} from 'generated';

const END_RESERVATION_OR_VISITS_IN_RANGE = gql`
  mutation EndReservationOrVisitsInRange(
    $orgId: ID!
    $userId: ID!
    $start: Date!
    $end: Date!
    $cancelFutureVisits: Boolean
    $creationType: [String]
  ) {
    endReservationOrVisitsInRange(
      orgId: $orgId
      userId: $userId
      start: $start
      end: $end
      cancelFutureVisits: $cancelFutureVisits
      creationType: $creationType
    ) {
      success
      visitsCancelledAttempts {
        id
        success
        error
      }
      reservationsCancelledAttempts {
        id
        success
        error
      }
    }
  }
`;

export const useEndReservationOrVisitsInRange = (): MutationTuple<
  EndReservationOrVisitsInRangeMutationResult,
  EndReservationOrVisitsInRangeMutationVariables
> => {
  return useMutation<
    EndReservationOrVisitsInRangeMutationResult,
    EndReservationOrVisitsInRangeMutationVariables
  >(END_RESERVATION_OR_VISITS_IN_RANGE, {
    refetchQueries: [
      'DeskReservationsBySeatId',
      'UseMySeatReservation',
      'GroupingMapSeatState',
      'GroupingMapSeatStateMultiDay',
      'DeskReservationsForCancelModal',
      'DeskReservationsForDeskPolicyCard',
    ],
  });
};
