import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useApolloContext } from 'contexts';
import {
  DeskLocationDetailsQuery,
  DeskLocationDetailsQueryVariables,
} from 'generated';

const DESK_LOCATION_DETAILS = gql`
  query DeskLocationDetails($deskId: ID!) {
    getDeskById(id: $deskId) {
      id
      name
      level {
        name
      }
      location {
        name
      }
    }
  }
`;

export const useDeskLocationDetails = (deskId: string | undefined) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    DeskLocationDetailsQuery,
    DeskLocationDetailsQueryVariables
  >(DESK_LOCATION_DETAILS, {
    skip: !tenantId || !deskId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      deskId: deskId || '',
    },
  });

  return {
    loading,
    deskDetails: data?.getDeskById,
  };
};
