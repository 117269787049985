import styled from '@emotion/styled';
import { Skeleton } from '@robinpowered/ui-kit';

export const DeskListItemSkeleton = () => {
  return (
    <Container data-testid="skeleton">
      <Skeleton.Avatar active />

      <ContentContainer>
        <Skeleton.Button active style={{ height: 22, width: '50%' }} />
        <Skeleton.Button active style={{ height: 22, width: '25%' }} />
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--Components-List-Component-avatarMarginRight, 16px);
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
`;
