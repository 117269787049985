import { gql } from '@apollo/client';
import { useQueryCachedLoad } from 'hooks';
import { startTimeState, useSelectedLocation } from 'atoms/resource';
import {
  UseLocationWorkingHoursQuery,
  UseLocationWorkingHoursQueryVariables,
} from 'generated';
import { useAuthContext } from 'contexts';
import { useRecoilValue } from 'recoil';

export const OFFICE_HOURS_QUERY = gql`
  query useLocationWorkingHours(
    $locationId: ID!
    $startTime: Date!
    $endTime: Date!
    $userId: ID!
  ) {
    getLocationById(id: $locationId) {
      id
      name
      address
      timezone
      state(startTime: $startTime, endTime: $endTime, userId: $userId) {
        isOpen
      }
      workingHours {
        day
        timeFrames {
          start
          end
        }
      }
      customWorkingHours {
        id
        date
        timeFrames {
          start
          end
        }
      }
    }
  }
`;

export const useLocationWorkingHours = () => {
  const [selectedLocation] = useSelectedLocation();
  const { currentUser } = useAuthContext();
  const selectedStartTime = useRecoilValue(startTimeState);

  const result = useQueryCachedLoad<
    UseLocationWorkingHoursQuery,
    UseLocationWorkingHoursQueryVariables
  >(OFFICE_HOURS_QUERY, {
    variables: {
      locationId: selectedLocation || '',
      startTime: selectedStartTime || '',
      endTime: selectedStartTime
        ? new Date(
            new Date(selectedStartTime).getTime() + 30 * 60 * 1000
          ).toISOString()
        : '',
      userId: currentUser?.id || '',
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !selectedLocation || !selectedStartTime || !currentUser?.id,
  });

  return {
    loading: result.loading,
    location: result.data?.getLocationById,
  };
};
