import styled from '@emotion/styled';
import { theme, Typography } from '@robinpowered/ui-kit';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FC } from 'react';

export const ViolationMessage: FC<{ message: string }> = ({ message }) => {
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <Container>
      <ExclamationCircleFilled style={{ color: token.colorErrorText }} />
      <ErrorMessage size="small">{message}</ErrorMessage>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ErrorMessage = styled(Typography.Text)`
  color: var(--utilities-negative-red-100, #e81c1c);
`;
