import { ApolloLink } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

export const traceLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-datadog-trace-id': uuidv4(),
    },
  }));

  return forward(operation);
});
