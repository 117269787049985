import {
  useGetSpaceEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useTimezone,
} from 'atoms/resource';
import { useMemo } from 'react';

export const useSpaceAvailabilityParameters = () => {
  const { timezone } = useTimezone();

  const startTimes = useGetStartTimesForSelectedDates();
  const spaceEndTimes = useGetSpaceEndTimesForSelectedDates();

  const spaceAvailabilityDuration = useMemo(() => {
    const startTime = startTimes?.[0];
    const endTime = spaceEndTimes?.[0];

    return (
      (startTime && endTime && endTime.diff(startTime, 'minute')) || undefined
    );
  }, [spaceEndTimes, startTimes]);

  const firstStartTime = startTimes?.[0];
  const firstEndTime = spaceEndTimes?.[0];
  const dates = useMemo(() => {
    return startTimes
      ? startTimes.map((startTime) =>
          startTime.clone().tz(timezone).format('YYYY-MM-DD')
        )
      : [];
  }, [startTimes, timezone]);

  return {
    dates,
    durationInMinutes: spaceAvailabilityDuration || 15,
    timezone,
    startTime: firstStartTime,
    endTime: firstEndTime,
  };
};
