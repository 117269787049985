import { useAuthContext } from 'contexts';
import { DeskListItemType, DeskWithAvailability } from '../../types';
import { DeskReservationVisibility } from 'generated';
import { DeskListItemData } from '../../../graphql/useDeskListItemData';
import { useMemo } from 'react';
export const useDeskListItemType = (
  availability: DeskWithAvailability,
  deskData: DeskListItemData | null | undefined
): DeskListItemType => {
  const { currentUser, isAdmin } = useAuthContext();

  const itemType: DeskListItemType = useMemo(() => {
    if (availability.noAccessOrPermission) {
      return 'no_access_or_permission';
    }

    if (availability.requiresAssignmentByAdmin) {
      return 'assigned';
    }

    if (!availability.isBookable) {
      if (deskData?.state?.reservations?.length) {
        const reservation = deskData?.state?.reservations?.[0];
        const isCurrentUserReservation =
          reservation?.reservee?.user?.id === currentUser?.id;
        const isPrivateAndNotAdmin =
          reservation?.visibility === DeskReservationVisibility.JustMe &&
          !isAdmin;

        if (isCurrentUserReservation) {
          return 'current_user_reservation';
        } else if (isPrivateAndNotAdmin) {
          return 'private';
        } else {
          return 'booked';
        }
      }

      // default booked
      return 'unavailable';
    }

    // If we fall through it must be available
    return 'available';
  }, [availability, deskData, currentUser, isAdmin]);

  return itemType;
};
