import { useCallback } from 'react';
import { useSetCurrentlySelectedResource } from 'atoms/resource';
import { useSetDeskSidebarView } from 'atoms/sidebar/hooks';

export const useViewDeskDetails = (deskId: string) => {
  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const setView = useSetDeskSidebarView();

  return useCallback(() => {
    setCurrentlySelectedResource({ id: deskId, type: 'seats' });
    setView('desk-details');
  }, [deskId, setCurrentlySelectedResource, setView]);
};
