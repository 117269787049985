/* eslint-disable @shopify/jsx-no-hardcoded-content */
import { Tag } from '@robinpowered/ui-kit';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RRule } from 'rrule';

const dayMap: { [key: string]: string } = {
  0: 'mo',
  1: 'tu',
  2: 'we',
  3: 'th',
  4: 'fr',
  5: 'sa',
  6: 'su',
};

interface AssignedDeskScheduleProps {
  recurrence: RRule | null;
}

export function AssignedDeskSchedule({
  recurrence,
}: AssignedDeskScheduleProps) {
  const { t } = useTranslation('resourceDetails');
  const formattedSchedule = useMemo(() => {
    if (!recurrence || !recurrence.options) {
      return '';
    }

    const days = recurrence.options.byweekday?.map((day) =>
      t(`days.${dayMap[day.toString()]}`)
    );
    return days ? days.join(', ') : '';
  }, [recurrence, t]);

  if (!recurrence) {
    return null;
  }

  return (
    <div>
      <Tag>{t('assigned')}</Tag>
      {formattedSchedule}
    </div>
  );
}
