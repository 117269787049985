import { gql } from '@apollo/client';
import {
  DeskFilters,
  DesksOnFloorQuery,
  DesksOnFloorQueryVariables,
} from 'generated';
import { useQueryCachedLoad } from 'hooks';

export type DesksOnFloorData = DesksOnFloorQuery['getLevelById'];
export type DesksOnFloorZone = NonNullable<DesksOnFloorData>['zones'][number];
export type DesksOnFloorDesk = DesksOnFloorZone['desks'][number];

const QUERY_NAME = gql`
  query DesksOnFloor($levelId: ID!, $filters: [DeskFilters!]) {
    getLevelById(id: $levelId) {
      id
      zones {
        id
        name
        desks(filters: $filters) {
          id
          name
        }
      }
    }
  }
`;

export const useDesksOnFloor = (levelId: string | null) => {
  const { loading, data } = useQueryCachedLoad<
    DesksOnFloorQuery,
    DesksOnFloorQueryVariables
  >(QUERY_NAME, {
    skip: !levelId,
    variables: {
      levelId: levelId ?? '',
      filters: [DeskFilters.Enabled],
    },
  });

  return {
    loading,
    data,
  };
};
