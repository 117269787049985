import moment, { Moment, MomentSetObject, unitOfTime } from 'moment-timezone';

import { CustomWorkingHours, WorkingHours } from 'generated';
import { sortDatesAscending } from './sort';

/**
 * branding type here to prevent non ISO string types from being set and compared
 */
export type IsoString = string & { __brand: 'IsoString' };
export type RDateString = `RDATE:${string}`;

export const momentToIsoString = (
  date: Moment | LocationDateTimeMoment
): IsoString => {
  return date.clone().utc().format('YYYY-MM-DDTHH:mm:ssZ') as IsoString;
};

/**
 * Moment representing a datetime in local timezone.
 */
export interface LocationDateTimeMoment extends Moment {
  __brand: 'LocationMoment';
  clone(): LocationDateTimeMoment;
  endOf(unitOfTime: unitOfTime.StartOf): LocationDateTimeMoment;
  startOf(unitOfTime: unitOfTime.StartOf): LocationDateTimeMoment;
  subtract(
    amount?: moment.DurationInputArg1,
    unit?: moment.DurationInputArg2
  ): LocationDateTimeMoment;
  subtract(
    unit: moment.unitOfTime.DurationConstructor,
    amount: string | number
  ): LocationDateTimeMoment;
  add(
    unit: moment.unitOfTime.DurationConstructor,
    amount: string | number
  ): LocationDateTimeMoment;
  add(
    amount?: moment.DurationInputArg1,
    unit?: moment.DurationInputArg2
  ): LocationDateTimeMoment;
  set(unit: unitOfTime.All, value: number): LocationDateTimeMoment;
  set(objectLiteral: MomentSetObject): LocationDateTimeMoment;
}
export const createLocationDateTimeMoment = (
  timezone: string,
  input?: moment.MomentInput,
  format?: moment.MomentFormatSpecification,
  strict?: boolean
): LocationDateTimeMoment => {
  return moment(input, format, strict).tz(timezone) as LocationDateTimeMoment;
};

/**
 * Returns the current moment in provided timezone
 * @param timezone timezone string
 * @returns {LocationDateTimeMoment} LocationDateTimeMoment
 */
export const locationDateTimeMoment = (
  timezone: string
): LocationDateTimeMoment => {
  const createLocationDateTime = (moment: Moment) => {
    (moment as LocationDateTimeMoment).__brand = 'LocationMoment';
    return moment as LocationDateTimeMoment;
  };
  return createLocationDateTime(moment.tz(moment(), timezone));
};

/**
 *
 * @param dateTime generic moment object
 * @param timezone timezone string
 * @returns {LocationDateTimeMoment} LocationDateTimeMoment
 */
export const momentToLocationDateTime = (
  dateTime: Moment,
  timezone: string
): LocationDateTimeMoment => {
  const createLocationDateTime = (moment: Moment) => {
    (moment as LocationDateTimeMoment).__brand = 'LocationMoment';
    return moment as LocationDateTimeMoment;
  };

  return dateTime.tz() !== timezone
    ? createLocationDateTime(moment.tz(dateTime, timezone))
    : createLocationDateTime(dateTime.clone());
};

export const isMidnight = (date: Moment) => {
  return date.hours() === 0 && date.minutes() === 0;
};

export const getWorkingHoursForDay = (
  workingHours: WorkingHours[],
  day: number
) => {
  // Default to 7am-7pm if working hours aren't set, the office is closed, etc.
  const defaults = { start: 7, end: 19 };

  const dayOfWeek = day === 7 ? 0 : day;
  const workingHour = workingHours.find((wh) => wh.day === dayOfWeek);
  const timeFrame = workingHour?.timeFrames[0];

  if (!timeFrame) {
    return defaults;
  }

  return {
    start: moment.duration(timeFrame.start).asHours(),
    end: moment.duration(timeFrame.end).asHours(),
  };
};

export const calculateMinimumEndTime = <
  T extends Moment | LocationDateTimeMoment
>(
  startTime: T
): T => {
  const end = startTime.clone();
  if (startTime.minutes() < 15) {
    end.minutes(30).seconds(0);
  } else if (startTime.minutes() < 30) {
    end.minutes(45).seconds(0);
  } else if (startTime.minutes() < 45) {
    end.add(1, 'hour').minutes(0).seconds(0);
  } else {
    end.add(1, 'hour').minutes(15).seconds(0);
  }

  return end as T;
};

export const calculateEndOfDay = <T extends Moment | LocationDateTimeMoment>(
  startTime: T,
  openHours?: { start: number; end: number }
): T => {
  const end = startTime.clone();
  const isStartTimeAfterEndOfOpenHours =
    openHours && startTime.hour() >= openHours.end;

  if (!openHours || isStartTimeAfterEndOfOpenHours) {
    return end.endOf('day') as T;
  }
  return end.hour(openHours.end).minute(0) as T;
};

export const hasTodaySelected = (
  dates: Moment[],
  timezone: string
): boolean => {
  return dates.some((day) => {
    const clonedDay = day.clone();
    const today = moment().tz(timezone);
    return clonedDay.isSame(today, 'day');
  });
};

export const hasPastDateSelected = (
  dates: Moment[],
  timezone: string
): boolean => {
  return dates.some((day) =>
    day.isBefore(moment().tz(timezone).startOf('day'))
  );
};

export const hasFutureDateSelected = (
  dates: Moment[],
  timezone: string
): boolean => {
  return dates.some((day) => day.isAfter(moment().tz(timezone).endOf('day')));
};

export const findCustomHourForDate = (
  customWorkingHours: CustomWorkingHours[],
  date: string,
  timezone: string
): { start: number; end: number } | null => {
  const customWorkingHour =
    customWorkingHours && customWorkingHours.find((cwa) => cwa.date === date);
  if (customWorkingHour && customWorkingHour.timeFrames.length > 0) {
    const { start, end } = customWorkingHour.timeFrames[0];
    return {
      start: moment.tz(`${customWorkingHour.date} ${start}`, timezone).hour(),
      end: moment.tz(`${customWorkingHour.date} ${end}`, timezone).hour(),
    };
  }
  return null;
};

/** Example: Converts the timezone string 'America/New_York' to the long name 'Eastern Standard Time'*/
export const getLongTimeZoneName = (
  timeZoneString: string | null | undefined
) => {
  try {
    if (timeZoneString) {
      const formatter = new Intl.DateTimeFormat(undefined, {
        timeZone: timeZoneString,
        timeZoneName: 'long',
      });

      return formatter
        .formatToParts(new Date())
        .find((p) => p.type === 'timeZoneName')?.value;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return;
  }
};

/** From a start and end time populate all times in between (by day) */
export const createDateRange = <T extends Moment | LocationDateTimeMoment>(
  start: T,
  end: T
): T[] => {
  const dates = [];
  const currentDate = start.clone();

  while (currentDate.isSameOrBefore(end, 'day')) {
    dates.push(currentDate.clone() as T);
    currentDate.add(1, 'day');
  }

  return dates;
};

export const createRecurrenceFromDates = (
  dates: LocationDateTimeMoment[] | null
): RDateString => {
  if (!dates) {
    return '' as RDateString;
  }

  const formattedDates = dates
    .map((date) => date.clone().utc().format('YYYYMMDDTHHmmss[Z]'))
    .join(',');

  return `RDATE:${formattedDates}`;
};

/** Does the given array contain consecutive dates */
export const isConsecutiveList = (
  dates: (Moment | LocationDateTimeMoment)[]
) => {
  const sorted = dates.sort(sortDatesAscending);

  return sorted.reduce((isContinuous, currentDate, currentIndex) => {
    if (currentIndex === sorted.length - 1) return isContinuous;
    return (
      isContinuous && sorted[currentIndex + 1].diff(currentDate, 'days') === 1
    );
  }, sorted.length > 1);
};
