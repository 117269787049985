import { gql, useMutation } from '@apollo/client';
import { message } from '@robinpowered/ui-kit';
import {
  DeleteDeskExclusionsMutation,
  DeleteDeskExclusionsMutationVariables,
} from 'generated';
import { useTranslation } from 'react-i18next';

const RESERVE_DESK_MUTATION = gql`
  mutation deleteDeskExclusions(
    $seriesId: ID!
    $exclusionIds: [ID!]!
    $dates: [DateRangeWithTimeZone!]!
    $hasDates: Boolean!
    $hasDeletions: Boolean!
  ) {
    deleteDeskExclusions(seriesId: $seriesId, exclusionIds: $exclusionIds)
      @include(if: $hasDeletions)
    createDeskExclusions(seriesId: $seriesId, dates: $dates)
      @include(if: $hasDates)
  }
`;

const perseusAvailabilityQueries = [
  'UseMySeatReservation',
  'GroupingMapSeatState',
  'GroupingMapSeatStateMultiDay',
];

export const usePatchExclusions = (
  seriesId: string,
  exclusionIds: string[],
  dates: {
    start: { dateTime: string; timeZone: string };
    end: { dateTime: string; timeZone: string };
  }[],
  hasDates: boolean,
  hasDeletions: boolean
) => {
  const { t } = useTranslation('resourceDetails');

  const [reserveDesk, { loading: isReserving }] = useMutation<
    DeleteDeskExclusionsMutation,
    DeleteDeskExclusionsMutationVariables
  >(RESERVE_DESK_MUTATION, {
    variables: {
      seriesId,
      exclusionIds,
      dates,
      hasDates,
      hasDeletions,
    },
    refetchQueries: [
      'DeskResourceDetails',
      'DeskReservationsBySeatId',
      'DeskReservationsForDeskPolicyCard',
      'GetReleaseDeskExclusions',
      'GetReleaseDeskScheduleByDeskId',
      ...perseusAvailabilityQueries,
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      void message.open({
        type: 'success',
        content: t('reservation_exclusions.success'),
      });
    },
    onError: () => {
      void message.open({
        type: 'error',
        content: t('reservation_exclusions.failure'),
      });
    },
  });

  return {
    reserveDesk,
    isReserving,
  };
};
