import {
  useGetDeskEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useTimezone,
} from 'atoms/resource';
import { useMemo } from 'react';
import {
  createRecurrenceFromDates,
  LocationDateTimeMoment,
  RDateString,
} from 'utils';

interface DeskAvailabilityQueryParameters {
  dates: string[];
  startTimes: LocationDateTimeMoment[] | null;
  endTime: LocationDateTimeMoment | undefined;
  durationInMinutes: number;
  recurrence?: RDateString;
}

export const useDeskAvailabilityQueryParameters =
  (): DeskAvailabilityQueryParameters => {
    const { timezone } = useTimezone();

    const startTimes = useGetStartTimesForSelectedDates();
    const deskEndTimes = useGetDeskEndTimesForSelectedDates();

    const firstStartTime = startTimes?.[0];
    const firstEndTime = deskEndTimes?.[0];

    const durationInMinutes = useMemo(() => {
      if (firstStartTime && firstEndTime) {
        return firstEndTime.diff(firstStartTime, 'minute');
      }
      return 15;
    }, [firstStartTime, firstEndTime]);

    const dates = useMemo(() => {
      return startTimes
        ? startTimes.map((startTime) =>
            startTime.clone().tz(timezone).format('YYYY-MM-DD')
          )
        : [];
    }, [startTimes, timezone]);

    const recurrence = useMemo(() => {
      return startTimes && startTimes.length > 0
        ? createRecurrenceFromDates(startTimes)
        : undefined;
    }, [startTimes]);

    return {
      dates,
      startTimes,
      endTime: deskEndTimes?.[0],
      durationInMinutes,
      recurrence,
    };
  };
