import { atom } from 'recoil';
import { EditSpaceView } from './types';

export const AtomKey = {
  editSpaceView: 'editSpaceView',
  spaceList: 'spaceList',
};

export const editSpaceView = atom<EditSpaceView>({
  key: AtomKey.editSpaceView,
  default: 'space-list',
});
