import { useCallback, useMemo } from 'react';
import { DeskSettings, DeskSettingSlug } from '../../graphql/useDeskSettings';
import { Reservation } from '../../graphql';
import moment, { duration } from 'moment';
import { momentToLocationDateTime } from 'utils';
import { useTimezone } from 'atoms/resource';

export function useDeskReservationSettings(deskSettings: DeskSettings) {
  const { timezone } = useTimezone();

  const allowCheckIns = useMemo(
    () =>
      deskSettings[DeskSettingSlug.AssignedReservationCheckInsEnabled] ===
      'true',
    [deskSettings]
  );

  const enforceLocalCheckInOnly = useMemo(
    () => deskSettings[DeskSettingSlug.EnforceLocalCheckInOnly] === 'true',
    [deskSettings]
  );

  const allowExclusions = useMemo(
    () => deskSettings[DeskSettingSlug.AllowExclusions] === 'true',
    [deskSettings]
  );

  const getCheckInStart = useCallback(
    (reservation: Reservation) => {
      const reservationStart = momentToLocationDateTime(
        moment(reservation.startTime),
        timezone
      );
      const checkInWindow =
        deskSettings[DeskSettingSlug.SeatReservationCheckInWindow];
      const checkInDuration = duration(checkInWindow);
      return checkInDuration.isValid()
        ? reservationStart.subtract(checkInDuration)
        : reservationStart;
    },
    [deskSettings, timezone]
  );

  const getDeskAbandonedAt = useCallback(
    (reservation: Reservation) => {
      const abandonmentEnabled =
        deskSettings[DeskSettingSlug.AbandonedDeskProtectionEnabled] === 'true';
      if (!abandonmentEnabled) {
        return null;
      }

      const reservationStart = momentToLocationDateTime(
        moment(reservation.startTime),
        timezone
      );
      const abandonmentThreshold =
        deskSettings[DeskSettingSlug.AbandonedDeskProtectionThreshold];
      const abandonmentDuration = duration(abandonmentThreshold);
      return abandonmentDuration.isValid()
        ? reservationStart.add(abandonmentDuration)
        : null;
    },
    [deskSettings, timezone]
  );

  return {
    getCheckInStart,
    getDeskAbandonedAt,
    allowCheckIns,
    enforceLocalCheckInOnly,
    allowExclusions,
  };
}
