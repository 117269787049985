const serializeByType = (data: unknown) => {
  if (typeof data === 'string') {
    return data;
  }
  return JSON.stringify(data);
};

export const dataToParam = (data: unknown) => {
  try {
    const nonEmptyData = data === undefined ? '' : data;
    return serializeByType(nonEmptyData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return '';
  }
};

export const paramToData = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    if (typeof str === 'string') {
      return str;
    }
    // eslint-disable-next-line no-console
    console.error(`Unable to parse param ${str} ${e}`);
    return null;
  }
};
