import { DatePicker } from '@robinpowered/ui-kit';
import momentGenerateConfig from 'rc-picker/es/generate/moment';
import { LocationDateTimeMoment } from 'utils';

const MomentDatePicker =
  // @ts-expect-error LocationDateTimeMoment is just a moment. This ensures we pass in a location timezoned moment to our datepickers
  DatePicker.generatePicker<LocationDateTimeMoment>(momentGenerateConfig);
const MomentRangePicker = MomentDatePicker.RangePicker;

export { MomentDatePicker, MomentRangePicker };
