import { useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  SpaceReservationPoliciesQuery,
  SpaceReservationPoliciesQueryVariables,
} from 'generated';
import gql from 'graphql-tag';

const SPACE_RESERVATION_POLICIES = gql`
  query SpaceReservationPolicies($id: ID!) {
    getSpaceById(id: $id) {
      reservationPolicies {
        id
        meetingCheckins
        abandonedMeetingProtection
        abandonedThreshold
        strikesForRecurringEvents
        maxReservationLength
        advanceBookingThreshold
        allowRecurringEvents
        restrictBookingToWorkingHours
      }
    }
  }
`;

export const useSpaceReservationPolicies = (spaceId: string | undefined) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    SpaceReservationPoliciesQuery,
    SpaceReservationPoliciesQueryVariables
  >(SPACE_RESERVATION_POLICIES, {
    skip: !tenantId || !spaceId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: spaceId || '',
    },
  });

  return { loading, policies: data?.getSpaceById?.reservationPolicies };
};
