import React from 'react';
import { DeskAssignedSolid } from '@robinpowered/icons';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

type CancelReservationDeskDetailsProps = {
  reservationUserName?: string | null;
  deskName: string;
  floorName?: string;
  buildingName: string;
};

export function CancelReservationDeskDetails({
  reservationUserName,
  deskName,
  floorName,
  buildingName,
}: CancelReservationDeskDetailsProps) {
  const { t } = useTranslation('resourceDetails');

  return (
    <DeskSection>
      <DeskAssignedSolid size={16} />
      <DeskSectionDescription>
        <div>
          {t('cancel_modal.desk_name', {
            userName: reservationUserName,
            deskName,
          })}
        </div>
        <DeskSectionLine>
          {t('cancel_modal.floor_and_building', {
            floorName: floorName || '',
            buildingName,
          })}
        </DeskSectionLine>
      </DeskSectionDescription>
    </DeskSection>
  );
}

const DeskSection = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

const DeskSectionDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeskSectionLine = styled.div`
  font-size: 14px;
  color: gray;
`;
