import { useMutation } from '@apollo/client';
import { PERSEUS_AVAILABILITY_QUERIES } from 'constants/perseus';
import { EditBookingMutation, EditBookingMutationVariables } from 'generated';
import gql from 'graphql-tag';

export const EDIT_BOOKING_MUTATION = gql`
  mutation EditBooking($input: UpdateMyReservationDeskAndTimeFrameInput!) {
    updateMyReservationDeskAndTimeFrame(input: $input) {
      updatedReservations {
        id
      }
    }
  }
`;

export const useEditBooking = () => {
  const [editBooking, { loading }] = useMutation<
    EditBookingMutation,
    EditBookingMutationVariables
  >(EDIT_BOOKING_MUTATION, {
    refetchQueries: [
      'DeskResourceDetails',
      'DeskReservationsBySeatId',
      'DeskReservationsForDeskPolicyCard',
      ...PERSEUS_AVAILABILITY_QUERIES,
    ],
  });

  return {
    editBooking,
    loading,
  };
};
