import { theme } from '@robinpowered/ui-kit';

export const useAvailabilityPillStyles = () => {
  const { token } = theme.useToken();

  return {
    colorSuccessText: token.colorSuccessText,
    colorSuccessBorder: token.colorSuccessBorder,
    colorSuccessTextHover: token.colorSuccessTextHover,
    colorSuccessBorderHover: token.colorSuccessBorderHover,
    colorSuccessTextActive: token.colorSuccessTextActive,
    colorSuccessActive: token.colorSuccessActive,
    colorTextDisabled: token.colorTextDisabled,
    colorBgContainerDisabled: token.colorBgContainerDisabled,
    colorBgBase: token.colorBgBase,
  };
};
