import {
  FC,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
} from '@apollo/client';
import { useAccessToken } from 'hooks/useAccessToken';
import { config } from 'config';
import {
  cacheHitLink,
  errorLink,
  traceLink,
  persistedQueryLink,
  httpLink,
  createAuthLink,
} from './links';

type ApolloContextValue = {
  tenantId: string | undefined;
  setTenantId: Dispatch<SetStateAction<string | undefined>>;
};

const createApolloClient = (
  accessToken: string | null,
  tenantId: string | undefined
) => {
  const authLink = createAuthLink(accessToken, tenantId);

  return new ApolloClient({
    name: config.appName ?? 'unknown-react-dashboard-app',
    version: config.appVersion ?? '0.0',
    link: from([
      cacheHitLink,
      errorLink,
      authLink,
      traceLink,
      ...(persistedQueryLink ? [persistedQueryLink] : []),
      httpLink,
    ]),
    // Adding typenames and result caching to the cache explicitly even though 'true' is the default on both.
    //
    // TODO: we might want to expand the configuration with typePolicies or custom merge functions depending
    // to get the most out of the cache.
    cache: new InMemoryCache({ addTypename: true, resultCaching: true }),
    credentials: 'include',
    resolvers: {},
  });
};

const ApolloContext = createContext<ApolloContextValue>({
  tenantId: undefined,
  setTenantId: () => null,
});

export const ApolloContextProvider: FC = ({ children }) => {
  const [tenantId, setTenantId] = useState<string | undefined>();
  const accessToken = useAccessToken();

  const apolloClient = useMemo(
    () => createApolloClient(accessToken, tenantId),
    [accessToken, tenantId]
  );

  return (
    <ApolloContext.Provider value={{ tenantId, setTenantId }}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </ApolloContext.Provider>
  );
};

export const useApolloContext = (): ApolloContextValue => {
  return useContext(ApolloContext);
};
