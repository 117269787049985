import styled from '@emotion/styled';
import { rightSidebarView } from '../../atoms/sidebar/atoms';
import { Checkbox, Skeleton, Input, message } from '@robinpowered/ui-kit';
import { useCurrentlySelectedResource } from 'atoms/resource';
import { useState, useCallback, ChangeEvent, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { useDeskLocationDetails, useReportIssueWithDesk } from './graphql';
import {
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from '../../components/global/sidebar';
import { useTranslation } from 'react-i18next';
import { Typography } from '@robinpowered/ui-kit';

const { Title, Text } = Typography;
const { TextArea } = Input;

export const ReportIssue = () => {
  const { t } = useTranslation('resourceDetails');
  const setRightSidebarView = useSetRecoilState(rightSidebarView);
  const { id: selectedResourceId } = useCurrentlySelectedResource() || {};
  const { deskDetails, loading } = useDeskLocationDetails(selectedResourceId);

  const [textValue, setTextValue] = useState<string>('');
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [reportIssueWithDesk, reportIssueWithDeskRequest] =
    useReportIssueWithDesk();

  const onClose = useCallback(() => {
    // todo: change this account for spaces once report issue is implemeted for spaces
    setRightSidebarView('desks');
  }, [setRightSidebarView]);

  const handleTextChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setTextValue(e.target.value);
      if (e.target.value) {
        setError(null);
      }
    },
    []
  );

  const getLocationDetails = useMemo(
    () => (levelName?: string, locationName?: string) => {
      if (levelName && locationName) {
        return `${levelName}, ${locationName}`;
      }
      return levelName || locationName || '';
    },
    []
  );

  const handleCheckbox = useCallback(() => {
    setCheckboxValue((value: unknown) => !value);
  }, []);

  const handleFormSubmit = useCallback(async () => {
    if (!textValue.trim()) {
      setError(t('report_issue.error_message'));
      return;
    }

    try {
      if (!deskDetails) {
        return;
      }

      await reportIssueWithDesk({
        variables: {
          input: {
            deskId: deskDetails.id,
            issue: { description: textValue, isUnusable: checkboxValue },
          },
        },
      });
      onClose();
      await message.success(t('report_issue.success_message'));
    } catch (e) {
      await message.error(t('report_issue.failure_message'));
    }
  }, [onClose, checkboxValue, deskDetails, reportIssueWithDesk, textValue, t]);

  if (loading) {
    return (
      <>
        <SidebarHeader header={t('report_issue.header')} onClose={onClose} />
        <SidebarContent data-testid="skeleton">
          <Skeleton active />
        </SidebarContent>
      </>
    );
  }

  return (
    <ReportIssueContainer>
      <SidebarHeader header={t('report_issue.header')} onClose={onClose} />
      <SidebarContent>
        <ReportIssueWrapper data-testid="report-issue">
          <ResourceDetails>
            <DeskName data-testid="desk-name" level={4}>
              {deskDetails?.name}
            </DeskName>
            <DeskLocation data-testid="desk-location" type="secondary">
              {getLocationDetails(
                deskDetails?.level?.name,
                deskDetails?.location.name
              )}
            </DeskLocation>
          </ResourceDetails>
          <Details>
            <DetailsTitle aria-required="true">
              {t('report_issue.details_title')}
            </DetailsTitle>
            <DetailsTextArea
              placeholder={t('report_issue.details_placeholder')}
              onChange={handleTextChange}
              value={textValue}
              aria-invalid={!!error}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </Details>
          <Checkbox onChange={handleCheckbox}>
            {t('report_issue.checkbox_label')}
          </Checkbox>
        </ReportIssueWrapper>
      </SidebarContent>
      <SidebarFooter
        actions={{
          primary: {
            label: t('report_issue.send_report'),
            onClick: handleFormSubmit,
            disabled: reportIssueWithDeskRequest.loading || !textValue,
          },
          secondary: {
            label: t('report_issue.close'),
            onClick: onClose,
            disabled: reportIssueWithDeskRequest.loading,
          },
        }}
      />
    </ReportIssueContainer>
  );
};

const ReportIssueContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ReportIssueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--Space-Margin-marginLG, 24px);
  overflow-y: auto;
`;

const ResourceDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--Space-Margin-marginXXS, 4px);
`;

const DetailsTitle = styled(Text)`
  && {
    margin: 0;
  }
`;

const DeskName = styled(Title)`
  && {
    margin: 0;
  }
`;

const DeskLocation = styled(Text)`
  && {
    margin: 0;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--Space-Margin-marginXS, 8px);
`;

const DetailsTextArea = styled(TextArea)`
  &[aria-invalid='true'] {
    border-color: red;
  }
`;

const ErrorMessage = styled(Text)`
  color: red;
  margin-top: 8px;
`;
