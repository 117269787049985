import { DatePickerType } from 'atoms/resource/types';
import { Moment } from 'moment';
import { checkIsExhaustive, createDateRange } from 'utils';

export const updatedDatesForPickerType = (
  pickerType: DatePickerType,
  selectedDates: Moment[] | null
): Moment[] | undefined => {
  if (!selectedDates) {
    return;
  }

  switch (pickerType) {
    case 'single':
      return [selectedDates[0]];

    case 'range': {
      if (selectedDates.length > 1) {
        const first = selectedDates[0];
        const last = selectedDates[selectedDates.length - 1];

        return createDateRange(first, last);
      }
      return;
    }

    case 'multi':
      // Nothing special needs to happen when changing to multi picker
      return;

    default:
      checkIsExhaustive(pickerType);
      return;
  }
};
