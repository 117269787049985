import {
  createContext,
  useContext,
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { gql, useQuery } from '@apollo/client';
import { useSelectedLevel } from 'atoms/resource';
import { DeskFilterQuery, DeskFilterQueryVariables } from 'generated';
import {
  useSetFilteredDeskIds,
  useSetDeskFilterInitialized,
} from 'atoms/mapInteractions';

const DESK_FILTER_QUERY = gql`
  query deskFilter($levelId: ID!, $filters: [DeskFilters!]) {
    getLevelById(id: $levelId) {
      desks(filters: $filters) {
        id
      }
    }
  }
`;

type DeskFilterContextValue = {
  setDeskIdsThatMatchAmenityCriteria: Dispatch<SetStateAction<string[]>>;
};

const DeskFilterContext = createContext<DeskFilterContextValue>({
  setDeskIdsThatMatchAmenityCriteria: () => {
    throw new Error('Not Implemented');
  },
});

export const DeskFilterContextProvider: FC = ({ children }) => {
  const [levelId] = useSelectedLevel();
  const setFilteredDeskIds = useSetFilteredDeskIds();
  const setDeskFilterInitialized = useSetDeskFilterInitialized();

  const { data, loading } = useQuery<DeskFilterQuery, DeskFilterQueryVariables>(
    DESK_FILTER_QUERY,
    {
      skip: !levelId,
      variables: {
        levelId: levelId || '',
      },
    }
  );

  const [deskIdsThatMatchAmenityCriteria, setDeskIdsThatMatchAmenityCriteria] =
    useState<string[]>([]);

  useEffect(() => {
    setDeskFilterInitialized(!loading);
  }, [setDeskFilterInitialized, loading]);

  useEffect(() => {
    setDeskIdsThatMatchAmenityCriteria(
      data?.getLevelById?.desks?.map((desk) => desk.id) || []
    );
  }, [data?.getLevelById?.desks]);

  useEffect(() => {
    // For now there is only a single desk filter
    setFilteredDeskIds(deskIdsThatMatchAmenityCriteria);
  }, [setFilteredDeskIds, deskIdsThatMatchAmenityCriteria]);

  const value: DeskFilterContextValue = {
    setDeskIdsThatMatchAmenityCriteria,
  };

  return (
    <DeskFilterContext.Provider value={value}>
      {children}
    </DeskFilterContext.Provider>
  );
};

export const useDeskFilterContext = (): DeskFilterContextValue => {
  return useContext(DeskFilterContext);
};
