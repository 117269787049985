/* eslint-disable @shopify/jsx-no-hardcoded-content */
import { useMemo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { localizeDuration } from 'utils/LocalizeDuration';
import styled from '@emotion/styled';
import { Skeleton, Typography } from '@robinpowered/ui-kit';
import { useDeskReservationPolicies } from './graphql/useDeskReservationPolicies';
import {
  useGetDeskEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useTimezone,
} from 'atoms/resource';
import { SHORT_TIME_FORMAT } from 'constants/timeFormat';
import { LocationDateTimeMoment, momentToLocationDateTime } from 'utils';
import { useAuthContext } from 'contexts';
import {
  DeskReservationForDeskPolicyCard,
  useDeskReservationsByUserId,
} from './graphql/useDeskReservationsByUserId';

const { Title, Text } = Typography;
type WindowBounds = 'before' | 'after';
export function DeskBookingPolicies({
  deskId,
}: {
  deskId: string | null | undefined;
}): JSX.Element | null {
  const { t } = useTranslation('resourceDetails');
  const startTimes = useGetStartTimesForSelectedDates();
  const endTimes = useGetDeskEndTimesForSelectedDates();
  const { currentUser } = useAuthContext();
  const [usersDeskReservation, setUsersDeskReservation] = useState<
    DeskReservationForDeskPolicyCard | undefined
  >(undefined);

  const { reservations } = useDeskReservationsByUserId(
    startTimes?.[0],
    endTimes?.[0],
    currentUser?.id
  );

  const { deskDetails, loading } = useDeskReservationPolicies(deskId);
  const policies = deskDetails?.reservationPolicies;

  useEffect(() => {
    if (reservations) {
      const foundIndex = reservations
        .map((x) => x.seat.id)
        .findIndex((x) => x === deskId);
      if (foundIndex >= 0 && reservations) {
        setUsersDeskReservation(reservations[foundIndex]);
      } else {
        setUsersDeskReservation(undefined);
      }
    }
  }, [deskId, reservations, currentUser, setUsersDeskReservation]);

  const isMultiDaySelection = useMemo(() => {
    return startTimes ? startTimes.length > 1 : false;
  }, [startTimes]);

  const advanceBookingThreshold = useMemo(() => {
    return policies?.seatReservationAdvancedBookingThreshold
      ? localizeDuration(policies.seatReservationAdvancedBookingThreshold)
      : null;
  }, [policies?.seatReservationAdvancedBookingThreshold]);

  const healthCheckpointRequired = useMemo(
    () => deskDetails?.location.isHealthCheckpointRequired,
    [deskDetails?.location.isHealthCheckpointRequired]
  );

  const checkInWindow = useMemo(
    () => policies?.checkinPolicies?.checkInWindow,
    [policies?.checkinPolicies?.checkInWindow]
  );

  const abandonedDeskProtectionEnabled = useMemo(
    () => policies?.abandonedDeskProtectionEnabled,
    [policies?.abandonedDeskProtectionEnabled]
  );

  const abandonedDeskProtectionThreshold = useMemo(() => {
    return abandonedDeskProtectionEnabled
      ? policies?.abandonedDeskProtectionThreshold
      : null;
  }, [
    abandonedDeskProtectionEnabled,
    policies?.abandonedDeskProtectionThreshold,
  ]);

  const enforceLocalCheckinOnly = useMemo(
    () => policies?.checkinPolicies?.enforceLocalCheckinOnly,
    [policies?.checkinPolicies?.enforceLocalCheckinOnly]
  );

  const hasDeskPolicies = useMemo(
    () =>
      Boolean(
        advanceBookingThreshold ||
          healthCheckpointRequired ||
          checkInWindow ||
          abandonedDeskProtectionThreshold ||
          enforceLocalCheckinOnly
      ),
    [
      advanceBookingThreshold,
      healthCheckpointRequired,
      checkInWindow,
      abandonedDeskProtectionThreshold,
      enforceLocalCheckinOnly,
    ]
  );

  if (loading) return <Skeleton active />;
  if (!hasDeskPolicies) return null;

  return (
    <DeskBookingPoliciesContainer>
      <DeskBookingPoliciesHeader>
        <DeskBookingPoliciesSubHeader level={5}>
          {t('policies')}
        </DeskBookingPoliciesSubHeader>
        <DeskBookingPoliciesHeaderContent type="secondary">
          Policies are set by company admins
        </DeskBookingPoliciesHeaderContent>
      </DeskBookingPoliciesHeader>
      <DeskBookingPoliciesList>
        {advanceBookingThreshold && (
          <DeskBookingPolicy type="secondary">
            {t('desk_reservation_policies.booking_threshold', {
              threshold: advanceBookingThreshold,
            })}
          </DeskBookingPolicy>
        )}
        {healthCheckpointRequired && (
          <DeskBookingPolicy type="secondary">
            {t('desk_reservation_policies.health_checkpoint')}
          </DeskBookingPolicy>
        )}
        <CheckInAndAbandonProtectionPolicies
          checkInWindow={checkInWindow}
          abandonedDeskProtectionThreshold={abandonedDeskProtectionThreshold}
          usersDeskReservation={usersDeskReservation}
          isMultiDaySelection={isMultiDaySelection}
        ></CheckInAndAbandonProtectionPolicies>
        {enforceLocalCheckinOnly && (
          <DeskBookingPolicy type="secondary">
            {t('desk_reservation_policies.local_check_in')}
          </DeskBookingPolicy>
        )}
      </DeskBookingPoliciesList>
    </DeskBookingPoliciesContainer>
  );
}

const CheckInAndAbandonProtectionPolicies = ({
  checkInWindow,
  abandonedDeskProtectionThreshold,
  usersDeskReservation,
  isMultiDaySelection,
}: {
  checkInWindow?: string | null | undefined;
  abandonedDeskProtectionThreshold?: string | null | undefined;
  usersDeskReservation?: DeskReservationForDeskPolicyCard;
  isMultiDaySelection: boolean;
}) => {
  const { t } = useTranslation('resourceDetails');
  const { timezone } = useTimezone();

  const calculateWindowByDate = useCallback(
    (
      duration: string,
      dateTime: LocationDateTimeMoment,
      bound: WindowBounds
    ): string => {
      const durationMoment = moment.duration(duration);

      const date = dateTime.clone();
      if (bound === 'before') {
        return date.add(durationMoment).format(SHORT_TIME_FORMAT);
      }

      return date.subtract(durationMoment).format(SHORT_TIME_FORMAT);
    },
    []
  );

  const showCalculatedTimeWindows =
    usersDeskReservation && !isMultiDaySelection;

  const getCheckInWindow = useCallback((duration: string): string => {
    const durationMoment = moment.duration(duration);
    const hours = durationMoment.hours();
    const minutes = durationMoment.minutes();
    const hourString = hours > 0 ? `${hours} hours` : '';
    const minuteString = minutes > 0 ? `${minutes} minutes` : '';
    return `${hourString}${hourString && ' '}${minuteString}`;
  }, []);

  const checkInEndTime = useMemo(() => {
    if (!abandonedDeskProtectionThreshold) {
      return null;
    }
    return showCalculatedTimeWindows
      ? calculateWindowByDate(
          abandonedDeskProtectionThreshold,
          momentToLocationDateTime(
            moment(usersDeskReservation.startTime),
            timezone
          ),
          'before'
        )
      : getCheckInWindow(abandonedDeskProtectionThreshold);
  }, [
    abandonedDeskProtectionThreshold,
    usersDeskReservation,
    getCheckInWindow,
    calculateWindowByDate,
    timezone,
    showCalculatedTimeWindows,
  ]);

  const checkInStartTime = useMemo(() => {
    if (!checkInWindow) {
      return null;
    }
    return showCalculatedTimeWindows
      ? calculateWindowByDate(
          checkInWindow,
          momentToLocationDateTime(
            moment(usersDeskReservation.startTime),
            timezone
          ),
          'after'
        )
      : getCheckInWindow(checkInWindow);
  }, [
    checkInWindow,
    usersDeskReservation,
    getCheckInWindow,
    timezone,
    calculateWindowByDate,
    showCalculatedTimeWindows,
  ]);

  if (checkInWindow && abandonedDeskProtectionThreshold) {
    if (showCalculatedTimeWindows) {
      return (
        <DeskBookingPolicy type="secondary">
          {t('desk_reservation_policies.check_in_window', {
            before: checkInEndTime,
            after: checkInStartTime,
          })}
        </DeskBookingPolicy>
      );
    } else {
      return (
        <>
          <DeskBookingPolicy type="secondary">
            {t('desk_reservation_policies.check_in_after', {
              time: checkInStartTime,
            })}
          </DeskBookingPolicy>
          <DeskBookingPolicy type="secondary">
            {t('desk_reservation_policies.check_in_before', {
              time: checkInEndTime,
            })}
          </DeskBookingPolicy>
        </>
      );
    }
  }

  if (checkInStartTime) {
    return (
      <DeskBookingPolicy type="secondary">
        {t(
          showCalculatedTimeWindows
            ? 'desk_reservation_policies.reserved_check_in_after'
            : 'desk_reservation_policies.check_in_after',
          { time: checkInStartTime }
        )}
      </DeskBookingPolicy>
    );
  }

  if (checkInEndTime) {
    return (
      <DeskBookingPolicy type="secondary">
        {t(
          showCalculatedTimeWindows
            ? 'desk_reservation_policies.reserved_check_in_before'
            : 'desk_reservation_policies.check_in_before',
          { time: checkInEndTime }
        )}
      </DeskBookingPolicy>
    );
  }
  return null;
};

const DeskBookingPoliciesContainer = styled.div`
  display: flex;
  width: 276px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const DeskBookingPoliciesHeader = styled.div`
  display: flex;
  width: 276px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const DeskBookingPoliciesSubHeader = styled(Title)`
  && {
    margin: 0;
  }
`;

const DeskBookingPoliciesHeaderContent = styled(Text)`
  && {
    margin: 0;
  }
`;

const DeskBookingPoliciesList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  list-style-type: disc;
  padding-left: 1.5em;
`;

const DeskBookingPolicy = styled(Text)`
  && {
    display: list-item;
    margin: 0;
  }
`;
