import { message } from '@robinpowered/ui-kit';
import { useEffect } from 'react';

export const MessageConfig = () => {
  useEffect(() => {
    message.config({
      top: 150,
    });
  }, []);

  return null;
};
