import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useApolloContext } from 'contexts';
import {
  DeskReservationPoliciesQuery,
  DeskReservationPoliciesQueryVariables,
} from 'generated';

const DESK_RESERVATION_POLICIES = gql`
  query DeskReservationPolicies($deskId: ID!) {
    getDeskById(id: $deskId) {
      id
      location {
        isHealthCheckpointRequired
      }
      reservationPolicies {
        checkinPolicies {
          enforceLocalCheckinOnly
          checkInWindow
          assignedCheckInEnabled
        }
        seatReservationTypesAllowed
        allowExclusions
        seatReservationAdvancedBookingThreshold
        seatReservationMaxLength
        allowOverlappingReservations
        seatReservationVisibilityEnabled
        abandonedDeskProtectionThreshold
        abandonedDeskProtectionEnabled
      }
    }
  }
`;

export const useDeskReservationPolicies = (
  deskId: string | null | undefined
) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    DeskReservationPoliciesQuery,
    DeskReservationPoliciesQueryVariables
  >(DESK_RESERVATION_POLICIES, {
    skip: !tenantId || !deskId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      deskId: deskId || '',
    },
  });

  return {
    loading,
    deskDetails: data?.getDeskById,
  };
};
