import { ChevronLeftOutline } from '@robinpowered/icons';
import { SidebarContent, SidebarHeader } from 'components/global/sidebar';
import { DeskList } from '../desk-list/DeskList';
import { Button } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { FC, useCallback, useEffect } from 'react';
import { useEditDeskNewDeskId } from 'atoms/editDesk';
import { useTranslation } from 'react-i18next';
import { useCurrentlySelectedResource } from 'atoms/resource';
import { useBackDestinationContext } from './contexts';
import { useSetDeskSidebarView } from 'atoms/sidebar/hooks';

export const EditDeskListView: FC = () => {
  const { setBackDestination } = useBackDestinationContext();

  const { t } = useTranslation('resourceDetails');

  const { id: selectedResourceId } = useCurrentlySelectedResource() || {};
  const newDeskId = useEditDeskNewDeskId();

  const setView = useSetDeskSidebarView();

  const onClose = useCallback(() => {
    setView('edit-summary');
  }, [setView]);

  useEffect(() => {
    setBackDestination('edit-desk-list');
  }, [setBackDestination]);

  return (
    <>
      <SidebarHeader
        prefix={
          <BackButton type="link" onClick={onClose} data-testid="back-button">
            <ChevronLeftOutline size={16} />
          </BackButton>
        }
        header={t('desk_list.header')}
        onClose={onClose}
      />

      <SidebarContent>
        <DeskList selectedDeskId={newDeskId ?? selectedResourceId} />
      </SidebarContent>
    </>
  );
};

const BackButton = styled(Button)`
  padding: 0;
`;
