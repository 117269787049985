import moment from 'moment';
import { i18n } from 'i18n';

// Returns the quantity and time type for a given duration
// This only works for a sinle time type - either minutes, hours, days, weeks or months
// ie. returns "100 days" or "12 hours" or "45 minutes" - can't return "13 hours, 30 minutes"
export function localizeDuration(duration: string): string {
  const durationMoment = moment.duration(duration);

  if (duration.includes('T')) {
    if (duration.includes('M')) {
      return i18n.t('time:time_options.minutes', {
        count: durationMoment.asMinutes(),
      });
    } else {
      return i18n.t('time:time_options.hours', {
        count: durationMoment.asHours(),
      });
    }
  } else if (duration.includes('D')) {
    return i18n.t('time:time_options.days', { count: durationMoment.asDays() });
  } else if (duration.includes('W')) {
    return i18n.t('time:time_options.weeks', {
      count: durationMoment.asWeeks(),
    });
  }
  return i18n.t('time:time_options.months', {
    count: durationMoment.asMonths(),
  });
}
