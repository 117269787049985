import { gql } from '@apollo/client';
import {
  DeskBookingControlsQuery,
  DeskBookingControlsQueryVariables,
} from 'generated';
import { useQueryCachedLoad } from '../../../../../hooks';

export type DeskBookingControls = DeskBookingControlsQuery;

export type DeskSetting = NonNullable<
  NonNullable<DeskBookingControlsQuery['getDeskById']>['settings']
>[number];

export const DESK_BOOKING_CONTROLS = gql`
  query DeskBookingControls($deskId: ID!) {
    getDeskById(id: $deskId) {
      id
      name
      type
      settings {
        slug
        value
      }
    }
  }
`;

export const useDeskBookingControlsData = (
  selectedResourceId: string | undefined
) => {
  const { loading, data } = useQueryCachedLoad<
    DeskBookingControlsQuery,
    DeskBookingControlsQueryVariables
  >(
    DESK_BOOKING_CONTROLS,
    {
      skip: !selectedResourceId,
      variables: {
        deskId: selectedResourceId || '',
      },
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
    },
    ['deskId']
  );

  return {
    loading,
    data,
  };
};
