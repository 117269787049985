import { refineFilterType } from 'atoms/resource/refine';
import { FilterType, InteractiveLayer, MapMode } from 'atoms/resource/types';
import { writeWithReset } from 'atoms/utils';
import { atom } from 'recoil';
import { syncEffect } from 'recoil-sync';

export const AtomKey = {
  mapMode: 'mapModeVersion',
  mapInteractiveLayers: 'mapInteractiveLayers',
  isCameraResetting: 'isCameraResetting',
  currentFilter: 'currentFilter',
  filteredSpaceIds: 'filteredSpaceIds',
  spaceFilterInitialized: 'spaceFilterInitialized',
  filteredDeskIds: 'filteredDeskIds',
  deskFilterInitialized: 'deskFilterInitialized',
};

export const mapInteractiveLayers = atom<Set<InteractiveLayer>>({
  key: AtomKey.mapInteractiveLayers,
  default: new Set(['seats', 'spaces', 'space_labels']),
});

export const mapMode = atom<MapMode>({
  key: AtomKey.mapMode,
  default: 'browse',
});

export const isCameraResetting = atom<boolean>({
  key: AtomKey.isCameraResetting,
  default: false,
});

export const currentFilter = atom<FilterType | null>({
  key: AtomKey.currentFilter,
  default: null,
  effects: [
    syncEffect({
      itemKey: 'currentFilter',
      refine: refineFilterType(),
      write: writeWithReset('currentFilter'),
    }),
  ],
});

export const filteredSpaceIds = atom<string[]>({
  key: AtomKey.filteredSpaceIds,
  default: [],
});

export const spaceFilterInitialized = atom<boolean>({
  key: AtomKey.spaceFilterInitialized,
  default: false,
});

export const filteredDeskIds = atom<string[]>({
  key: AtomKey.filteredDeskIds,
  default: [],
});

export const deskFilterInitialized = atom<boolean>({
  key: AtomKey.deskFilterInitialized,
  default: false,
});
