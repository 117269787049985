import React, { createContext, ReactNode, useState } from 'react';

interface BookingControlsContextType {
  isBookingControlsSticky: boolean | null;
  setIsBookingControlsSticky: React.Dispatch<
    React.SetStateAction<boolean | null>
  >;
}

export const BookingControlsContext = createContext<
  BookingControlsContextType | undefined
>(undefined);

export const BookingControlsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isBookingControlsSticky, setIsBookingControlsSticky] = useState<
    boolean | null
  >(null);

  return (
    <BookingControlsContext.Provider
      value={{ isBookingControlsSticky, setIsBookingControlsSticky }}
    >
      {children}
    </BookingControlsContext.Provider>
  );
};

export const useBookingControlsContext = () => {
  const context = React.useContext(BookingControlsContext);
  if (context === undefined) {
    throw new Error('No booking controls context found');
  }
  return context;
};
