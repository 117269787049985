import { TFunction } from 'i18next';
import difference from 'lodash/difference';
import { type SelectOption } from '../../../types';
import { type Desks } from '../AmenitiesFilter';

export const amenitiesToSelectOptions = (
  desks: Desks | undefined,
  t: TFunction
): SelectOption[] => {
  if (!desks) {
    return [];
  }

  const amenityOptions = mapAmenitiesToOptions(desks);
  const filteredOptions = filterOutDuplicatesByValue(amenityOptions);
  const translatedOptions = translateOptionLabel(filteredOptions, t);

  return sortByLabelAlphabetically(translatedOptions);
};

const mapAmenitiesToOptions = (desks: Desks): SelectOption[] => {
  return (
    desks
      .flatMap((space) => space.amenities)
      .map((amenity) => ({
        label: amenity.name,
        value: amenity.amenityId || '',
      })) || []
  );
};

const filterOutDuplicatesByValue = (
  amenityOptions: SelectOption[]
): SelectOption[] => {
  return amenityOptions.filter(
    (amenity, index, allAmenities) =>
      index === allAmenities.findIndex((a) => a.value === amenity.value)
  );
};

const translateOptionLabel = (
  filteredOptions: SelectOption[],
  t: TFunction
): SelectOption[] => {
  return filteredOptions?.map((option) => {
    const key = `COMMON.AMENITIES.DEFAULTS.${option.value}`;
    const translated = t(key);
    const label = translated !== key ? translated : option.label;

    return {
      ...option,
      label,
    };
  });
};

const sortByLabelAlphabetically = (
  translatedOptions: SelectOption[]
): SelectOption[] => {
  return translatedOptions.sort((optionA, optionB) => {
    return optionA.label.localeCompare(optionB.label);
  });
};

export const getDeskIdsThatHaveAllSelectedAmenities = (
  desks: Desks | undefined,
  selectedAmenityIds: number[]
): string[] => {
  if (!desks) {
    return [];
  }

  return (
    desks
      .filter((space) => {
        const spaceAmenityIds = space.amenities
          .map((amenity) => amenity.amenityId)
          .filter((id) => !!id) as number[];

        return difference(selectedAmenityIds, spaceAmenityIds).length === 0;
      })
      .map((space) => space.id) || []
  );
};
