import { gql, useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  DeskType,
  GetSharedDesksScheduleByDeskIdQuery,
  GetSharedDesksScheduleByDeskIdQueryVariables,
} from 'generated';

export type SharedDeskSchedules =
  GetSharedDesksScheduleByDeskIdQuery['getSharedDesksSchedules'];

export const GET_SHARED_DESK_SCHEDULE = gql`
  query GetSharedDesksScheduleByDeskId($getSharedDeskScheduleIds: [ID!]!) {
    getSharedDesksSchedules(deskIds: $getSharedDeskScheduleIds) {
      id
      schedule {
        id
        firstName
        lastName
        email
        recurrence
        uniqueId
        avatar
      }
    }
  }
`;

export const useSharedDeskSchedule = (
  deskId: string | undefined,
  deskType: DeskType[] | undefined
) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    GetSharedDesksScheduleByDeskIdQuery,
    GetSharedDesksScheduleByDeskIdQueryVariables
  >(GET_SHARED_DESK_SCHEDULE, {
    skip:
      !deskType || !deskType.includes(DeskType.Shared) || !tenantId || !deskId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      getSharedDeskScheduleIds: deskId || '',
    },
  });

  return {
    loading,
    data: data?.getSharedDesksSchedules,
  };
};
