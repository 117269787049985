import { FC } from 'react';
import { DeskListItemBase } from './DeskListItemBase';
import { UnavailableIcon } from './icons/UnavailableIcon';
import { useTranslation } from 'react-i18next';
import { useDefaultDeskListItemClick } from './hooks/useDefaultDeskListItemClick';

export const DeskListItemUnavailable: FC<{
  deskId: string;
  deskName: string;
}> = ({ deskName, deskId }) => {
  const { t } = useTranslation('resourceDetails');

  const onClick = useDefaultDeskListItemClick(deskId);

  return (
    <DeskListItemBase
      title={deskName}
      subtitle={t('desk_list.unavailable')}
      icon={<UnavailableIcon />}
      onClick={onClick}
    />
  );
};
