import { gql, useQuery } from '@apollo/client';
import { useCurrentFilter } from 'atoms/mapInteractions';
import {
  useGetStartTimesForSelectedDates,
  useSelectedLocation,
  useTimezone,
} from 'atoms/resource';
import { useCallback, useMemo } from 'react';
import {
  calculateEndOfDay,
  findCustomHourForDate,
  getWorkingHoursForDay,
  LocationDateTimeMoment,
} from 'utils';
import { UseEndTimeQuery, UseEndTimeQueryVariables } from 'generated';
import moment from 'moment';
import { SELECTED_DATE_FORMAT } from '../constants';

const OFFICE_HOURS_QUERY = gql`
  query useEndTime($locationId: ID!) {
    getLocationById(id: $locationId) {
      id
      timezone
      workingHours {
        day
        timeFrames {
          start
          end
        }
      }
      customWorkingHours {
        id
        date
        timeFrames {
          start
          end
        }
      }
    }
  }
`;

export const useEndTime = () => {
  const currentFilter = useCurrentFilter();
  const { timezone } = useTimezone();
  const [selectedLocation] = useSelectedLocation();
  const selectedDates = useGetStartTimesForSelectedDates();

  const { data } = useQuery<UseEndTimeQuery, UseEndTimeQueryVariables>(
    OFFICE_HOURS_QUERY,
    {
      skip: currentFilter !== 'seats' || !selectedLocation,
      variables: { locationId: selectedLocation || '' },
    }
  );

  const workingHoursForFirstSelectedDate = useMemo(
    () =>
      getWorkingHoursForDay(
        data?.getLocationById?.workingHours || [],
        selectedDates && selectedDates[0]
          ? selectedDates[0].day()
          : moment.tz(timezone).day()
      ),
    [data?.getLocationById?.workingHours, selectedDates, timezone]
  );

  const customHoursForFirstSelectedDate = useMemo(
    () =>
      findCustomHourForDate(
        data?.getLocationById?.customWorkingHours || [],
        selectedDates
          ? selectedDates[0].format(SELECTED_DATE_FORMAT)
          : moment.tz(timezone).format(SELECTED_DATE_FORMAT),
        timezone
      ),
    [data?.getLocationById?.customWorkingHours, selectedDates, timezone]
  );

  const calculateEndTimeForDesks = useCallback(
    (startTime: LocationDateTimeMoment) => {
      return calculateEndOfDay(
        startTime,
        customHoursForFirstSelectedDate || workingHoursForFirstSelectedDate
      );
    },
    [customHoursForFirstSelectedDate, workingHoursForFirstSelectedDate]
  );

  return { calculateEndTimeForDesks };
};
