import { gql, useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  GetReleaseDeskExclusionsQuery,
  GetReleaseDeskExclusionsQueryVariables,
} from 'generated';
import { Moment } from 'moment';

export type ReleaseDeskExclusions =
  GetReleaseDeskExclusionsQuery['getReservationExclusions'];

export const GET_DESK_EXCLUSIONS = gql`
  query GetReleaseDeskExclusions(
    $reservationSeriesId: ID!
    $startDate: Date!
    $endDate: Date!
  ) {
    getReservationExclusions(
      reservationSeriesId: $reservationSeriesId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      startTime
      endTime
    }
  }
`;

export const useReleaseDeskExclusions = (
  reservationSeriesId: string | null | undefined,
  startDate: Moment | null,
  endDate: Moment | null
) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    GetReleaseDeskExclusionsQuery,
    GetReleaseDeskExclusionsQueryVariables
  >(GET_DESK_EXCLUSIONS, {
    context: {
      headers: {
        'cache-refresh': 'no-cache',
      },
    },
    skip: !tenantId || !reservationSeriesId || !startDate || !endDate,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      reservationSeriesId: reservationSeriesId || '',
      startDate: startDate?.format('YYYY-MM-DDTHH:mm:ss[Z]') || '',
      endDate: endDate?.format('YYYY-MM-DDTHH:mm:ss[Z]') || '',
    },
  });

  return {
    loading,
    data: data?.getReservationExclusions,
  };
};
