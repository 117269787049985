import moment from 'moment';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RRule } from 'rrule';
import { useReleaseDeskSchedule } from '../graphql';
import {
  createLocationDateTimeMoment,
  locationDateTimeMoment,
  LocationDateTimeMoment,
} from 'utils';
import { useTimezone } from 'atoms/resource';
import { SELECTED_DATE_FORMAT } from 'constants/timeFormat';
import {
  ReleaseDeskExclusions,
  useReleaseDeskExclusions,
} from '../graphql/useReleaseDeskExclusions';

export type DayStatus = 'in-use' | 'assigned-to-me' | 'released';

interface Day {
  originalStatus: DayStatus;
  currentStatus: DayStatus;
}

interface ReleaseDeskModalContextType {
  days: Map<string, Day>;
  toggleDayStatus: (date: string) => void;
  resetChanges: () => void;
  currentDate: LocationDateTimeMoment;
  setCurrentDate: React.Dispatch<React.SetStateAction<LocationDateTimeMoment>>;
  exclusions: ReleaseDeskExclusions;
  seriesId: string | null | undefined;
  setDays: React.Dispatch<React.SetStateAction<Map<string, Day>>>;
  loading: boolean;
  hasChanges: boolean;
}

interface ReleaseDeskModalProviderProps {
  seriesId: string | null | undefined;
  deskId: string | undefined;
  children: ReactNode;
}

export const ReleaseDeskModalContext = createContext<
  ReleaseDeskModalContextType | undefined
>(undefined);

export function loadDaysFromServer(
  recurrence: string,
  month: number,
  year: number,
  timezone: string,
  today: LocationDateTimeMoment,
  threeMonthsFromToday: LocationDateTimeMoment,
  exclusions: ReleaseDeskExclusions | undefined
): Map<string, Day> {
  const rruleString = recurrence.replace('RRULE:', '');
  const rruleOptions = RRule.parseString(rruleString);

  const dtstart = createLocationDateTimeMoment(timezone, [
    year,
    month,
    1,
  ]).toDate();
  rruleOptions.dtstart = dtstart;

  const rule = new RRule(rruleOptions);

  const startOfMonth = createLocationDateTimeMoment(timezone, [
    year,
    month,
    15, //Just set to the middle of the month so that timezones cant set it to the previous month
  ]).startOf('month');

  const endOfMonthDate = createLocationDateTimeMoment(timezone, [
    year,
    month,
    15,
  ]).endOf('month');
  const dates = rule.between(
    startOfMonth.toDate(),
    endOfMonthDate.toDate(),
    true
  );

  const daysMap = new Map<string, Day>();
  const daysInMonth = endOfMonthDate.date();

  for (let day = 1; day <= daysInMonth; day++) {
    const date = moment.tz([year, month, day], timezone);
    if (date.isBetween(today, threeMonthsFromToday, null, '[]')) {
      const formattedDate = date.format(SELECTED_DATE_FORMAT);
      daysMap.set(formattedDate, {
        originalStatus: 'in-use',
        currentStatus: 'in-use',
      });
    }
  }

  dates.forEach((date: moment.MomentInput) => {
    const dateMoment = moment.tz(date, timezone);
    if (dateMoment.isBetween(today, threeMonthsFromToday, null, '[]')) {
      const formattedDate = dateMoment.format(SELECTED_DATE_FORMAT);
      daysMap.set(formattedDate, {
        originalStatus: 'assigned-to-me',
        currentStatus: 'assigned-to-me',
      });
    }
  });

  if (exclusions && exclusions.length > 0) {
    exclusions.forEach((exclusion) => {
      const startMoment = moment(exclusion.startTime).startOf('day');
      if (
        startMoment.isBetween(
          today.clone().subtract(1, 'day'),
          threeMonthsFromToday,
          null,
          '[]'
        )
      ) {
        const formattedDate = startMoment.format(SELECTED_DATE_FORMAT);
        daysMap.set(formattedDate, {
          originalStatus: 'released',
          currentStatus: 'released',
        });
      }
    });
  }

  return daysMap;
}

export const ReleaseDeskModalProvider = ({
  seriesId,
  deskId,
  children,
}: ReleaseDeskModalProviderProps) => {
  const { timezone } = useTimezone();
  const [loading, setLoading] = useState(true);
  const [days, setDays] = useState<Map<string, Day>>(new Map());
  const [currentDate, setCurrentDate] = useState<LocationDateTimeMoment>(
    locationDateTimeMoment(timezone)
  );

  const [hasChanges, setHasChanges] = useState(false);

  const checkForChanges = useCallback(() => {
    setHasChanges(() => {
      for (const day of days.values()) {
        if (day.currentStatus !== day.originalStatus) {
          return true;
        }
      }
      return false;
    });
  }, [days]);

  useEffect(() => {
    checkForChanges();
  }, [days, checkForChanges]);

  const today = useMemo(() => {
    return locationDateTimeMoment(timezone).startOf('day');
  }, [timezone]);

  const threeMonthsFromToday = useMemo(() => {
    return today.clone().add(3, 'months').endOf('day');
  }, [today]);

  const { data: deskSchedule, loading: deskScheduleLoading } =
    useReleaseDeskSchedule(deskId);

  const { data: exclusions, loading: exclusionsLoading } =
    useReleaseDeskExclusions(seriesId, today, threeMonthsFromToday);

  useEffect(() => {
    if (deskScheduleLoading || exclusionsLoading) return;

    const recurrence = deskSchedule?.[0]?.schedule?.[0]?.recurrence;

    if (recurrence && currentDate) {
      const month = currentDate.month();
      const year = currentDate.year();
      const daysMap = loadDaysFromServer(
        recurrence,
        month,
        year,
        timezone,
        today,
        threeMonthsFromToday,
        exclusions
      );

      setDays((prevDays) => {
        const newDays = new Map(prevDays);
        daysMap.forEach((day, date) => {
          if (!newDays.has(date)) {
            newDays.set(date, day);
          }
        });
        return newDays;
      });

      setLoading(false);
    }
  }, [
    currentDate,
    deskSchedule,
    deskScheduleLoading,
    exclusions,
    exclusionsLoading,
    threeMonthsFromToday,
    timezone,
    today,
  ]);

  const toggleDayStatus = (date: string) => {
    setDays((prevDays) => {
      const newDays = new Map(prevDays);
      const day = newDays.get(date);

      if (day) {
        const newDay = { ...day };
        if (newDay.currentStatus === 'assigned-to-me') {
          newDay.currentStatus = 'released';
        } else if (newDay.currentStatus === 'released') {
          newDay.currentStatus = 'assigned-to-me';
        }
        newDays.set(date, newDay);
      }
      return newDays;
    });
  };

  const resetChanges = () => {
    setDays((prevDays) => {
      const newDays = new Map<string, Day>();
      prevDays.forEach((day, date) => {
        newDays.set(date, {
          originalStatus: day.originalStatus,
          currentStatus: day.originalStatus,
        });
      });
      return newDays;
    });
  };

  return (
    <ReleaseDeskModalContext.Provider
      value={{
        days,
        toggleDayStatus,
        resetChanges,
        currentDate,
        setCurrentDate,
        exclusions: exclusions || [],
        seriesId,
        setDays,
        loading,
        hasChanges,
      }}
    >
      {children}
    </ReleaseDeskModalContext.Provider>
  );
};

export const useReleaseDeskModalContext = () => {
  const context = React.useContext(ReleaseDeskModalContext);
  if (context === undefined) {
    throw new Error('No release desk context found');
  }
  return context;
};
