import styled from '@emotion/styled';
import { Button } from '@robinpowered/ui-kit';

interface AvailabilityPillProps {
  colorSuccessText: string;
  colorSuccessBorder: string;
  colorSuccessTextHover: string;
  colorSuccessBorderHover: string;
  colorSuccessTextActive: string;
  colorSuccessActive: string;
  colorTextDisabled: string;
  colorBgContainerDisabled: string;
  colorBgBase: string;
}

export const AvailabilityPill = styled(Button)<AvailabilityPillProps>`
  color: ${({ colorSuccessText }) => colorSuccessText};
  border-color: ${({ colorSuccessBorder }) => colorSuccessBorder};

  &:hover {
    color: ${({ colorSuccessTextHover }) => colorSuccessTextHover} !important;
    border-color: ${({ colorSuccessBorderHover }) =>
      colorSuccessBorderHover} !important;
  }

  &:focus {
    color: ${({ colorSuccessText }) => colorSuccessText} !important;
    border-color: ${({ colorBgBase }) => colorBgBase} !important;
  }

  &:active {
    color: ${({ colorSuccessTextActive }) => colorSuccessTextActive} !important;
    border-color: ${({ colorSuccessActive }) => colorSuccessActive} !important;
  }

  &:disabled {
    color: ${({ colorTextDisabled }) => colorTextDisabled} !important;
    border-color: ${({ colorBgContainerDisabled }) =>
      colorBgContainerDisabled} !important;
  }
`;
