import { CancelReservationSingleModal } from './single';
import { CancelReservationSeriesModal } from './series';
import { FC } from 'react';
import { CancelReservationModalProps, isSeriesModalProps } from './types';

export const CancelReservationModal: FC<CancelReservationModalProps> = (
  props
) => {
  return isSeriesModalProps(props) ? (
    <CancelReservationSeriesModal {...props} />
  ) : (
    <CancelReservationSingleModal {...props} />
  );
};
