import { useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  DeskReservationReserveeQuery,
  DeskReservationReserveeQueryVariables,
} from 'generated';
import gql from 'graphql-tag';
import { Moment } from 'moment';

export type DeskReservationReservee =
  DeskReservationReserveeQuery['getDeskReservationsBySeatId'];
export type Reservation = DeskReservationReservee[number];

const DESK_RESERVATION_RESERVEE = gql`
  query DeskReservationReservee(
    $deskId: ID!
    $startTime: ISODateTime!
    $duration: Int!
  ) {
    getDeskReservationsBySeatId(
      deskId: $deskId
      startTime: $startTime
      duration: $duration
    ) {
      id
      visibility
      reservee {
        user {
          id
        }
      }
    }
  }
`;

export const useDeskReservationReservee = (
  deskId: string | undefined,
  duration: number,
  startTime?: Moment
) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    DeskReservationReserveeQuery,
    DeskReservationReserveeQueryVariables
  >(DESK_RESERVATION_RESERVEE, {
    skip: !tenantId || !deskId || !startTime,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      deskId: deskId || '',
      startTime: startTime?.toISOString(),
      duration,
    },
  });

  const reservations = data?.getDeskReservationsBySeatId ?? [];

  return {
    loading,
    reservations,
  };
};
