import { CheckSuccess } from '@recoiljs/refine';
import {
  DatePickerType,
  FilterType,
  SelectedResource,
  isDatePickerType,
  isFilterType,
  isResource,
} from './types';
import { IsoString, momentToIsoString } from 'utils';
import moment from 'moment';

const success = <T>(value: T): CheckSuccess<T> => {
  return {
    type: 'success',
    value,
    warnings: [],
  };
};

export const refineSelectedResource = () => {
  const selectedResource = (
    data: unknown
  ): CheckSuccess<SelectedResource | null> => {
    return success<SelectedResource | null>(isResource(data) ? data : null);
  };
  return selectedResource;
};

export const refineNumberToString = () => {
  const refinedString = (data: unknown): CheckSuccess<string | null> => {
    return success<string | null>(
      typeof data === 'number' && !isNaN(data) ? data.toString() : null
    );
  };
  return refinedString;
};

export const refineDatePickerType = (
  defaultVal = 'single' as DatePickerType
) => {
  const refinedString = (data: unknown): CheckSuccess<DatePickerType> => {
    return success(isDatePickerType(data) ? data : defaultVal);
  };
  return refinedString;
};

export const refineIsoStringArray = (defaultVal: IsoString[]) => {
  const refinedSelectedDates = (data: unknown): CheckSuccess<IsoString[]> => {
    return success(
      Array.isArray(data) && data.every((x) => moment(x).isValid())
        ? data.map((item) => momentToIsoString(moment(item)))
        : defaultVal
    );
  };
  return refinedSelectedDates;
};

export const refineIsoString = () => {
  const refinedSelectedDates = (
    data: unknown
  ): CheckSuccess<IsoString | null> => {
    return success<IsoString | null>(
      typeof data === 'string' && moment(data).isValid()
        ? momentToIsoString(moment(data))
        : null
    );
  };
  return refinedSelectedDates;
};

export const refineFilterType = () => {
  const refinedString = (data: unknown): CheckSuccess<FilterType | null> => {
    return success<FilterType | null>(isFilterType(data) ? data : null);
  };
  return refinedString;
};
