import { CancelOptions } from '../types';
import { useTranslation } from 'react-i18next';
import { Z_INDEX } from 'constants/zIndex';
import { Checkbox, Tooltip } from '@robinpowered/ui-kit';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

export const CancelReservationCancelVisits = ({
  selectedOption,
  reservationInProgress,
  shouldCancelVisits,
  handleCheckboxClick,
}: {
  selectedOption: CancelOptions;
  reservationInProgress: boolean;
  shouldCancelVisits: boolean;
  handleCheckboxClick: (e: CheckboxChangeEvent) => void;
}) => {
  const { t } = useTranslation('resourceDetails');

  return (
    <Tooltip
      title={
        reservationInProgress && selectedOption === 'single'
          ? t('cancel_modal.option_disabled')
          : ''
      }
      zIndex={Z_INDEX.CANCEL_RESERVATION_MODAL_TOOLTIPS}
    >
      <Checkbox
        checked={shouldCancelVisits}
        onChange={handleCheckboxClick}
        disabled={reservationInProgress && selectedOption === 'single'}
        data-testid="cancel-visits-checkbox"
      >
        {t('cancel_modal.update_to_remote')}
      </Checkbox>
    </Tooltip>
  );
};
