import {
  useGetSpaceEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
} from 'atoms/resource';
import { useMemo } from 'react';
import { calculateMinimumEndTime } from 'utils';

export const useIsUnmodifiedSpaceEndTime = () => {
  const endTimes = useGetSpaceEndTimesForSelectedDates();
  const startTimes = useGetStartTimesForSelectedDates();

  return useMemo(() => {
    return startTimes?.[0] && endTimes?.[0]
      ? calculateMinimumEndTime(startTimes[0]).isSame(endTimes[0], 'minute')
      : false;
  }, [startTimes, endTimes]);
};
