import { DatePickerComponent } from 'components/global/controls';
import { DeskUnbookableReasonType } from 'generated';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ViolationMessage } from './ViolationMessage';
import moment from 'moment';
import { DeskResourceDetails, DeskUnbookableReasons } from '../graphql';
import { useDatePickerType } from 'atoms/resource';
import { DatePickerType } from 'atoms/resource/types';

export const BookDeskDatePicker: FC<{
  conflictingReservations:
    | DeskResourceDetails['state']['reservations']
    | undefined;
  unbookableReasons: DeskUnbookableReasons | undefined;
}> = ({ conflictingReservations, unbookableReasons }) => {
  const { t } = useTranslation('resourceDetails');
  const bookingType: DatePickerType = useDatePickerType();

  const dateViolation = useMemo(() => {
    const violationReason = unbookableReasons?.find(
      (r) =>
        r?.reason === DeskUnbookableReasonType.ViolateHotDeskPolicy ||
        r?.reason === DeskUnbookableReasonType.SeatReservationMaxLength ||
        r?.reason ===
          DeskUnbookableReasonType.SeatReservationAdvancedBookingThreshold
    );

    switch (violationReason?.reason) {
      case DeskUnbookableReasonType.ViolateHotDeskPolicy:
        return t('desk_booking_controls.violations.hot_desk');

      case DeskUnbookableReasonType.SeatReservationMaxLength:
        return t('desk_booking_controls.violations.too_long', {
          duration: moment.duration(violationReason.value).humanize(),
        });

      case DeskUnbookableReasonType.SeatReservationAdvancedBookingThreshold:
        return t('desk_booking_controls.violations.too_far_in_advance', {
          duration: moment.duration(violationReason.value).humanize(),
        });

      default:
        return undefined;
    }
  }, [t, unbookableReasons]);

  const hasConflictingReservations = useMemo(() => {
    return (
      bookingType !== 'single' &&
      conflictingReservations &&
      conflictingReservations.length > 0
    );
  }, [bookingType, conflictingReservations]);

  return (
    <>
      <DatePickerComponent
        status={
          dateViolation || hasConflictingReservations ? 'error' : undefined
        }
      />

      {hasConflictingReservations && conflictingReservations?.[0] ? (
        <ViolationMessage
          message={t(
            'desk_booking_controls.violations.resource_unavailable_on',
            {
              date: moment(conflictingReservations[0].startTime).format(
                'MM-DD'
              ),
            }
          )}
        />
      ) : (
        dateViolation && <ViolationMessage message={dateViolation} />
      )}
    </>
  );
};
