import { DeskAltSolid } from '@robinpowered/icons';
import { FilterTag } from '../FilterTag';
import { useMemo } from 'react';
import { useCurrentFilter } from 'atoms/mapInteractions';
import { useFilterTagClick } from '../useFilterTagClick';
import { theme } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { DeskSubFilters } from './sub-filters';
import { InteractiveLayer } from 'atoms/resource/types';

export const DeskFilter = () => {
  const { t } = useTranslation('filters');
  const { useToken } = theme;
  const { token } = useToken();

  const currentFilter = useCurrentFilter();

  const isDisplayed = useMemo(
    () => !currentFilter || currentFilter === 'seats',
    [currentFilter]
  );

  const isActive = useMemo(() => currentFilter === 'seats', [currentFilter]);

  const activeLayers: Set<InteractiveLayer> = useMemo(
    () => new Set(['seats']),
    []
  );

  const { handleClick } = useFilterTagClick({
    type: 'seats',
    active: isActive,
    activeLayers,
  });

  if (!isDisplayed) {
    return null;
  }

  return (
    <>
      <FilterTag
        title={t('DESK_FILTERS.TAG_LABEL')}
        active={isActive}
        onClick={handleClick}
        icon={
          <DeskAltSolid
            color={isActive ? token.colorBgContainer : token.colorBgSpotlight}
            size={16}
          />
        }
        data-testid="desk-filter"
      />

      {isActive && <DeskSubFilters />}
    </>
  );
};
