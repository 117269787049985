import styled from '@emotion/styled';
import { CancelReservationDeskDetails } from './CancelReservationDeskDetails';
import { CalendarOutline } from '@robinpowered/icons';

export const CancelReservationDetailsSection = ({
  reservationUserName,
  deskName,
  floorName,
  buildingName,
  children,
}: {
  reservationUserName: string | null | undefined;
  deskName: string;
  floorName: string | undefined;
  buildingName: string;
  children: React.ReactNode;
}) => {
  return (
    <DetailsSection>
      <DateSection>
        <CalendarIcon size={16} />
        {children}
      </DateSection>
      <CancelReservationDeskDetails
        reservationUserName={reservationUserName}
        deskName={deskName}
        floorName={floorName}
        buildingName={buildingName}
      />
    </DetailsSection>
  );
};

const DateSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CalendarIcon = styled(CalendarOutline)`
  margin-top: 2px;
`;
