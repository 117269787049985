import styled from '@emotion/styled';
import { FC, useCallback, useMemo, useState } from 'react';
import { Button, Skeleton, Typography } from '@robinpowered/ui-kit';
import { CalendarSolid } from '@robinpowered/icons';
import { SHORT_DATE_FORMAT } from 'constants/timeFormat';
import { isConsecutiveList } from 'utils';
import { useTranslation } from 'react-i18next';
import { useSeriesStartTimes } from '../graphql';
import moment from 'moment';

const NUM_INITIAL_VISIBLE_DATES = 3;

export const ReservationDate: FC<{
  seriesId: string | null | undefined;
  startTime: string;
  timezone: string;
}> = ({ seriesId, startTime, timezone }) => {
  const { t } = useTranslation('resourceDetails');

  const [remainingDatesVisible, setRemainingDatesVisible] =
    useState<boolean>(false);

  const { startTimes, loading } = useSeriesStartTimes(seriesId);

  const dates = useMemo(
    () =>
      [...new Set<string>([startTime, ...(startTimes || [])])].map((d) =>
        moment(d).tz(timezone)
      ),
    [startTime, startTimes, timezone]
  );

  const consecutiveDatesString = useMemo(() => {
    if (isConsecutiveList(dates)) {
      return `${dates[0].format(SHORT_DATE_FORMAT)} - ${dates[
        dates.length - 1
      ].format(SHORT_DATE_FORMAT)}`;
    }
  }, [dates]);

  const { initialNonConsecutiveDates, remainingNonConsecutiveDates } =
    useMemo(() => {
      const formatted = dates.map((d) => d.format(SHORT_DATE_FORMAT));

      const initialNonConsecutiveDates = formatted.slice(
        0,
        NUM_INITIAL_VISIBLE_DATES
      );

      const remainingNonConsecutiveDates = formatted.slice(
        NUM_INITIAL_VISIBLE_DATES
      );

      return { initialNonConsecutiveDates, remainingNonConsecutiveDates };
    }, [dates]);

  const toggleRemainingDatesVisibility = useCallback(() => {
    setRemainingDatesVisible((prev) => !prev);
  }, []);

  const toggleButtonText = useMemo(
    () =>
      remainingDatesVisible
        ? t('buttons.collapse_dates')
        : t('buttons.expand_dates'),
    [remainingDatesVisible, t]
  );

  if (loading) {
    return (
      <Wrapper data-testid="loader">
        <DateIcon />
        <Skeleton.Button active style={{ height: 20 }} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <DateIcon />

      {consecutiveDatesString && (
        <Typography.Text data-testid="range-string">
          {consecutiveDatesString}
        </Typography.Text>
      )}

      {!consecutiveDatesString && (
        <div>
          <DateContainer data-testid="dates">
            {initialNonConsecutiveDates.map((date) => (
              <Typography.Text key={date}>{date}</Typography.Text>
            ))}

            {remainingNonConsecutiveDates.length > 0 && (
              <>
                {remainingDatesVisible &&
                  remainingNonConsecutiveDates.map((date) => (
                    <Typography.Text key={date}>{date}</Typography.Text>
                  ))}
              </>
            )}
          </DateContainer>

          {remainingNonConsecutiveDates.length > 0 && (
            <DateToggleButton
              type="link"
              size="small"
              onClick={toggleRemainingDatesVisibility}
              data-testid="toggle"
            >
              {toggleButtonText}
            </DateToggleButton>
          )}
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--Space-Padding-paddingXS, 8px);
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateIcon = styled(CalendarSolid)`
  width: 16px;
  height: 16px;
  fill-opacity: 0.55;
  margin-top: 2px;
`;

const DateToggleButton = styled(Button)`
  justify-content: flex-start;
  padding: 0;
`;
