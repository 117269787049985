import { TFunction } from 'i18next';
import { type SelectOption } from '../../../types';
import { type Spaces } from '../TypeFilter';

export const spaceTypesToSelectOptions = (
  spaces: Spaces | undefined,
  t: TFunction
): SelectOption[] => {
  if (!spaces) {
    return [];
  }

  const types = spaces.map((space) => space.type).filter((type) => !!type);

  // Remove duplicates
  const set = new Set(types);

  const translatedTypes: SelectOption[] = [];

  set.forEach((type) =>
    translatedTypes.push({
      label: t(`SPACE_FILTERS.TYPES.${type}`),
      value: type || '',
    })
  );

  return translatedTypes.sort((optionA, optionB) => {
    return optionA.label.localeCompare(optionB.label);
  });
};

export const getSpaceIdsThatAreOfSelectedType = (
  spaces: Spaces | undefined,
  selectedTypes: string[]
): string[] => {
  if (!spaces) {
    return [];
  }

  return (
    spaces
      .filter((space) => {
        return space.type ? selectedTypes.includes(space.type) : false;
      })
      .map((space) => space.id) || []
  );
};
