import React, { useCallback } from 'react';
import { RecoilURLSync } from 'recoil-sync';
import { dataToParam, paramToData } from './helpers';

export const RecoilURLSyncJSONSafe = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const serializeJSONSafely = useCallback(dataToParam, []);
  const deserializeJSONSafely = useCallback(paramToData, []);

  return (
    <RecoilURLSync
      location={{ part: 'queryParams' }}
      serialize={serializeJSONSafely}
      deserialize={deserializeJSONSafely}
    >
      {children}
    </RecoilURLSync>
  );
};
