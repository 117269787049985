import { Tag } from '@robinpowered/ui-kit';
import { DeskType } from 'generated';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const DeskTypeTag = ({ deskTypes }: { deskTypes: DeskType[] }) => {
  const { t } = useTranslation('resourceDetails');

  const tagText = useMemo(() => {
    if (deskTypes.length > 2) {
      return;
    }

    // The presense of 'Shared' is the simplest indicator
    // Shared + Assigned desks are assigned
    // Shared + Hotel desks are assigned
    // Evertying else is flexible
    return deskTypes.includes(DeskType.Shared) ? t('assigned') : t('flexible');
  }, [deskTypes, t]);

  if (!tagText) {
    return null;
  }

  return <Tag>{tagText}</Tag>;
};
