import React, { useMemo, useState } from 'react';
import { Input } from '@robinpowered/ui-kit';

import { SpaceListDetail } from '../graphql/useSpaceList';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Fuse from 'fuse.js';

const { Search } = Input;

interface SpaceListSearchProps {
  spaceList: SpaceListDetail[];
  children: (searchResults: SpaceListDetail[]) => React.ReactNode;
}

export const SpaceListSearch: React.FC<SpaceListSearchProps> = ({
  spaceList,
  children,
}) => {
  const { t } = useTranslation('spaceResourceDetails');
  const [searchQuery, setSearchQuery] = useState('');
  // Configure Fuse instance
  const fuse = useMemo(() => {
    const options = {
      keys: ['name'],
      threshold: 0.2,
      includeMatches: true,
    };
    return new Fuse(spaceList, options);
  }, [spaceList]);

  const searchResults = useMemo(() => {
    if (spaceList && spaceList.length > 0) {
      const searchTerm = searchQuery.trim().toLowerCase();
      if (searchTerm === '') {
        return spaceList;
      } else {
        const results = fuse.search(searchTerm);
        const filteredSpaces = results.map((result) => result.item);
        return filteredSpaces;
      }
    }
    return [];
  }, [spaceList, searchQuery, fuse]);

  return (
    <>
      <SearchInput
        allowClear
        onClear={() => setSearchQuery('')}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={t('space_list.search')}
        name="space-search"
      />
      {children(searchResults)}
    </>
  );
};

const SearchInput = styled(Search)`
  width: 300px;
  align-self: center;
  max-width: 100%;
`;
