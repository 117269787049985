import React, { createContext, useEffect, ReactNode, useCallback } from 'react';
import { useOrganizationLocations } from 'hooks/useOrganizationLocations';
import { useLocationLevels } from 'hooks/useLocationLevels';
import { useLocalStorageContext } from './LocalStorageContext';
import { useDefaultLocation } from 'hooks/useDefaultLocation';
import {
  useSelectedLevel,
  useSelectedLocation,
  useSetTimezone,
  useSetCurrentlySelectedResource,
} from 'atoms/resource';
import { useSetRightSidebarView } from 'atoms/sidebar/hooks';

interface MapContextProps {
  selectedLevelId: string | null;
  setSelectedLevelId: (levelId: string) => void;
  selectedLocationId: string | null;
  setSelectedLocationId: (locationId: string) => void;
}

const MapControlsContext = createContext<MapContextProps | undefined>(
  undefined
);

export const MapControlsProvider = ({ children }: { children?: ReactNode }) => {
  const { data: defaultLocation, loading: defaultLocationsLoading } =
    useDefaultLocation();
  const {
    recentlySelectedLocations: [
      recentlySelectedLocationIds,
      setRecentlySelectedLocationIds,
    ],
    recentlySelectedLevel: [
      recentlySelectedLevelId,
      setRecentlySelectedLevelId,
    ],
  } = useLocalStorageContext();
  const locationData = useOrganizationLocations();
  const [selectedLocationId, setSelectedLocation] = useSelectedLocation();
  const setRightSidebarView = useSetRightSidebarView();
  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const levelData = useLocationLevels(selectedLocationId);
  const setTimezone = useSetTimezone();

  const setSelectedLocationId = useCallback(
    (locationId: string) => {
      // ensure the most recently selected is the first elemnt in array
      const locationSet = new Set([locationId, ...recentlySelectedLocationIds]);
      const updatedRecentLocations = Array.from(locationSet).slice(0, 3);

      setRecentlySelectedLocationIds(updatedRecentLocations);

      setSelectedLocation(locationId);
      setRightSidebarView(undefined);
      setCurrentlySelectedResource(null);
    },
    [
      recentlySelectedLocationIds,
      setCurrentlySelectedResource,
      setRecentlySelectedLocationIds,
      setRightSidebarView,
      setSelectedLocation,
    ]
  );

  useEffect(() => {
    if (levelData.data?.getLocationById?.timezone) {
      setTimezone(levelData.data.getLocationById.timezone);
    }
  }, [levelData, setTimezone]);

  useEffect(() => {
    // Priority order.
    // 1) locationId exists on queryparam 2) user's default location 3) most recent from local storage 4) first location in org
    if (
      !selectedLocationId &&
      !defaultLocationsLoading &&
      !locationData.loading &&
      locationData.locations.length > 0
    ) {
      const defaultLocationIndex = locationData.locations.findIndex(
        (x) =>
          defaultLocation?.getOrSuggestMyDefaultLocationOrLevel?.locationId ===
          x.id
      );

      if (defaultLocationIndex > -1) {
        return setSelectedLocationId(
          locationData.locations[defaultLocationIndex].id
        );
      }
      if (recentlySelectedLocationIds.length > 1) {
        const recentlySelectionLocationIndex = locationData.locations.findIndex(
          (x) => recentlySelectedLocationIds[0] === x.id
        );
        if (recentlySelectionLocationIndex > -1)
          return setSelectedLocationId(
            locationData.locations[recentlySelectionLocationIndex].id
          );
      }
      return setSelectedLocationId(locationData.locations[0].id);
    }
  }, [
    setSelectedLocationId,
    selectedLocationId,
    locationData.loading,
    locationData.locations,
    defaultLocation,
    defaultLocationsLoading,
    recentlySelectedLocationIds,
  ]);

  const [selectedLevelId, setSelectedLevel] = useSelectedLevel();

  const setSelectedLevelId = useCallback(
    (levelId: string) => {
      setRecentlySelectedLevelId(levelId);
      setSelectedLevel(levelId);
      setRightSidebarView(undefined);
      setCurrentlySelectedResource(null);
    },
    [
      setCurrentlySelectedResource,
      setRecentlySelectedLevelId,
      setRightSidebarView,
      setSelectedLevel,
    ]
  );
  useEffect(() => {
    if (
      !levelData.loading &&
      !defaultLocationsLoading &&
      levelData?.data?.getLocationById?.levels &&
      levelData.data.getLocationById.levels.length > 0 &&
      !selectedLevelId &&
      selectedLocationId
    ) {
      // Priority order (if exists in list of levels)
      // 1) url param 2) default level 3) most recent from local storage 4) the first level in list
      const levels = levelData.data.getLocationById.levels;
      const findLevelById = (levelId: string | undefined | null) =>
        levelData?.data?.getLocationById?.levels.findIndex(
          (x) => x.id === levelId
        ) || -1;

      const defaultLevelIdFound = findLevelById(
        defaultLocation?.getOrSuggestMyDefaultLocationOrLevel?.levelId
      );

      if (
        defaultLocation?.getOrSuggestMyDefaultLocationOrLevel?.levelId &&
        defaultLevelIdFound > -1
      ) {
        return setSelectedLevelId(levels[defaultLevelIdFound].id);
      }
      const recentlySelectedLevelIdFound = findLevelById(
        recentlySelectedLevelId
      );
      if (recentlySelectedLevelId && recentlySelectedLevelIdFound > -1) {
        return setSelectedLevelId(levels[recentlySelectedLevelIdFound].id);
      }
      return setSelectedLevelId(levels[0].id);
    }
  }, [
    levelData,
    selectedLevelId,
    setSelectedLevelId,
    recentlySelectedLevelId,
    defaultLocation,
    defaultLocationsLoading,
    selectedLocationId,
  ]);

  return (
    <MapControlsContext.Provider
      value={{
        selectedLevelId,
        setSelectedLevelId,
        selectedLocationId,
        setSelectedLocationId,
      }}
    >
      {children}
    </MapControlsContext.Provider>
  );
};

export const useMapControlsContext = (): MapContextProps => {
  const context = React.useContext(MapControlsContext);
  if (!context) {
    throw new Error('No map context found');
  }
  return context;
};
