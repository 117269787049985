import { gql, MutationTuple, useMutation } from '@apollo/client';
import {
  ConfirmDeskReservationFromDashboardMutationResult,
  ConfirmDeskReservationFromDashboardMutationVariables,
} from 'generated';

export const confirmDeskMutation = gql`
  mutation ConfirmDeskReservationFromDashboard(
    $reservationId: ID!
    $type: DeskConfirmationType!
  ) {
    confirmDeskReservation(reservationId: $reservationId, type: $type)
  }
`;

export const useConfirmDesk = (): MutationTuple<
  ConfirmDeskReservationFromDashboardMutationResult,
  ConfirmDeskReservationFromDashboardMutationVariables
> => {
  return useMutation<
    ConfirmDeskReservationFromDashboardMutationResult,
    ConfirmDeskReservationFromDashboardMutationVariables
  >(confirmDeskMutation, {
    refetchQueries: ['DeskReservationsBySeatId'],
  });
};
