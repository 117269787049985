import type { CapacityGroup, SelectOption } from '../../../../filters/types';
import type { Spaces } from '../CapacityFilter';

export const capacityToSelectOptions = (
  spaces: Spaces | undefined
): SelectOption[] => {
  if (!spaces) {
    return [];
  }

  const capacityGroupMins = [1, 3, 5, 7, 9];
  const maxCapacity = Math.max(...spaces.map((space) => space.capacity || 0));

  const selectOptions = capacityGroupMins
    .filter((min) => min <= maxCapacity)
    .map((min) => {
      const capacityGroup: CapacityGroup = {
        min,
        max: min >= 9 ? null : min + 1,
      };

      const capacityLabel = min >= 9 ? '9+' : `${min}-${min + 1}`;

      return {
        label: `${capacityLabel}`,
        value: JSON.stringify(capacityGroup),
      };
    });

  return selectOptions;
};

export const getSpaceIdsThatAreWithCapacityGroupRange = (
  spaces: Spaces | undefined,
  selectedCapacityGroup: CapacityGroup
): string[] => {
  if (!spaces) {
    return [];
  }

  return spaces
    .filter((s) => isWithinRange(s.capacity, selectedCapacityGroup))
    .map((s) => s.id);
};

const isWithinRange = (
  spaceCapacity: number | null | undefined,
  { min, max }: CapacityGroup
) => {
  if (!spaceCapacity) {
    return false;
  }

  return fitsMin(spaceCapacity, min) && fitsMax(spaceCapacity, max);
};

const fitsMin = (spaceCapacity: number, min: number | null) => {
  return min === null || spaceCapacity >= min;
};

const fitsMax = (spaceCapacity: number, max: number | null) => {
  return max === null || spaceCapacity <= max;
};
