import styled from '@emotion/styled';
import { Typography } from '@robinpowered/ui-kit';

const { Text } = Typography;

export function StatusMessage({
  icon,
  message,
  isPositive,
}: {
  icon: React.ReactNode;
  message: string;
  isPositive?: boolean;
}) {
  return (
    <StatusContainer>
      <IconContainer>{icon}</IconContainer>
      <Text style={{ color: isPositive ? 'green' : undefined }}>{message}</Text>
    </StatusContainer>
  );
}

const StatusContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--Space-Padding-paddingXS, 8px);
  flex-direction: row;
`;

const IconContainer = styled.div`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
`;
