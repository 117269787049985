import { List } from '@robinpowered/ui-kit';
import { DeskListItem } from './DeskListItem';
import { DeskWithAvailability } from './types';
import { sortObjectAsc } from 'utils';

export const ZoneList = ({ desks }: { desks: DeskWithAvailability[] }) => {
  const items = [...desks].sort(sortObjectAsc('name'));

  return (
    <List
      dataSource={items}
      renderItem={(deskData) => (
        <List.Item data-testid="zone-list-item">
          <DeskListItem desk={deskData} />
        </List.Item>
      )}
    />
  );
};
