import { FC, useMemo } from 'react';
import { DeskListItemBase } from './DeskListItemBase';
import { momentToLocationDateTime } from 'utils';
import { DeskListItemReservation } from '../../graphql/useDeskListItemData';
import moment from 'moment';
import { useTimezone } from 'atoms/resource';
import { SHORT_TIME_FORMAT } from 'constants/timeFormat';
import { useDefaultDeskListItemClick } from './hooks/useDefaultDeskListItemClick';

export const DeskListItemBooked: FC<{
  deskId: string;
  reservation: DeskListItemReservation | undefined;
}> = ({ deskId, reservation }) => {
  const { timezone } = useTimezone();

  const onClick = useDefaultDeskListItemClick(deskId);

  const reservationTimeString = useMemo(() => {
    return `${momentToLocationDateTime(
      moment(reservation?.startTime),
      timezone
    ).format(SHORT_TIME_FORMAT)} - ${momentToLocationDateTime(
      moment(reservation?.endTime),
      timezone
    ).format(SHORT_TIME_FORMAT)}`;
  }, [timezone, reservation?.endTime, reservation?.startTime]);

  return (
    <DeskListItemBase
      title={reservation?.reservee?.user?.name || ''}
      subtitle={reservationTimeString}
      avatarSrc={reservation?.reservee?.user?.avatar || undefined}
      reserveeName={reservation?.reservee?.user?.name || undefined}
      reserveeId={reservation?.reservee?.user?.id || undefined}
      onClick={onClick}
    />
  );
};
