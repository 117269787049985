import { Collapse, CollapseProps, Skeleton } from '@robinpowered/ui-kit';
import { useDesksOnFloor } from '../graphql/useDesksOnFloor';
import { useMapControlsContext } from 'contexts';
import { FC, useMemo } from 'react';
import { useBookingEnabledForDesks } from '../hooks/useBookingEnabledForDesks';
import { ZoneAvailability } from './ZoneAvailability';
import { ZoneList } from './ZoneList';
import { DeskListSearch } from './DeskListSearch';
import styled from '@emotion/styled';
import { sortObjectAsc } from 'utils';
import { useFilteredDeskIds } from 'atoms/mapInteractions';

export const DeskList: FC<{ selectedDeskId?: string | undefined }> = ({
  selectedDeskId,
}) => {
  const filteredDeskIds = useFilteredDeskIds();
  const { selectedLevelId } = useMapControlsContext();
  const { loading: desksLoading, data } = useDesksOnFloor(selectedLevelId);

  const { data: desksWithAvailability, loading: desksWithAvailabilityLoading } =
    useBookingEnabledForDesks(
      data?.getLevelById?.zones.flatMap((zone) =>
        zone.desks.map((desk) => desk.id)
      )
    );

  const zonesWithFilteredDesks = useMemo(() => {
    return data?.getLevelById?.zones
      .map((zone) => ({
        ...zone,
        desks: zone.desks.filter((desk) => filteredDeskIds.includes(desk.id)),
      }))
      .filter((zone) => zone.desks.length > 0);
  }, [data, filteredDeskIds]);

  const zonesWithAvailability = useMemo(() => {
    if (
      desksLoading ||
      desksWithAvailabilityLoading ||
      !zonesWithFilteredDesks
    ) {
      return [];
    }

    return zonesWithFilteredDesks.map((zone) => {
      return {
        ...zone,
        desks: zone.desks.map((desk) => ({
          ...desk,
          isBookable: desksWithAvailability?.[desk.id]?.isBookable || false,
          isDuringExclusion:
            desksWithAvailability?.[desk.id]
              ?.isSelectedStartTimeDuringExclusion || false,
          noAccessOrPermission:
            desksWithAvailability?.[desk.id]?.noAccessOrPermission || false,
          requiresAssignmentByAdmin:
            desksWithAvailability?.[desk.id]?.requiresAssignmentByAdmin ||
            false,
        })),
      };
    });
  }, [
    desksLoading,
    desksWithAvailabilityLoading,
    zonesWithFilteredDesks,
    desksWithAvailability,
  ]);

  const defaultOpenZoneId = useMemo(() => {
    if (!selectedDeskId) {
      return undefined;
    }

    return zonesWithAvailability?.find((zone) =>
      zone.desks.some((desk) => desk.id === selectedDeskId)
    )?.id;
  }, [selectedDeskId, zonesWithAvailability]);

  const items: CollapseProps['items'] = useMemo(() => {
    return zonesWithAvailability?.sort(sortObjectAsc('name')).map((zone) => ({
      key: zone.id,
      label: zone.name,
      children: <ZoneList desks={zone.desks} />,
      extra: <ZoneAvailability zone={zone} />,
    }));
  }, [zonesWithAvailability]);

  const isLoading = desksLoading || desksWithAvailabilityLoading;

  return (
    <>
      {isLoading && (
        <div data-testid="skeleton">
          <Skeleton />
        </div>
      )}

      {!isLoading && (
        <Container>
          <DeskListSearch zones={zonesWithAvailability} />
          <Collapse items={items} defaultActiveKey={defaultOpenZoneId} />
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--Space-Margin-margin, 16px);
`;
