import { gql, useQuery } from '@apollo/client';
import {
  DeskReservationsForDeskPolicyCardQuery,
  DeskReservationsForDeskPolicyCardQueryVariables,
} from 'generated';
import { Moment } from 'moment';

export type DeskReservationForDeskPolicyCard =
  DeskReservationsForDeskPolicyCardQuery['getDeskReservationsByUserId']['reservations'][number];

export const GET_DESK_RESERVATIONS_FOR_USER = gql`
  query DeskReservationsForDeskPolicyCard(
    $userId: ID!
    $start: Date!
    $end: Date!
  ) {
    getDeskReservationsByUserId(id: $userId, startTime: $start, endTime: $end) {
      userId
      reservations {
        id
        startTime
        seat {
          id
        }
      }
    }
  }
`;

export function useDeskReservationsByUserId(
  startTime?: Moment,
  endTime?: Moment,
  userId?: string
): {
  loading: boolean;
  reservations: DeskReservationForDeskPolicyCard[] | undefined;
} {
  const result = useQuery<
    DeskReservationsForDeskPolicyCardQuery,
    DeskReservationsForDeskPolicyCardQueryVariables
  >(GET_DESK_RESERVATIONS_FOR_USER, {
    variables: {
      userId: userId ? userId : '',
      start: startTime?.toISOString() || '',
      end: endTime?.toISOString() || '',
    },
    skip: !userId || !startTime || !endTime,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });
  return {
    loading: result.loading,
    reservations: result.data?.getDeskReservationsByUserId.reservations,
  };
}
