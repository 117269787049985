import styled from '@emotion/styled';
import { Button, message, Skeleton, Typography } from '@robinpowered/ui-kit';
import {
  useGetSpaceEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useStartTimeIsNow,
  useTimezone,
} from 'atoms/resource';
import { momentToLocationDateTime } from 'utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAvailabilityPillStyles } from './hooks';
import { AvailabilityPill } from './SpaceAvailabilityPill';
import { useSpaceWindowedAvailability } from '../graphql/useSpaceWindowedAvailability';
import { useAuthContext } from 'contexts';
import { redirectToOldComposer } from 'utils/legacyDashboard';
import { useState, useCallback } from 'react';
import {
  Availability_Pill_VERY_SHORT_TIME_FORMAT,
  Availability_Pill_SHORT_TIME_FORMAT_WITH_ZONE,
  Availability_Pill_SHORT_TIME_FORMAT,
} from 'constants/index';

const { Title } = Typography;

type AvailabilityDetailsProps = {
  spaceId: string;
};

export const SpaceAvailabilityPills = ({
  spaceId,
}: AvailabilityDetailsProps) => {
  const { t } = useTranslation('spaceResourceDetails');
  const { timezone } = useTimezone();
  const { currentOrg } = useAuthContext();
  const startTimeIsNow = useStartTimeIsNow();
  const endTimes = useGetSpaceEndTimesForSelectedDates();
  const startTimes = useGetStartTimesForSelectedDates();

  const pillStyles = useAvailabilityPillStyles();

  const [isRedirecting, setIsRedirecting] = useState(false);

  const { spaceDetails, loading } = useSpaceWindowedAvailability(
    spaceId,
    startTimes?.[0]
  );

  const handlePillClick = useCallback(
    (start: string, end: string) => {
      if (!currentOrg || currentOrg.slug === '') {
        void message.error(t('error'));
        return;
      }

      setIsRedirecting(true);

      redirectToOldComposer(
        currentOrg?.slug ?? '',
        spaceId,
        momentToLocationDateTime(moment(start), timezone),
        momentToLocationDateTime(moment(end), timezone)
      );
    },
    [currentOrg, spaceId, timezone, t]
  );

  const onViewMore = useCallback(() => {
    if (
      !currentOrg ||
      currentOrg.slug === '' ||
      !startTimes?.[0] ||
      !endTimes?.[0]
    ) {
      void message.error(t('error'));
      return;
    }
    setIsRedirecting(true);

    redirectToOldComposer(
      currentOrg?.slug ?? '',
      spaceId,
      startTimes[0],
      endTimes[0]
    );
  }, [t, currentOrg, spaceId, startTimes, endTimes]);

  const renderAvailabilityPills = useCallback(() => {
    if (loading) {
      return <Skeleton />;
    }
    return spaceDetails?.meetingRoomWindowedAvailability?.map((slot, index) => {
      const { start, end, status } = slot;

      if (!start || !end) {
        return null;
      }

      const isDisabled = status !== 'AVAILABLE';
      const startMoment = moment(start);
      const startTimeFormat =
        startMoment.minutes() === 0
          ? Availability_Pill_VERY_SHORT_TIME_FORMAT
          : Availability_Pill_SHORT_TIME_FORMAT;
      const startTime =
        index === 0 && startTimeIsNow
          ? t('space_availability.now')
          : momentToLocationDateTime(startMoment, timezone).format(
              startTimeFormat
            );
      const endTime = momentToLocationDateTime(moment(end), timezone).format(
        Availability_Pill_SHORT_TIME_FORMAT_WITH_ZONE
      );
      const timeRange = `${startTime} - ${endTime}`;

      return (
        <AvailabilityPill
          key={index}
          disabled={isDisabled || isRedirecting}
          {...pillStyles}
          onClick={() => handlePillClick(start, end)}
        >
          {timeRange}
        </AvailabilityPill>
      );
    });
  }, [
    loading,
    spaceDetails,
    startTimeIsNow,
    timezone,
    isRedirecting,
    pillStyles,
    handlePillClick,
    t,
  ]);

  return (
    <AvailbilityDetails>
      <Title level={5}>{t('space_availability.availability')}</Title>
      <AvailabilityPillsContainer>
        {renderAvailabilityPills()}
      </AvailabilityPillsContainer>
      <ViewScheduleButton onClick={onViewMore} disabled={isRedirecting}>
        {t('space_availability.view_more_times')}
      </ViewScheduleButton>
    </AvailbilityDetails>
  );
};

const AvailbilityDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
  max-width: 100%;
  box-sizing: border-box;
`;

const AvailabilityPillsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 8px;
  align-self: stretch;
  max-width: 100%;
  box-sizing: border-box;
`;

const ViewScheduleButton = styled(Button)`
  display: flex;
  padding: 0px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
