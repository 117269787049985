import { FC } from 'react';
import { DeskListItemBase } from './DeskListItemBase';
import { useTranslation } from 'react-i18next';
import { DeskListItemDeskReservation } from '../../graphql/useDeskListItemData';
import { useDefaultDeskListItemClick } from './hooks/useDefaultDeskListItemClick';

export const DeskListItemCurrentUserReservation: FC<{
  deskName: string;
  currentUserReservation: DeskListItemDeskReservation | undefined;
  deskId: string;
}> = ({ deskName, currentUserReservation, deskId }) => {
  const { t } = useTranslation('resourceDetails');

  const onClick = useDefaultDeskListItemClick(deskId);

  return (
    <DeskListItemBase
      title={deskName}
      subtitle={t('desk_list.current_booking')}
      avatarSrc={currentUserReservation?.reservee?.user?.avatar || undefined}
      reserveeName={currentUserReservation?.reservee?.user?.name || undefined}
      reserveeId={currentUserReservation?.reservee?.user?.id || undefined}
      onClick={onClick}
    />
  );
};
