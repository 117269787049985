type Components =
  | 'SIDEBAR_TRIGGER'
  | 'SIDEBAR'
  | 'SIDEBAR_MASK'
  | 'FILTER_CONTROLS'
  | 'MESSAGE'
  | 'CANCEL_RESERVATION_MODAL'
  | 'CANCEL_RESERVATION_MODAL_TOOLTIPS'
  | 'OFFICE_HOURS_MODAL'
  | 'APP';

export const Z_INDEX: { [key in Components]: number } = {
  SIDEBAR_TRIGGER: 5000,
  SIDEBAR: 3000,
  SIDEBAR_MASK: 2000,
  FILTER_CONTROLS: 50,
  MESSAGE: 8000,
  CANCEL_RESERVATION_MODAL: 9000,
  CANCEL_RESERVATION_MODAL_TOOLTIPS: 9001,
  OFFICE_HOURS_MODAL: 9000,
  APP: 1,
};
