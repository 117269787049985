import { TFunction } from 'i18next';
import difference from 'lodash/difference';
import { type SelectOption } from '../../../types';
import { type Spaces } from '../AmenitiesFilter';

export const amenitiesToSelectOptions = (
  spaces: Spaces | undefined,
  t: TFunction
): SelectOption[] => {
  if (!spaces) {
    return [];
  }

  const amenityOptions = mapAmenitiesToOptions(spaces);
  const filteredOptions = filterOutDuplicatesByValue(amenityOptions);
  const translatedOptions = translateOptionLabel(filteredOptions, t);

  return sortByLabelAlphabetically(translatedOptions);
};

const mapAmenitiesToOptions = (spaces: Spaces): SelectOption[] => {
  return (
    spaces
      .flatMap((space) => space.amenities)
      .map((amenity) => ({
        label: amenity.name,
        value: amenity.amenityId || '',
      })) || []
  );
};

const filterOutDuplicatesByValue = (
  amenityOptions: SelectOption[]
): SelectOption[] => {
  return amenityOptions.reduce<SelectOption[]>((acc, curr) => {
    if (acc.findIndex((item) => item.value === curr.value) === -1) {
      acc.push(curr);
    }

    return acc;
  }, []);
};

const translateOptionLabel = (
  filteredOptions: SelectOption[],
  t: TFunction
): SelectOption[] => {
  return filteredOptions?.map((option) => {
    const key = `COMMON.AMENITIES.DEFAULTS.${option.value}`;
    const translated = t(key);
    const label = translated !== key ? translated : option.label;

    return {
      ...option,
      label,
    };
  });
};

const sortByLabelAlphabetically = (
  translatedOptions: SelectOption[]
): SelectOption[] => {
  return translatedOptions.sort((optionA, optionB) => {
    return optionA.label.localeCompare(optionB.label);
  });
};

export const getSpaceIdsThatHaveAllSelectedAmenities = (
  spaces: Spaces | undefined,
  selectedAmenityIds: number[]
): string[] => {
  if (!spaces) {
    return [];
  }

  return (
    spaces
      .filter((space) => {
        const spaceAmenityIds = space.amenities
          .map((amenity) => amenity.amenityId)
          .filter((id) => !!id) as number[];

        return difference(selectedAmenityIds, spaceAmenityIds).length === 0;
      })
      .map((space) => space.id) || []
  );
};
