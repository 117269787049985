import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * useQuery hook with additional features:
 * - Loading will only be true on the first request
 * - Data will not become undefined on subsequent queries
 * - Accepts a set of keys as the third option, and if any those watched values change the cache WILL be busted
 *
 * Notes:
 * - Calls the standard apollo/client userQuery hook under the hood
 * - refetch will bust the loading/data caches
 */
export const useQueryCachedLoad = <
  TData,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> | undefined,
  // watchedVariables: Set<keyof TVariables> = new Set()
  watchedVariables: (keyof TVariables)[] = []
): QueryResult<TData, TVariables> => {
  const result = useQuery<TData, TVariables>(query, options);

  const [cachedLoading, setCachedLoading] = useState<boolean>(true);
  const [cachedData, setCachedData] = useState<TData | undefined>(undefined);

  // Track previous variables to detect changes using useRef
  const prevVariables = useRef<Partial<TVariables>>({});

  useEffect(() => {
    const watchedVariablesSet = new Set(watchedVariables);

    if (
      !options?.variables ||
      !watchedVariablesSet.size ||
      !prevVariables.current
    ) {
      return;
    }

    const hasChanges = Array.from(watchedVariablesSet).some((key) => {
      return !isEqual(prevVariables.current[key], options.variables?.[key]);
    });

    if (hasChanges) {
      setCachedLoading(true);
      setCachedData(undefined);

      prevVariables.current = options.variables;
    }
  }, [
    options?.variables,
    watchedVariables,
    prevVariables,
    setCachedLoading,
    setCachedData,
  ]);

  // Handle the caching of loading and data responses
  useEffect(() => {
    if (result && !result.loading) {
      if (cachedLoading === true) {
        setCachedLoading(false);
      }

      if (!isEqual(cachedData, result.data)) {
        setCachedData(result.data);
      }
    }
  }, [cachedData, cachedLoading, result]);

  const refetchWithBustedCache = useCallback(() => {
    setCachedLoading(true);
    setCachedData(undefined);

    return result.refetch();
  }, [result]);

  return {
    ...result,
    loading: cachedLoading,
    data: cachedData,
    refetch: refetchWithBustedCache,
  };
};
