import { useSetEndTimeForDesks, useSetStartTime } from 'atoms/resource';
import { useCallback, useEffect, useState } from 'react';
import { LocationDateTimeMoment } from 'utils';

export const useInitializeTimes = ({
  reservationStart,
  reservationEnd,
  nowAtLocation,
}: {
  reservationStart: LocationDateTimeMoment | undefined;
  reservationEnd: LocationDateTimeMoment | undefined;
  nowAtLocation: LocationDateTimeMoment;
}) => {
  const [timesInitialized, setTimesInitialized] = useState<boolean>(false);

  const setStartTime = useSetStartTime();
  const setEndTimeForDesks = useSetEndTimeForDesks();

  const initializeTimes = useCallback(() => {
    if (reservationStart && reservationEnd && !timesInitialized) {
      if (reservationStart.isAfter(nowAtLocation)) {
        setStartTime(reservationStart);
      }

      setEndTimeForDesks(reservationEnd);
      setTimesInitialized(true);
    }
  }, [
    nowAtLocation,
    reservationEnd,
    reservationStart,
    setEndTimeForDesks,
    setStartTime,
    timesInitialized,
  ]);

  useEffect(() => {
    initializeTimes();
  }, [initializeTimes]);

  return null;
};
