import { gql, useQuery } from '@apollo/client';
import { Select } from '@robinpowered/ui-kit';
import { useSelectedLevel } from 'atoms/resource';
import { useCallback, useMemo, useState } from 'react';
import { useSpaceFilterContext } from '../../context/SpaceFilterContext';
import { useTranslation } from 'react-i18next';
import {
  SpaceAmenityFilterQuery,
  SpaceAmenityFilterQueryVariables,
} from 'generated';
import {
  amenitiesToSelectOptions,
  getSpaceIdsThatHaveAllSelectedAmenities,
} from './utils/amenities';
import { type SelectOption } from '../../types';
import { NoResults } from '../../NoResults';

const AMENITY_FILTER_QUERY = gql`
  query spaceAmenityFilter($levelId: ID!) {
    getLevelById(id: $levelId) {
      spaces {
        id
        amenities {
          id
          name
          amenityId
        }
      }
    }
  }
`;

export type Spaces = NonNullable<
  SpaceAmenityFilterQuery['getLevelById']
>['spaces'];

export const AmenitiesFilter = () => {
  const { t } = useTranslation('filters');
  const [levelId] = useSelectedLevel();
  const [selectedAmenityIds, setSelectedAmenityIds] = useState<number[]>([]);
  const { setSpaceIdsThatMatchAmenityCriteria } = useSpaceFilterContext();

  const { loading, data } = useQuery<
    SpaceAmenityFilterQuery,
    SpaceAmenityFilterQueryVariables
  >(AMENITY_FILTER_QUERY, {
    skip: !levelId,
    variables: {
      levelId: levelId || '',
    },
  });

  const options: SelectOption[] = useMemo(
    () => amenitiesToSelectOptions(data?.getLevelById?.spaces, t),
    [data?.getLevelById?.spaces, t]
  );

  const handleChange = useCallback(
    (updatedAmenities: number[]) => {
      setSelectedAmenityIds(updatedAmenities);

      // All spaces match criteria if there are no selected amenities
      if (updatedAmenities.length === 0) {
        setSpaceIdsThatMatchAmenityCriteria(
          data?.getLevelById?.spaces?.map((space) => space.id) || []
        );
        return;
      }

      setSpaceIdsThatMatchAmenityCriteria(
        getSpaceIdsThatHaveAllSelectedAmenities(
          data?.getLevelById?.spaces,
          updatedAmenities
        )
      );
    },
    [data?.getLevelById?.spaces, setSpaceIdsThatMatchAmenityCriteria]
  );

  return (
    <Select
      optionFilterProp="label"
      loading={loading}
      mode="multiple"
      value={selectedAmenityIds}
      style={{ width: '200px' }}
      onChange={handleChange}
      options={options}
      placeholder={t('SPACE_FILTERS.FILTER_LABELS.AMENITIES')}
      maxTagCount="responsive"
      data-testid="space-amenities-filter"
      notFoundContent={<NoResults />}
    />
  );
};
