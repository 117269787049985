import { gql, useQuery } from '@apollo/client';
import { Select } from '@robinpowered/ui-kit';
import { useSelectedLevel } from 'atoms/resource';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeskAmenityFilterQuery,
  DeskAmenityFilterQueryVariables,
  DeskFilters,
} from '../../../../../generated';
import { type SelectOption } from '../../types';
import { useDeskFilterContext } from '../../context/DeskFilterContext';
import {
  amenitiesToSelectOptions,
  getDeskIdsThatHaveAllSelectedAmenities,
} from './utils/amenities';
import { NoResults } from '../../NoResults';

const AMENITY_FILTER_QUERY = gql`
  query deskAmenityFilter($levelId: ID!, $filters: [DeskFilters!]) {
    getLevelById(id: $levelId) {
      desks(filters: $filters) {
        id
        amenities {
          id
          name
          amenityId
        }
      }
    }
  }
`;

export type Desks = NonNullable<
  DeskAmenityFilterQuery['getLevelById']
>['desks'];

export const AmenitiesFilter = () => {
  const { t } = useTranslation('filters');
  const [levelId] = useSelectedLevel();
  const [selectedAmenityIds, setSelectedAmenityIds] = useState<number[]>([]);
  const { setDeskIdsThatMatchAmenityCriteria } = useDeskFilterContext();

  const { loading, data } = useQuery<
    DeskAmenityFilterQuery,
    DeskAmenityFilterQueryVariables
  >(AMENITY_FILTER_QUERY, {
    skip: !levelId,
    variables: {
      levelId: levelId || '',
      filters: [DeskFilters.Enabled],
    },
  });

  const options: SelectOption[] = useMemo(
    () => amenitiesToSelectOptions(data?.getLevelById?.desks, t),
    [data?.getLevelById?.desks, t]
  );

  const handleChange = useCallback(
    (updatedAmenities: number[]) => {
      setSelectedAmenityIds(updatedAmenities);

      // All desks match criteria if there are no selected amenities
      if (updatedAmenities.length === 0) {
        setDeskIdsThatMatchAmenityCriteria(
          data?.getLevelById?.desks?.map((desk) => desk.id) || []
        );
        return;
      }

      setDeskIdsThatMatchAmenityCriteria(
        getDeskIdsThatHaveAllSelectedAmenities(
          data?.getLevelById?.desks,
          updatedAmenities
        )
      );
    },
    [data?.getLevelById?.desks, setDeskIdsThatMatchAmenityCriteria]
  );

  return (
    <Select
      optionFilterProp="label"
      loading={loading}
      mode="multiple"
      value={selectedAmenityIds}
      style={{ width: '200px' }}
      onChange={handleChange}
      options={options}
      placeholder={t('DESK_FILTERS.FILTER_LABELS.AMENITIES')}
      maxTagCount="responsive"
      data-testid="desk-amenities-filter"
      notFoundContent={<NoResults />}
    />
  );
};
