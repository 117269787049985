import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import { App } from 'App';
import { config } from 'config';

if (config.robinEnv === 'development') {
  // eslint-disable-next-line no-console
  window.onerror = (e) => console.error(e);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
