import { DeskSidebarView } from 'atoms/sidebar/types';
import {
  FC,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type BackDestinationContextValue = {
  backDestination: DeskSidebarView | undefined;
  setBackDestination: Dispatch<SetStateAction<DeskSidebarView | undefined>>;
};

const BackDestinationContext = createContext<BackDestinationContextValue>({
  backDestination: 'edit-summary',
  setBackDestination: () => {
    throw new Error('BackDestinationContext not initialized');
  },
});

export const BackDestinationContextProvider: FC = ({ children }) => {
  const [backDestination, setBackDestination] = useState<
    DeskSidebarView | undefined
  >(undefined);

  return (
    <BackDestinationContext.Provider
      value={{ backDestination, setBackDestination }}
    >
      {children}
    </BackDestinationContext.Provider>
  );
};

export const useBackDestinationContext = (): BackDestinationContextValue => {
  return useContext(BackDestinationContext);
};
