import styled from '@emotion/styled';
import { Tooltip, Typography } from '@robinpowered/ui-kit';
import {
  useSetEndTimeFromStartTimeForDesks,
  useSetEndTimeFromStartTimeForSpaces,
  useSetStartTime,
  useTimezone,
} from 'atoms/resource';
import { EndTimeDropdown, StartTimeDropdown } from 'components/global/controls';
import moment, { Moment } from 'moment';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeskUnbookableReasonType } from 'generated';
import { ViolationMessage } from './ViolationMessage';
import { DeskUnbookableReasons } from '../graphql';

export const BookDeskTimePickers: FC<{
  unbookableReasons: DeskUnbookableReasons | undefined;
}> = ({ unbookableReasons }) => {
  const { t } = useTranslation('resourceDetails');

  const setStartTime = useSetStartTime();
  const setEndTimeFromStartTimeForDesks = useSetEndTimeFromStartTimeForDesks();
  const setEndTimeFromStartTimeForSpaces =
    useSetEndTimeFromStartTimeForSpaces();

  const { timezone, longName } = useTimezone();

  const handleStartTimeSelect = useCallback(
    (selectedTime: Moment) => {
      setStartTime(selectedTime);
      setEndTimeFromStartTimeForDesks(selectedTime);
      // Because the default availability span when a user adds an end time to the top controls is for spaces we
      // actually want to update the space end time here as well so if the user closes the side bar and then enables
      // the end time it has not fallen out of context with the shared start time
      setEndTimeFromStartTimeForSpaces(selectedTime);
    },
    [
      setStartTime,
      setEndTimeFromStartTimeForDesks,
      setEndTimeFromStartTimeForSpaces,
    ]
  );

  const timeViolation: string | undefined = useMemo(() => {
    const violationReason = unbookableReasons?.find(
      (r) =>
        r?.reason ===
        DeskUnbookableReasonType.SeatReservationWorkingHoursRestricted
    );

    switch (violationReason?.reason) {
      case DeskUnbookableReasonType.SeatReservationWorkingHoursRestricted:
        return t('desk_booking_controls.violations.outside_working_hours');

      default:
        return;
    }
  }, [t, unbookableReasons]);

  return (
    <>
      <TimePickersContainer>
        <StartTimeDropdown
          onTimeSelect={handleStartTimeSelect}
          status={timeViolation ? 'error' : undefined}
        />

        <Typography.Text>
          {t('desk_booking_controls.time_divider')}
        </Typography.Text>

        <EndTimeDropdown
          type={'seats'}
          status={timeViolation ? 'error' : undefined}
        />

        {timezone && (
          <Tooltip title={longName}>
            <Timezone type="secondary">
              {moment.tz(timezone).format('z')}
            </Timezone>
          </Tooltip>
        )}
      </TimePickersContainer>

      {timeViolation && <ViolationMessage message={timeViolation} />}
    </>
  );
};

const TimePickersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const Timezone = styled(Typography.Text)`
  && {
    margin: 0;
  }
`;
