import { Alert } from '@robinpowered/ui-kit';
import { FC, useMemo } from 'react';
import { DeskUnbookableReasons } from '../graphql';
import { DeskUnbookableReasonType } from 'generated';
import { useTranslation } from 'react-i18next';

export const NoAccessOrPermissionAlert: FC<{
  unbookableReasons: DeskUnbookableReasons;
}> = ({ unbookableReasons }) => {
  const { t } = useTranslation('resourceDetails');

  const noAccessOrPermission = useMemo(() => {
    const lackingPermissions = unbookableReasons.find((unbookable) => {
      return (
        unbookable?.reason === DeskUnbookableReasonType.LackingPermissions ||
        unbookable?.reason === DeskUnbookableReasonType.NoAccess
      );
    });
    return !!lackingPermissions;
  }, [unbookableReasons]);

  if (noAccessOrPermission) {
    return (
      <Alert
        message={t('desk_booking_controls.no_permission')}
        type="info"
        showIcon
      />
    );
  }

  return null;
};
