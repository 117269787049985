import React from 'react';
import styled from '@emotion/styled';
import { Button, Card, Tag, Row, Typography } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import ChevronRight from '@robinpowered/icons/ChevronRightOutline';
import ChevronDown from '@robinpowered/icons/ChevronDownOutline';
import { useLocalStorageContext } from 'contexts';
const { Text, Title } = Typography;

const MinimizedCardContainer = styled(Card)`
  margin-bottom: 10px;
  padding: 0px 16px;
  height: 50px;
  width: 315px;
`;

const OpenCardContainer = styled(Card)`
  margin-bottom: 10px;
  padding: 0px 16px;
  height: 175px;
  width: 315px;
`;

const StyledButton = styled(Button)`
  margin-top: 5px;
`;

const BetaTag = styled(Tag)`
  font-size: 10px;
  height: 25px;
  background-color: var(--Components-Tag-Global-colorInfoBg, #f0faff);
  border-color: var(--Components-Tag-Global-colorInfoBorder, #9ecae8);
  text-align: center;
  padding-top: 2px;
`;

const Header = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  border: 0;
  &:hover {
    background-color: var(
      --Components-Button-Component-defaultBg,
      #ffffff
    ) !important;
  }
`;

const HeaderTextBox = styled.div`
  display: flex;
  gap: 10px,
  justify-content: flex-start;
`;

const ChevronWrapper = styled.div`
  padding-top: 3px;
`;

const EnableOfficeV1CardWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

const Body = styled.div`
  margin-top: 5px;
`;

export const EnableOfficeV1Card = () => {
  const { t } = useTranslation('leftSideBar');

  const {
    officeV2Enabled: [, setIsOfficeV2Enabled],
    enabledLegacyOfficeCardMinimized: [
      enabledLegacyOfficeCardMinimized,
      setEnabledLegacyOfficeCardMinimized,
    ],
  } = useLocalStorageContext();

  const onHeaderClick = () => {
    setEnabledLegacyOfficeCardMinimized(!enabledLegacyOfficeCardMinimized);
  };

  const onButtonClick = () => {
    setIsOfficeV2Enabled(false);
  };

  const header = (
    <Header type="text" onClick={onHeaderClick}>
      <HeaderTextBox>
        <BetaTag>{t('enable_office_v1.tag')}</BetaTag>
        <Title level={5}>
          {enabledLegacyOfficeCardMinimized
            ? t('enable_office_v1.header_collapsed')
            : t('enable_office_v1.header_expanded')}
        </Title>
      </HeaderTextBox>
      <ChevronWrapper>
        {enabledLegacyOfficeCardMinimized ? (
          <ChevronRight size={12} />
        ) : (
          <ChevronDown size={12} />
        )}
      </ChevronWrapper>
    </Header>
  );

  return (
    <EnableOfficeV1CardWrapper>
      {enabledLegacyOfficeCardMinimized ? (
        <MinimizedCardContainer size={'small'}>{header}</MinimizedCardContainer>
      ) : (
        <OpenCardContainer size={'small'}>
          {header}
          <Body>
            <Row>
              <Text>{t('enable_office_v1.body')}</Text>
            </Row>
            <Row>
              <StyledButton onClick={onButtonClick}>
                {t('enable_office_v1.button')}
              </StyledButton>
            </Row>
          </Body>
        </OpenCardContainer>
      )}
    </EnableOfficeV1CardWrapper>
  );
};
