import styled from '@emotion/styled';
import { SpaceFilter } from './space';
import { DeskFilter } from './desk';
import { Z_INDEX } from '../../../constants';
import { useEffect } from 'react';
import {
  useCurrentFilter,
  useSetIsCameraResetting,
} from 'atoms/mapInteractions';

export const FilterControls = () => {
  const currentFilter = useCurrentFilter();
  const setIsCameraResetting = useSetIsCameraResetting();

  useEffect(() => {
    if (currentFilter) {
      setIsCameraResetting(true);
    }
  }, [currentFilter, setIsCameraResetting]);

  return (
    <Positioner>
      <Container>
        <DeskFilter />
        <SpaceFilter />
      </Container>
    </Positioner>
  );
};

const Container = styled.div`
  padding: 16px;
  display: flex;
  gap: 8px;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
`;

// This holds a place in the document flow for its children which allows them
// to be placed 'over' the map without needing to set their positions (top/bottom/etc)
const Positioner = styled.div`
  height: 0;
  position: relative;
  z-index: ${Z_INDEX.FILTER_CONTROLS};
`;
