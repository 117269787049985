import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  currentFilter,
  spaceFilterInitialized,
  filteredSpaceIds,
  mapInteractiveLayers,
  filteredDeskIds,
  deskFilterInitialized,
  isCameraResetting,
  mapMode,
} from './atoms';

export const useMapInteractiveLayers = () => {
  return useRecoilValue(mapInteractiveLayers);
};

export const useSetMapInteractiveLayers = () => {
  return useSetRecoilState(mapInteractiveLayers);
};

export const useMapMode = () => {
  return useRecoilValue(mapMode);
};

export const useSetMapMode = () => {
  return useSetRecoilState(mapMode);
};

export const useIsCameraResetting = () => {
  return useRecoilValue(isCameraResetting);
};

export const useSetIsCameraResetting = () => {
  return useSetRecoilState(isCameraResetting);
};

export const useResetMapInteractiveLayesrs = () => {
  return useResetRecoilState(mapInteractiveLayers);
};

// Top Level Filter

export const useCurrentFilter = () => {
  return useRecoilValue(currentFilter);
};

export const useSetCurrentFilter = () => {
  return useSetRecoilState(currentFilter);
};

// Space Filters

export const useFilteredSpaceIds = () => {
  return useRecoilValue(filteredSpaceIds);
};

export const useSetFilteredSpaceIds = () => {
  return useSetRecoilState(filteredSpaceIds);
};

export const useSpaceFilterInitialized = () => {
  return useRecoilValue(spaceFilterInitialized);
};

export const useSetSpaceFilterInitialized = () => {
  return useSetRecoilState(spaceFilterInitialized);
};

// Desks Filters

export const useFilteredDeskIds = () => {
  return useRecoilValue(filteredDeskIds);
};

export const useSetFilteredDeskIds = () => {
  return useSetRecoilState(filteredDeskIds);
};

export const useDeskFilterInitialized = () => {
  return useRecoilValue(deskFilterInitialized);
};

export const useSetDeskFilterInitialized = () => {
  return useSetRecoilState(deskFilterInitialized);
};
