import { Alert } from '@robinpowered/ui-kit';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const DisabledDeskAlert: FC<{
  isReservable: boolean;
  isDisabled: boolean | null | undefined;
}> = ({ isReservable, isDisabled }) => {
  const { t } = useTranslation('resourceDetails');

  const isEnabled = useMemo(() => {
    return isReservable && !isDisabled;
  }, [isDisabled, isReservable]);

  if (!isEnabled) {
    return (
      <Alert
        message={t('desk_booking_controls.desk_disabled')}
        type="info"
        showIcon
      />
    );
  }

  return null;
};
