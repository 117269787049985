import styled from '@emotion/styled';
import { message } from '@robinpowered/ui-kit';
import { useStartTimeIsNow, useTimezone } from 'atoms/resource';
import { momentToLocationDateTime } from 'utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAvailabilityPillStyles } from './hooks';
import { AvailabilityPill } from './SpaceAvailabilityPill';
import { useAuthContext } from 'contexts';
import { redirectToOldComposer } from 'utils/legacyDashboard';
import { useState, useCallback } from 'react';
import { SpaceListDetail } from '../graphql/useSpaceList';
import {
  Availability_Pill_SHORT_TIME_FORMAT,
  Availability_Pill_SHORT_TIME_FORMAT_WITH_ZONE,
} from 'constants/timeFormat';
import { css } from '@emotion/react';

type AvailabilityDetailsProps = {
  space: SpaceListDetail;
};

export const SpaceListAvailabilityPills = ({
  space,
}: AvailabilityDetailsProps) => {
  const { t } = useTranslation('spaceResourceDetails');
  const { timezone } = useTimezone();
  const { currentOrg } = useAuthContext();

  const pillStyles = useAvailabilityPillStyles();
  const startTimeIsNow = useStartTimeIsNow();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const handlePillClick = useCallback(
    (start: string, end: string) => {
      if (!currentOrg || !currentOrg.slug || currentOrg.slug === '') {
        void message.error(t('error'));
        return;
      }

      setIsRedirecting(true);

      redirectToOldComposer(
        currentOrg.slug,
        space.id,
        momentToLocationDateTime(moment(start), timezone),
        momentToLocationDateTime(moment(end), timezone)
      );
    },
    [currentOrg, space.id, timezone, t]
  );

  const renderAvailabilityPills = useCallback(() => {
    const TimePills = space.meetingRoomWindowedAvailability?.map(
      (slot, index) => {
        const { start, end, status } = slot;

        if (!start || !end) {
          return null;
        }

        const isDisabled = status !== 'AVAILABLE';
        const startMoment = momentToLocationDateTime(moment(start), timezone);
        const endMoment = momentToLocationDateTime(moment(end), timezone);
        const startTime =
          index === 0 && startTimeIsNow
            ? t('space_list_item.now')
            : startMoment.format(Availability_Pill_SHORT_TIME_FORMAT_WITH_ZONE);
        const startAndEndTime =
          startMoment.format(Availability_Pill_SHORT_TIME_FORMAT) +
          ' - ' +
          endMoment.format(Availability_Pill_SHORT_TIME_FORMAT_WITH_ZONE);
        return (
          <SpaceListAvailabilityPill
            key={index}
            disabled={isDisabled || isRedirecting}
            {...pillStyles}
            onClick={() => handlePillClick(start, end)}
          >
            <span>{startTime}</span>
            <span>{startAndEndTime}</span>
          </SpaceListAvailabilityPill>
        );
      }
    );

    return TimePills;
  }, [
    isRedirecting,
    space.meetingRoomWindowedAvailability,
    startTimeIsNow,
    timezone,
    pillStyles,
    handlePillClick,
    t,
  ]);

  return (
    <AvailbilityDetails>
      <AvailabilityPillsContainer>
        {renderAvailabilityPills()}
      </AvailabilityPillsContainer>
    </AvailbilityDetails>
  );
};

const hoverAnimationStyles = css`
  position: relative;
  overflow: hidden;
  text-align: center;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  &:hover span:first-child {
    transform: translate(-150%, -50%);
    opacity: 0;
  }
  &:hover span:last-child {
    transform: translate(-50%, -50%);
    opacity: 1;
    font-size: 10px;
  }
  span:first-child {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  span:last-child {
    opacity: 0;
    transform: translate(150%, -50%);
  }
`;

const SpaceListAvailabilityPill = styled(AvailabilityPill)`
  width: 100%;
  ${hoverAnimationStyles}
`;

const AvailbilityDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  align-self: stretch;
  max-width: 100%;
  box-sizing: border-box;
`;

const AvailabilityPillsContainer = styled.div`
  display: flex;
  gap: 5px;
  max-width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
`;
