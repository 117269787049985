import { Alert } from '@robinpowered/ui-kit';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Availability, DeskType } from 'generated';
import { DeskAvailability } from '../graphql';

export const AssignedDeskAlert: FC<{
  isReservable: boolean;
  isDisabled: boolean | null | undefined;
  rawTypes: DeskType[];
  availability: DeskAvailability;
  isDuringExclusion: boolean;
}> = ({
  isReservable,
  isDisabled,
  rawTypes,
  availability,
  isDuringExclusion,
}) => {
  const { t } = useTranslation('resourceDetails');

  const isEnabled = useMemo(() => {
    return isReservable && !isDisabled;
  }, [isDisabled, isReservable]);

  const isAssignedAndShared = useMemo(() => {
    return (
      rawTypes?.length === 2 &&
      rawTypes.includes(DeskType.Assigned) &&
      rawTypes.includes(DeskType.Shared)
    );
  }, [rawTypes]);

  const showAssignedDeskAlert = useMemo(() => {
    const isAvailable = availability === Availability.Available;

    return (
      isEnabled && isAvailable && isAssignedAndShared && !isDuringExclusion
    );
  }, [availability, isAssignedAndShared, isDuringExclusion, isEnabled]);

  if (isEnabled && showAssignedDeskAlert) {
    return (
      <Alert
        message={t('desk_booking_controls.assigned_desk_alert')}
        type="info"
        showIcon
      />
    );
  }

  return null;
};
