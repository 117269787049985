import { useMemo, useState } from 'react';
import { config } from '../../../config';

const StyleSpecTheme = {
  LIGHT: 'light',
  DARK: 'dark',
} as const;

type StyleSpecThemeType = typeof StyleSpecTheme[keyof typeof StyleSpecTheme];

const StyleSpecVersion = {
  'v1.2': 'v1.2',
} as const;

const ATLAS_BACKGROUND_COLORS = {
  [StyleSpecVersion['v1.2']]: {
    [StyleSpecTheme.LIGHT]: '#cdd5dc',
    [StyleSpecTheme.DARK]: '#202326',
  },
};

const version = 'v1.2';

export const useStyleSpec = (levelId: string | null | undefined) => {
  const [theme] = useState<StyleSpecThemeType>(StyleSpecTheme.LIGHT);

  const backgroundColor = useMemo(
    () => ATLAS_BACKGROUND_COLORS[version][theme],
    [theme]
  );

  const styleUrl = useMemo(() => {
    return levelId
      ? `${config.atlasUrl}/${version}/styles/levels/${levelId}/${theme}/style.json`
      : undefined;
  }, [levelId, theme]);

  return {
    backgroundColor,
    styleUrl,
  };
};
