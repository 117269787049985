import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  handleSetLeftSidebarOpen,
  handleSetRightSidebarView,
} from './handlers';
import { deskSidebarView } from './atoms';
import { DeskSidebarView } from './types';

export const useSetRightSidebarView = () => {
  return useRecoilCallback(handleSetRightSidebarView);
};

export const useSetLeftSidebarOpen = () => {
  return useRecoilCallback(handleSetLeftSidebarOpen);
};

export const useDeskSidebarView = () => {
  return useRecoilValue(deskSidebarView);
};

export const useSetDeskSidebarView = () => {
  const setSidebarView = useSetRightSidebarView();
  const setDeskSidebarView = useSetRecoilState(deskSidebarView);
  return (view: DeskSidebarView) => {
    setSidebarView('desks');
    setDeskSidebarView(view);
  };
};
