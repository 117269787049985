import React, { MouseEventHandler } from 'react';
import { Button, Checkbox } from '@robinpowered/ui-kit';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import styled from '@emotion/styled';

type FooterButton = {
  label: string;
  onClick: MouseEventHandler<HTMLElement>;
  disabled: boolean;
  loading?: boolean;
};

type FooterCheckbox = {
  label: string;
  onChange: (e: CheckboxChangeEvent) => void;
};

export type SidebarFooterProps = {
  actions?: {
    checkBox?: FooterCheckbox;
    primary?: FooterButton;
    secondary?: FooterButton;
    tertiary?: FooterButton;
  };
};

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const SidebarFooter = ({
  actions,
  ...divProps
}: SidebarFooterProps & DivProps): JSX.Element | null => {
  const { primary, secondary, tertiary, checkBox } = actions || {};

  if (!primary && !secondary && !tertiary && !checkBox) {
    return null;
  }

  return (
    <Footer {...divProps}>
      {checkBox && (
        <Checkbox onChange={checkBox?.onChange}>{checkBox.label}</Checkbox>
      )}

      <ButtonsContainer>
        {primary && (
          <ActionButton
            type="primary"
            onClick={primary.onClick}
            disabled={primary.disabled}
            loading={primary.loading}
            data-testid="primary"
          >
            {primary.label}
          </ActionButton>
        )}

        {secondary && (
          <ActionButton
            type="default"
            onClick={secondary.onClick}
            disabled={secondary.disabled}
            loading={secondary.loading}
            data-testid="secondary"
          >
            {secondary.label}
          </ActionButton>
        )}

        {tertiary && (
          <ActionButton
            type="text"
            onClick={tertiary.onClick}
            disabled={tertiary.disabled}
            loading={tertiary.loading}
            data-testid="tertiary"
          >
            {tertiary.label}
          </ActionButton>
        )}
      </ButtonsContainer>
    </Footer>
  );
};

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--Space-Margin-marginSM, 12px);
  background: var(--Colors-Neutral-colorWhite, #fff);
  border-top: var(--Components-Drawer-Global-lineWidth, 1px) solid
    var(--Components-Drawer-Global-colorSplit, rgba(0, 0, 0, 0.06));
  padding: var(--Space-Padding-padding, 16px);
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--Space-Margin-marginSM, 12px);
`;

const ActionButton = styled(Button)`
  flex-basis: 0;
  flex-grow: 1;
`;
