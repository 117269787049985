import styled from '@emotion/styled';
import { Radio, RadioChangeEvent } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { LocationDateTimeMoment } from 'utils';
import { useMemo } from 'react';
import { CancelOptions } from '../types';

export const CancelReservationOptionsSection = ({
  selectedOption,
  isSeries,
  loading,
  reservationStartDate,
  reservationInProgress,
  handleRadioButton,
}: {
  selectedOption: CancelOptions;
  isSeries: boolean;
  loading: boolean;
  reservationStartDate: LocationDateTimeMoment;
  reservationInProgress: boolean;
  handleRadioButton: (e: RadioChangeEvent) => void;
}) => {
  const { t } = useTranslation('resourceDetails');

  const [endText, cancelText] = useMemo(() => {
    if (isSeries) {
      return [t('cancel_modal.end_series'), t('cancel_modal.cancel_series')];
    }

    return [t('cancel_modal.end_week'), t('cancel_modal.cancel_week')];
  }, [isSeries, t]);

  return (
    <Radio.Group value={selectedOption} onChange={handleRadioButton}>
      <StyledRadioWrapper>
        <Radio value={'single'} data-testid="single-radio-option">
          {t('cancel_modal.cancel_single')}{' '}
          {loading
            ? t('cancel_modal.loading')
            : reservationStartDate.format('dddd, MMMM DD')}
        </Radio>
      </StyledRadioWrapper>
      <StyledRadioWrapper>
        <Radio value={'multiple'} data-testid="multiple-radio-button">
          {reservationInProgress ? endText : cancelText}
        </Radio>
      </StyledRadioWrapper>
    </Radio.Group>
  );
};

const StyledRadioWrapper = styled.div`
  display: flex;
  padding: var(--Space-Margin-margin, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
  margin-bottom: 8px;

  border-radius: var(--Border-Radius-borderRadiusLG, 8px);
  border: 1px solid var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
`;
