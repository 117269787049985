import styled from '@emotion/styled';
import { Checkbox, Typography } from '@robinpowered/ui-kit';
import {
  useCurrentlySelectedResource,
  useDatePickerType,
} from 'atoms/resource';
import { FC } from 'react';
import { BookButton } from './BookButton';
import { useTranslation } from 'react-i18next';
import { useDeskBookingControlsData, usePrivacySettings } from './hooks';
import { BookDeskDatePicker } from './BookDeskDatePicker';
import { BookDeskTimePickers } from './BookDeskTimePickers';
import { DeskResourceDetails, DeskUnbookableReasons } from '../graphql';
import { useAuthContext } from 'contexts';

const { Title } = Typography;

export const BookingControls: FC<{
  conflictingReservations:
    | DeskResourceDetails['state']['reservations']
    | undefined;
  unbookableReasons: DeskUnbookableReasons | undefined;
  bookingEnabled: boolean;
  isDuringExclusion: boolean;
}> = ({
  conflictingReservations,
  unbookableReasons,
  bookingEnabled,
  isDuringExclusion,
}) => {
  const { t } = useTranslation('resourceDetails');
  const { id: selectedResourceId } = useCurrentlySelectedResource() || {};
  const { permissions } = useAuthContext();
  const datePickerType = useDatePickerType();

  const { loading, data: deskBookingControls } =
    useDeskBookingControlsData(selectedResourceId);

  const { isPrivate, reservationVisibilityEnabled, handlePrivacyChange } =
    usePrivacySettings(
      deskBookingControls?.getDeskById?.settings || [],
      permissions.canReserveDesksForOthers
    );

  return (
    <Container>
      <BookingControlsTitle level={5}>
        {t('desk_booking_controls.title')}
      </BookingControlsTitle>

      <BookDeskDatePicker
        unbookableReasons={unbookableReasons}
        conflictingReservations={conflictingReservations}
      />
      <BookDeskTimePickers unbookableReasons={unbookableReasons} />

      {reservationVisibilityEnabled && (
        <Checkbox
          data-testid="visibility-checkbox"
          checked={isPrivate}
          onChange={handlePrivacyChange}
          disabled={!bookingEnabled || datePickerType !== 'single'}
        >
          {t('desk_booking_controls.mark_private')}
        </Checkbox>
      )}
      <BookingControlsCallToActions>
        <BookButton
          deskId={deskBookingControls?.getDeskById?.id}
          type={deskBookingControls?.getDeskById?.type}
          isDisabled={!bookingEnabled}
          isPrivate={isPrivate}
          isDuringExclusion={isDuringExclusion}
          deskDataLoading={loading}
        />

        {/* TODO: Book for button */}
      </BookingControlsCallToActions>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const BookingControlsTitle = styled(Title)`
  && {
    margin: 0;
  }
`;

const BookingControlsCallToActions = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
`;
