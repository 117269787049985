import {
  createContext,
  useContext,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
  useState,
  FC,
} from 'react';
import { useQuery, gql, ApolloError } from '@apollo/client';
import { useApolloContext } from 'contexts';
import { AuthContextQuery, AuthContextQueryVariables } from 'generated/graphql';
import { useGetUserPermissions } from 'hooks/useGetUserPermissions';

const AUTH_CONTEXT_QUERY = gql`
  query AuthContext {
    getCurrentUserAndOrgs {
      userId
      user {
        id
        name
        timeZone
        avatar
        primaryEmail {
          email
        }
      }
      organizations {
        id
        name
        slug
        avatar
      }
    }
  }
`;

type AuthContextResult = NonNullable<AuthContextQuery['getCurrentUserAndOrgs']>;
export type User = NonNullable<AuthContextResult['user']>;

type AuthContextValue = {
  loading: boolean;
  isLoggedIn: boolean;
  error: undefined | ApolloError;
  currentUser: null | AuthContextResult['user'];
  isAdmin: boolean;
  currentOrg: null | AuthContextResult['organizations'][number];
  setOrgSlug: Dispatch<SetStateAction<string | undefined>>;
  orgSlug?: string;
  refetch?: () => void;
  permissions: {
    canManageOfficeAccess: boolean;
    canReserveDesksForOthers: boolean;
  };
};

const AuthContext = createContext<AuthContextValue>({
  loading: true,
  isLoggedIn: false,
  error: undefined,
  currentUser: null,
  isAdmin: false,
  currentOrg: null,
  setOrgSlug: () => {},
  orgSlug: undefined,
  refetch: () => null,
  permissions: {
    canManageOfficeAccess: true,
    canReserveDesksForOthers: false,
  },
});

export const AuthContextProvider: FC = ({ children }) => {
  const { tenantId, setTenantId } = useApolloContext();
  const [orgSlug, setOrgSlug] = useState<string | undefined>();
  const {
    data,
    loading: authLoading,
    error: authError,
    refetch,
  } = useQuery<AuthContextQuery, AuthContextQueryVariables>(AUTH_CONTEXT_QUERY);

  const { canManageOfficeAccess, canReserveDesksForOthers } =
    useGetUserPermissions(tenantId);

  const isAdmin = canManageOfficeAccess; // should refine definition of Admin later

  const currentOrg = useMemo(
    () =>
      data?.getCurrentUserAndOrgs?.organizations.find(
        ({ slug }) => slug === orgSlug
      ) ?? null,
    [data, orgSlug]
  );

  // Tell ApolloContext what the current org ID is once we've logged in.
  // ApolloContext will re-create the client with a tenantId header for
  // all future queries.
  useEffect(() => {
    if (currentOrg) {
      setTenantId(currentOrg.id);
    }
  }, [currentOrg, setTenantId]);

  const hasAuthError =
    authError &&
    ['provide authorization', 'not authenticated'].includes(
      authError.message.toLowerCase()
    );

  /**
   * Show the loading state while:
   * 1. The query to get both the current user/orgs is in flight, or:
   * 2. The above query has succeeded but ApolloContext hasn't re-created
   *    a client yet with the tenantId header.
   */
  const loading = authLoading || (!authLoading && !!currentOrg && !tenantId);

  const isLoggedIn = !loading && !hasAuthError;

  const value: AuthContextValue = {
    loading,
    isLoggedIn,
    error: authError,
    currentUser: data?.getCurrentUserAndOrgs?.user ?? null,
    isAdmin,
    currentOrg,
    orgSlug,
    setOrgSlug,
    refetch,
    permissions: {
      canManageOfficeAccess,
      canReserveDesksForOthers,
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = (): AuthContextValue => {
  return useContext(AuthContext);
};
