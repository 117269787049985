import styled from '@emotion/styled';
import { Avatar, Button, theme, Typography } from '@robinpowered/ui-kit';
import { FC, useMemo } from 'react';
import ChevronRightIcon from '@robinpowered/icons/ChevronRightOutline';
import { config } from 'config';
import { useAuthContext } from 'contexts';

export const DeskListItemBase: FC<{
  title: string;
  subtitle: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  avatarSrc?: string;
  reserveeName?: string;
  reserveeId?: string;
}> = ({
  icon,
  avatarSrc,
  reserveeName,
  reserveeId,
  title,
  subtitle,
  onClick,
}) => {
  const { currentOrg } = useAuthContext();
  const { useToken } = theme;
  const { token } = useToken();

  const profileUrl = useMemo(
    () =>
      reserveeId
        ? `${config.dashboardUrl}/${
            currentOrg?.slug ?? currentOrg?.id
          }/people/${reserveeId}`
        : undefined,
    [currentOrg, reserveeId]
  );

  const UserAvatar = () => {
    const AvatarComponent = (
      <Avatar
        data-testid="avatar"
        icon={icon}
        src={avatarSrc}
        name={reserveeName}
        generateBackgroundColor
      />
    );

    return profileUrl ? (
      <a
        data-testid="avatar-link"
        href={profileUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {AvatarComponent}
      </a>
    ) : (
      AvatarComponent
    );
  };

  const Container = useMemo(
    () => (onClick ? DeskListItemButton : DeskListItemWrapper),
    [onClick]
  );

  return (
    <Container
      onClick={onClick}
      disabled={!onClick}
      data-testid="desk-list-item-base"
    >
      <AvatarContainer>
        <UserAvatar />
      </AvatarContainer>

      <DeskListItemTextContainer>
        <DeskListItemTextTitle data-testid="title">
          {title}
        </DeskListItemTextTitle>

        <DeskListItemTextSubtitle data-testid="subtitle">
          {subtitle}
        </DeskListItemTextSubtitle>
      </DeskListItemTextContainer>

      {onClick && (
        <IconContainer data-testid="chevron-right-icon">
          <ChevronRightIcon size={16} color={token.colorIcon} />
        </IconContainer>
      )}
    </Container>
  );
};

const DeskListItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--Components-List-Component-avatarMarginRight, 16px);
`;

const DeskListItemButton = styled(Button)`
  display: flex;
  align-items: flex-start;
  gap: var(--Components-List-Component-avatarMarginRight, 16px);
  width: 100%;

  height: unset;
  border: unset;
  padding: 0;
  box-shadow: none;
  background-color: transparent !important;
`;

const AvatarContainer = styled.div`
  .ant-avatar {
    display: flex !important;
  }
`;

const DeskListItemTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
`;

const DeskListItemTextTitle = styled(Typography.Text)`
  color: var(--Components-List-Global-colorText, #1c1c1c);
`;

const DeskListItemTextSubtitle = styled(Typography.Text)`
  color: var(--Components-List-Global-colorTextDescription, #6c6c6c);
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`;
