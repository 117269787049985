import { WriteAtom } from 'recoil-sync';

export const writeWithReset =
  <T>(key: string): WriteAtom<T> =>
  ({ write, reset }, newVal) => {
    if (newVal) {
      write(key, newVal);
    } else {
      reset(key);
    }
  };
