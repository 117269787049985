import { useApolloContext, useAuthContext } from 'contexts';
import { DeskDetailsByIdQuery, DeskDetailsByIdQueryVariables } from 'generated';
import gql from 'graphql-tag';
import { useQueryCachedLoad } from '../../../../hooks';
import { useDeskAvailabilityQueryParameters } from 'hooks/useDeskAvailabilityParameters';

export type Desk = DeskDetailsByIdQuery['getDeskById'];
export type Exclusion = NonNullable<
  NonNullable<
    NonNullable<DeskDetailsByIdQuery['getDeskById']>['state']
  >['exclusions'][number]
>;

export const GET_RESERVATIONS_BY_SEAT_ID = gql`
  query DeskDetailsById(
    $deskId: ID!
    $userId: Int!
    $startTimeMoment: Date!
    $endTimeMoment: Date!
    $recurrence: String
  ) {
    getDeskById(id: $deskId) {
      id
      rawType
      name
      zone {
        name
      }
      location {
        name
      }
      level {
        name
      }
      state(
        startTime: $startTimeMoment
        endTime: $endTimeMoment
        userId: $userId
        recurrence: $recurrence
      ) {
        exclusions {
          startTime
          endTime
        }
      }
    }
  }
`;

export const useDeskDetails = (deskId: string | undefined) => {
  const { tenantId } = useApolloContext();
  const { currentUser } = useAuthContext();
  const { startTimes, endTime, recurrence } =
    useDeskAvailabilityQueryParameters();

  const { loading, data } = useQueryCachedLoad<
    DeskDetailsByIdQuery,
    DeskDetailsByIdQueryVariables
  >(
    GET_RESERVATIONS_BY_SEAT_ID,
    {
      skip:
        !tenantId ||
        !deskId ||
        !startTimes ||
        startTimes.length === 0 ||
        !endTime ||
        !currentUser,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        deskId: deskId || '',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        startTimeMoment: startTimes![0].toString(),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        endTimeMoment: endTime!.toString(),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        userId: Number(currentUser!.id),
        recurrence,
      },
    },
    ['deskId']
  );

  return {
    loading,
    deskDetails: data?.getDeskById as Desk | undefined,
  };
};
