import { checkIsExhaustive } from 'utils';

export type MapMode = 'browse' | 'edit-desk-reservation';

export type SelectableResources = 'seats' | 'spaces';

export type SelectedResource = {
  type: SelectableResources;
  id: string;
};

const isSelectableResource = (str: string): str is SelectableResources => {
  if (typeof str !== 'string') return false;
  const type = str as SelectableResources;
  switch (type) {
    case 'seats':
    case 'spaces':
      return true;
    default:
      return checkIsExhaustive(type) ? false : false;
  }
};

export const isResource = (obj: unknown): obj is SelectedResource => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'type' in obj &&
    typeof obj.type === 'string' &&
    isSelectableResource(obj.type) &&
    'id' in obj &&
    typeof obj.id === 'string'
  );
};

export const isDatePickerType = (str: unknown): str is DatePickerType => {
  if (typeof str !== 'string') return false;
  const type = str as DatePickerType;
  switch (type) {
    case 'single':
    case 'multi':
    case 'range':
      return true;
    default:
      return checkIsExhaustive(type) ? false : false;
  }
};

export type DatePickerType = 'single' | 'multi' | 'range';

export type InteractiveLayer = 'seats' | 'spaces' | 'space_labels';

export const isFilterType = (str: unknown): str is FilterType => {
  if (typeof str !== 'string') return false;
  const type = str as FilterType;
  switch (type) {
    case 'seats':
    case 'spaces':
      return true;
    default:
      return checkIsExhaustive(type) ? false : false;
  }
};

export type FilterType = 'seats' | 'spaces';
