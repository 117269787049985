import { Layout, SiderProps, theme } from '@robinpowered/ui-kit';
import {
  useCallback,
  useMemo,
  TransitionEvent,
  useState,
  useEffect,
  useRef,
} from 'react';
import { SidebarTrigger } from './SidebarTrigger';
import styled from '@emotion/styled';
import { Z_INDEX } from '../../../constants';

type Side = 'left' | 'right';
type SidebarProps = { side: Side; mask?: boolean } & SiderProps;

export const Sidebar = ({ side, mask, ...siderProps }: SidebarProps) => {
  const { useToken } = theme;
  const { token } = useToken();
  const siderRef = useRef(null);

  const [isWidthTransitioning, setIsWidthTransitioning] =
    useState<boolean>(false);

  useEffect(() => {
    const sidebarTransitionStartListener = (e: globalThis.TransitionEvent) => {
      if (e.target === siderRef.current && e.propertyName === 'width') {
        setIsWidthTransitioning(true);
      }
    };

    window.addEventListener('transitionstart', sidebarTransitionStartListener);

    return () =>
      window.removeEventListener(
        'transitionstart',
        sidebarTransitionStartListener
      );
  }, []);

  const showContent = useMemo(() => {
    return !isWidthTransitioning && siderProps.collapsed === false;
  }, [isWidthTransitioning, siderProps.collapsed]);

  const handleTriggerClick = useCallback(() => {
    if (!!siderProps.onCollapse && siderProps.collapsed !== undefined) {
      setIsWidthTransitioning(true);
      siderProps.onCollapse(siderProps.collapsed, 'clickTrigger');
    }
  }, [siderProps]);

  const handleTransitionEnd = useCallback(
    (e: TransitionEvent<HTMLDivElement>) => {
      if (e.propertyName === 'width') {
        setIsWidthTransitioning(false);
      }
    },
    []
  );

  return (
    <>
      <Sider
        ref={siderRef}
        collapsible={true}
        style={{ backgroundColor: token.colorBgElevated }}
        trigger={null}
        onTransitionEnd={handleTransitionEnd}
        side={side}
        data-testid="sidebar"
        {...siderProps}
      >
        {siderProps.collapsedWidth !== 0 && (
          <SidebarTrigger
            collapsed={siderProps.collapsed || false}
            onCollapse={handleTriggerClick}
          />
        )}

        {showContent && siderProps.children}
      </Sider>

      {mask && <SiderMask data-testid="sidebar-mask" />}
    </>
  );
};

const Sider = styled(Layout.Sider)<{ side: Side }>`
  &.ant-layout-sider {
    box-shadow: ${({ side }) => {
      return side === 'left'
        ? '1px 0px 2px 0px rgba(16, 24, 40, 0.1), 2px 0px 6px 2px rgba(16, 24, 40, 0.05)'
        : '-1px 0px 2px 0px rgba(16, 24, 40, 0.10), -2px 0px 6px 2px rgba(16, 24, 40, 0.05)';
    }};
    z-index: ${Z_INDEX.SIDEBAR};
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
`;

const SiderMask = styled.div`
  position: absolute;
  inset: 0;
  background-color: var(--ant-color-bg-mask);
  z-index: ${Z_INDEX.SIDEBAR_MASK};
`;
