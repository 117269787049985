import styled from '@emotion/styled';
import { Tag, Typography } from '@robinpowered/ui-kit';
import { useMemo } from 'react';

const { Title } = Typography;

interface ResourceTagsProps {
  resources: { id: string; name: string; quantity?: number }[];
  title: string;
}

export function ResourceTags({
  resources,
  title,
}: ResourceTagsProps): JSX.Element {
  const tags = useMemo(
    () =>
      [...resources]
        .sort((resourceA, resourceB) => {
          return resourceA.name.localeCompare(resourceB.name);
        })
        .map((resource) => {
          const quantity =
            resource?.quantity && resource.quantity > 1
              ? ` (${resource.quantity})`
              : '';
          const resourceText = resource?.name + quantity;
          return <ResourceTag key={resource?.id}>{resourceText}</ResourceTag>;
        }),
    [resources]
  );

  return (
    <ResourceTagsContainer>
      <Title level={5}>{title}</Title>
      <TagList>{tags}</TagList>
    </ResourceTagsContainer>
  );
}

const ResourceTagsContainer = styled.div`
  display: flex;
  width: 276px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

const TagList = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const ResourceTag = styled(Tag)`
  display: flex;
  padding: 1px var(--Space-Padding-controlPaddingHorizontalSM, 8px);
  align-items: center;
  gap: 4px;
  border-radius: var(--Components-Tag-Global-borderRadiusSM, 4px);
  background: var(--Components-Tag-Component-defaultBg, rgba(0, 0, 0, 0.02));
`;
