import { generateGraphQLHeaders } from '@robinpowered/dashboard-apps-common';

import { setContext } from '@apollo/client/link/context';
import { ClientHeadersByApp } from '@robinpowered/dashboard-apps-common';

export const createAuthLink = (
  accessToken: string | null,
  tenantId: string | undefined
) =>
  setContext((_, { headers }) => ({
    headers: {
      ...generateGraphQLHeaders(
        accessToken,
        tenantId,
        ClientHeadersByApp.OFFICE
      ),
      ...headers,
    },
  }));
